import { KindeProvider } from '@kinde-oss/kinde-auth-react'
import type { KindeProps } from 'src/types'

export default function Kinde({ children }: KindeProps) {
  return (
    <KindeProvider
      clientId={import.meta.env.VITE_KINDE_CLIENT_ID}
      domain={import.meta.env.VITE_KINDE_DOMAIN}
      redirectUri={window.location.origin}
      logoutUri={window.location.origin}
      isDangerouslyUseLocalStorage // TODO: remove this line in production
    >
      {children}
    </KindeProvider>
  )
}
