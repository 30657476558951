import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'

export function useDemands() {
  const { distributor } = useDistributor()

  const demands = useMemo(() => {
    return distributor?.demands ?? []
  }, [distributor?.demands])

  return demands
}
