import { KeyboardEvent, ChangeEvent } from 'react'
import { Table, TextInput, Button, ActionIcon, Flex } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'

import DeleteModal from '../../common/Modals/DeleteModal'
import { useOrders } from 'src/providers/Orders'
import { OrderWithReceivingDays } from 'src/utils/prepareOrdersTable'
import { useDeleteProduct } from '../hooks/useDeleteProduct'
import { Distributor, Order, QtyState } from 'src/types'
import { SaveQtyParams } from '../OrdersBySKU'

type Props = {
  sku?: Distributor['skus'][0]
  order: OrderWithReceivingDays
  product: Order['products'][0]
  state: QtyState
  success: string
  saveQty: ({ id, price, volume, priceExceedsFixedPrice, deliveryDate }: SaveQtyParams) => Promise<void>
  setQtyState: React.Dispatch<React.SetStateAction<QtyState>>
}

function makeOfferCompoundId(order: OrderWithReceivingDays) {
  return order.id + new Date(order.deliveryDate).toISOString()
}

export default function OfferBySKURowAction({ sku, order, product, state, success, saveQty, setQtyState }: Props) {
  const { setShouldRefetch } = useOrders()
  const { isDeleteModalOpen, handleCloseDeleteModal, handleDeleteProduct, handleOpenDeleteModal } = useDeleteProduct({
    onSave: () => setShouldRefetch(true),
  })

  const compoundId = makeOfferCompoundId(order)
  const typedAmount = state.values[compoundId]
  const disabled =
    success === compoundId || Number(typedAmount) < 0 || Number(typedAmount) > product.volume || !typedAmount
  const saveBtnLabel = success === compoundId ? 'Salvo!' : 'Salvar no pedido'

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const invalidValueError = Number(value) < 0 ? 'Valor inválido' : ''
    setQtyState((prevState) => ({
      ...prevState,
      values: { ...prevState.values, [name]: value },
      errors: { ...prevState.errors, [name]: invalidValueError },
    }))
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    const isValueGreaterThanVolume = Number(state.values[compoundId]) > product.volume

    if (event.key === 'Enter' && !isValueGreaterThanVolume)
      saveQty({
        id: order.id,
        price: product.price,
        volume: product.volume,
        deliveryDate: order.deliveryDate,
        priceExceedsFixedPrice: product.priceExceedsFixedPrice,
      })
  }

  if (!sku) {
    return null
  }

  return (
    <>
      <DeleteModal
        show={isDeleteModalOpen}
        title="Remover oferta"
        description="Tem certeza que deseja remover esta oferta? Essa ação é irreversível."
        onCloseModal={handleCloseDeleteModal}
        handleDelete={handleDeleteProduct}
      />
      <Table.Td>
        <TextInput
          type="number"
          name={compoundId}
          value={state.values[compoundId]}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          disabled={state.loading[compoundId]}
          error={state.errors[compoundId]}
          variant="filled"
          placeholder="..."
        />
      </Table.Td>
      <Table.Td>
        <Flex align="center" gap="lg">
          <Button
            onClick={() =>
              saveQty({
                id: order.id,
                price: product.price,
                volume: product.volume,
                priceExceedsFixedPrice: product.priceExceedsFixedPrice,
                deliveryDate: order.deliveryDate,
              })
            }
            loading={state.loading[compoundId]}
            disabled={disabled}
            loaderProps={{ type: 'dots' }}
          >
            {saveBtnLabel}
          </Button>
          <ActionIcon
            color="red"
            variant="light"
            size="lg"
            onClick={handleOpenDeleteModal({
              orderId: order.id,
              sku: sku.SKU,
            })}
            loaderProps={{ type: 'dots' }}
          >
            <IconTrash />
          </ActionIcon>
        </Flex>
      </Table.Td>
    </>
  )
}
