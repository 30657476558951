const GOLDEN_ANGLE = 180 * (3 - Math.sqrt(5))

// TODO: avaliar alterar essa função de gerar cor
export function generateHSLColor(str: string) {
  // Simple hash function to convert a string to a number
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  // Normalize the hash value to [0, 1]
  const normalizedHash = ((hash % 360) + 360) % 360

  // Use the golden angle (approximately 137.5 degrees) to determine hue
  const hue = (normalizedHash + GOLDEN_ANGLE + 30) % 360

  // Set saturation and lightness to fixed values for distinguishability
  const saturation = 70 // Saturation of 70%
  const lightness = 60 // Lightness of 60%

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}
