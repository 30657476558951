import { Grid, Input, MultiSelect } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { IconSearch } from '@tabler/icons-react'

type Label = {
  label: string
  value: string
}

type Props = {
  query: {
    search: string
    selectedCategories: string[]
    selectedSKUs: string[]
    deliveryDate: Date | null
    skus: Label[]
    categories: string[]
  }
  onChange: (key: string, value?: string) => void
}

export default function SuppliersTableFilter({ query, onChange }: Props) {
  return (
    <Grid grow>
      <Grid.Col
        span={{
          base: 12,
          xs: 6,
        }}
      >
        <Input.Wrapper label="Pesquisar">
          <Input
            id="search"
            name="search"
            placeholder="Pesquise por código, nome ou whatsapp"
            value={query.search}
            leftSection={<IconSearch size={16} />}
            onChange={({ target }) => {
              onChange(target.name, target.value)
            }}
          />
        </Input.Wrapper>
      </Grid.Col>

      <Grid.Col
        span={{
          base: 12,
          xs: 6,
        }}
      >
        <DatePickerInput
          label="Data de entrega"
          placeholder="Selecione uma data"
          locale="pt-br"
          valueFormat="DD/MMM"
          minDate={new Date()}
          clearable
          value={query.deliveryDate}
          onChange={(date) => {
            onChange('date', date?.toISOString())
          }}
        />
      </Grid.Col>

      <Grid.Col
        span={{
          base: 12,
          xs: 6,
        }}
      >
        <MultiSelect
          name="category"
          placeholder="Escolha uma ou mais categorias de produtos"
          label="Categoria"
          data={query.categories}
          value={query.selectedCategories}
          searchable
          clearable
          checkIconPosition="right"
          onChange={(values) => {
            onChange('categories', values.join(','))
          }}
        />
      </Grid.Col>

      <Grid.Col
        span={{
          base: 12,
          xs: 6,
        }}
      >
        <MultiSelect
          name="skus"
          placeholder="Escolha um ou mais SKUs"
          label="SKUs"
          data={query.skus}
          value={query.selectedSKUs}
          searchable
          clearable
          checkIconPosition="right"
          onChange={(values) => {
            onChange('skus', values.join(','))
          }}
        />
      </Grid.Col>
    </Grid>
  )
}
