import 'chart.js/auto'
import { Flex, Text, Box, Group } from '@mantine/core'
import { ChartProps, Bar } from 'react-chartjs-2'
import { ChartData, ChartDataset } from 'chart.js/auto'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomMultiSelectOption } from 'src/components/common/Inputs/CustomMultiSelect'
import { useSKUs } from 'src/hooks/useSKUs'
import { generateHSLColor } from '../utils/colors'
import { INITIAL_CHECKBOXES_STATE, INITIAL_DATES_STATE, MAX_DATE } from '../SelectView/constants'
import { MultiSelectView } from '../SelectView'
import { getMarketSummary } from 'src/requests/charts'
import { useQuery } from '@tanstack/react-query'
import { ChartDates } from '../SelectView/types'
import { useDistributor } from 'src/providers/Distributor'
import { DatePickerInput } from '@mantine/dates'
import { ptBR } from 'date-fns/locale'

const config: Omit<ChartProps<'bar' | 'line'>, 'data'> = {
  type: 'bar',
  height: '350px',
  width: '100%',
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          round: 'day',
          unit: 'day',
          displayFormats: {
            day: 'dd MMM',
          },
          tooltipFormat: 'dd MMM',
        },
        adapters: {
          date: {
            locale: ptBR,
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Preço',
        },
      },
      y1: {
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        title: {
          display: true,
          text: 'Volume',
        },
      },
    },
  },
}

export function Volume() {
  const [chartDates, setChartDates] = useState<ChartDates>(INITIAL_DATES_STATE)
  const { labelValueSKUs } = useSKUs()
  const [selectedSKUs, setSelectedSKUs] = useState<CustomMultiSelectOption[]>([])
  const [checkboxes, setCheckboxes] = useState<string[]>(INITIAL_CHECKBOXES_STATE)
  const { distributor } = useDistributor()

  const showOffers = checkboxes.includes('offers')
  const showPurchases = checkboxes.includes('purchases')

  const selectedItem = selectedSKUs?.[0]?.value ?? ''

  const { data } = useQuery({
    initialData: [],
    queryKey: ['market-summary', chartDates, selectedItem],
    queryFn: ({ signal }) =>
      getMarketSummary(distributor!.distributorId, {
        params: {
          startDate: chartDates.startDate!,
          endDate: chartDates.endDate!,
          items: selectedItem,
        },
        signal,
      }),
    enabled: !!chartDates.endDate && !!chartDates.startDate && !!selectedSKUs.length && !!distributor?.distributorId,
  })

  const offersVolume: ChartDataset<'bar'> = useMemo(
    () => ({
      label: 'Volume ofertado',
      type: 'bar',
      fill: false,
      backgroundColor: '#B92DFE',
      borderColor: '#B92DFE',
      data: data.map((item) => item[selectedItem]?.totalOffersVolume),
      borderDash: [5, 5],
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
      yAxisID: 'y1',
      maxBarThickness: 15,
      borderRadius: Number.MAX_SAFE_INTEGER,
    }),
    [data, selectedItem],
  )

  const purchasesVolume: ChartDataset<'bar'> = useMemo(
    () => ({
      type: 'bar',
      label: 'Volume comprado',
      fill: false,
      backgroundColor: '#B7B2FF',
      borderColor: '#B7B2FF',
      data: data.map((item) => item[selectedItem]?.totalPurchasesVolume),
      borderDash: [5, 5],
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
      yAxisID: 'y1',
      maxBarThickness: 15,
      borderRadius: Number.MAX_SAFE_INTEGER,
    }),
    [data, selectedItem],
  )

  const offers: ChartDataset<'bar'>[] = useMemo(() => {
    if (!showOffers) return []

    return selectedSKUs.map((sku) => ({
      label: 'Oferta média',
      fill: false,
      type: 'line' as 'bar',
      backgroundColor: generateHSLColor(sku.value),
      borderColor: generateHSLColor(sku.value),
      data: data.map((item) => item[sku.value].averageOfferPrice),
      borderDash: [5, 5],
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    }))
  }, [selectedSKUs, showOffers, data])

  const purchases: ChartDataset<'bar'>[] = useMemo(() => {
    if (!showPurchases) return []

    return selectedSKUs.map((sku) => ({
      label: 'Compra média',
      fill: false,
      type: 'line' as 'bar',
      backgroundColor: generateHSLColor(sku.value),
      borderColor: generateHSLColor(sku.value),
      data: data.map((item) => item[sku.value].averagePurchasePrice),
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    }))
  }, [showPurchases, selectedSKUs, data])

  const chartData: ChartData<'bar'> = useMemo(() => {
    const chartData = {
      labels: data.map((item) => item.date),
      datasets: [...offers, ...purchases].sort((a, b) => a.label!.localeCompare(b.label!)),
    }

    if (showOffers) {
      chartData.datasets.push(offersVolume)
    }

    if (showPurchases) {
      chartData.datasets.push(purchasesVolume)
    }

    return chartData
  }, [data, offers, offersVolume, purchases, purchasesVolume, showOffers, showPurchases])

  const onChangeSKUs = useCallback((val: CustomMultiSelectOption[]) => {
    setSelectedSKUs(val)
  }, [])

  const onRemoveSKU = useCallback((val: CustomMultiSelectOption) => {
    setSelectedSKUs((current) => current.filter((v) => v.value !== val.value))
  }, [])

  // for resize purposes
  const ref = useRef<any>(null)

  useEffect(() => {
    window.addEventListener('resize', function () {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      ref.current?.resize?.()
    })
  }, [])

  return (
    <Box>
      <Flex gap="md">
        <Box flex={1}>
          <Group justify="space-between" mb="lg">
            <Text fw={500}>Volume ofertado e comprado</Text>

            <DatePickerInput
              onChange={(dates) => {
                const startDate = dates[0]
                const endDate = dates[1]

                setChartDates({
                  startDate,
                  endDate,
                })
              }}
              value={[chartDates.startDate, chartDates.endDate]}
              w={180}
              maxDate={MAX_DATE}
              allowSingleDateInRange
              valueFormat="DD MMM"
              locale="pt-br"
              clearable
              type="range"
              placeholder="Data"
            />
          </Group>
          <Box pos="relative">
            <Bar ref={ref} {...config} data={chartData} />
          </Box>
        </Box>

        <MultiSelectView
          placeholder="Escolha um SKU"
          limit={1}
          selected={selectedSKUs}
          handleRemove={onRemoveSKU}
          onChange={onChangeSKUs}
          options={labelValueSKUs}
          onChangeCheckboxes={setCheckboxes}
        />
      </Flex>
    </Box>
  )
}
