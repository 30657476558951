import { parseISO } from 'date-fns'
import { setToStartOfUTCDay } from 'src/utils/formatDate'

export function useDeliveryDate(isoDate?: string) {
  const zonedDate = isoDate ? parseISO(isoDate) : null
  const utcDate = zonedDate ? setToStartOfUTCDay(zonedDate) : zonedDate

  return {
    zonedDate,
    utcDate,
  }
}
