import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { useOrders } from 'src/providers/Orders'
import { useDemands } from '../useDemands'
import { useSuppliers } from '../useSuppliers'
import { removeDuplicatedStrings } from 'src/utils/arrays/removeDuplicated'
import { Order, Producer } from 'src/types'
import { UseSKUsWithOrderDataReturn } from './types'

export function useSKUs() {
  const { distributor } = useDistributor()

  const skus = useMemo(() => distributor?.skus ?? [], [distributor?.skus])

  const labelValueSKUs = useMemo(() => {
    return skus.map((sku) => ({
      value: String(sku['Código']),
      label: String(sku['SKU']),
    }))
  }, [skus])

  const stringNameSKUs = useMemo(() => {
    return skus.map((sku) => String(sku['SKU']))
  }, [skus])

  return {
    labelValueSKUs,
    stringNameSKUs,
    skus,
  }
}

export default function useSKUsWithOrderData(): UseSKUsWithOrderDataReturn[] {
  const { duplicatedOrdersWithExpirationDate: orders } = useOrders()
  const { skus } = useSKUs()
  const demands = useDemands()
  const { suppliers } = useSuppliers()

  const skusWithAggregatedData = useMemo(() => {
    return (
      skus?.map((sku) => {
        // find all orders that offer that sku and sum their volume
        const ordersWithSku = orders?.filter((order) =>
          order.products.find((product) => product.sku === sku['SKU'] && order.sentOrder.length === 0),
        )

        // find demand
        const demandFound = demands?.find((demand) => demand.code === sku['Código'] || demand.sku === sku['SKU'])
        // find producers receiving days

        const suppliersIds = combineSuppliersIds({
          suppliers,
          orders: ordersWithSku,
        })

        const deliveryDates = combineDeliveryDates({
          orders: ordersWithSku,
        })

        const suppliersIdsWithoutDuplication = removeDuplicatedStrings(suppliersIds)

        // return the sku with the aggregated data
        return {
          ...sku,
          demand: demandFound?.value ?? 0,
          suppliersIds: suppliersIdsWithoutDuplication,
          deliveryDates,
        }
      }) ?? []
    )
  }, [demands, orders, suppliers, skus])
  return sortSkusByDateAndName(skusWithAggregatedData)
}

type CombineContext = {
  orders?: Order[]
  suppliers: Producer[]
}

function combineSuppliersIds({ suppliers, orders = [] }: CombineContext) {
  return orders.reduce<string[]>((arr, order) => {
    const supplier = suppliers.find((p) => p.id === order.supplierId)

    const supplierId = supplier?.id

    if (supplierId) {
      arr.push(supplierId)
    }

    return arr
  }, [])
}

function combineDeliveryDates({ orders = [] }: Pick<CombineContext, 'orders'>) {
  return orders.map((order) => order.deliveryDate).filter(Boolean)
}

function sortSkusByDateAndName(skus: UseSKUsWithOrderDataReturn[]) {
  return skus.sort((a, b) => {
    const lastDateA = Math.max(...a.deliveryDates.map((date) => new Date(date).getTime()))
    const lastDateB = Math.max(...b.deliveryDates.map((date) => new Date(date).getTime()))
    if (lastDateA === lastDateB) {
      return a.SKU.localeCompare(b.SKU)
    } else {
      return lastDateB - lastDateA
    }
  })
}
