import { notifications } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons-react'

import { DEFAULT_AUTO_CLOSE_TIMEOUT } from 'src/constants/pedingNotification'
import { NotificationMessage } from 'src/types'

type Message = NotificationMessage

type OptionalErrorPendingNotificationFactoryContext = {
  error?: Message
  success: Message
  pending: Message
}

type PendingNotificationFactoryContext = {
  error: Message
  success: Message
  pending: Message
}

type ReturnWithOptionalError = {
  error: (errorContext?: Message) => void
  success: () => void
}

type ReturnWithRequiredError = {
  error: (errorContext: Message) => void
  success: () => void
}

function pendingNotificationFactory(context: PendingNotificationFactoryContext): ReturnWithOptionalError
function pendingNotificationFactory(context: OptionalErrorPendingNotificationFactoryContext): ReturnWithRequiredError
function pendingNotificationFactory(
  context: PendingNotificationFactoryContext | OptionalErrorPendingNotificationFactoryContext,
): ReturnWithOptionalError | ReturnWithRequiredError {
  const notificationId = notifications.show({
    loading: true,
    title: context.pending.title,
    message: context.pending.message,
    autoClose: false,
    withCloseButton: false,
  })

  function success() {
    notifications.update({
      id: notificationId,
      color: 'teal',
      title: context.success.title,
      message: context.success.message,
      icon: <IconCheck size={18} />,
      loading: false,
      autoClose: DEFAULT_AUTO_CLOSE_TIMEOUT,
      withCloseButton: true,
    })
  }

  return {
    success,
    error(errorContext: Message | undefined) {
      notifications.update({
        id: notificationId,
        color: 'red',
        title: errorContext?.message ?? context.error?.title,
        message: errorContext?.title ?? context.error?.message,
        icon: <IconX size={18} />,
        loading: false,
        withCloseButton: true,
        autoClose: DEFAULT_AUTO_CLOSE_TIMEOUT,
      })
    },
  }
}

export { pendingNotificationFactory }
