import { Box, Title, Text } from '@mantine/core'
import Illustration from './Illustration'
import styles from './NotFound.module.css'

export default function NotFound() {
  return (
    <Box className={styles.notFound}>
      <Illustration width={100} height={100} />
      <Title order={4}>Página não encontrada</Title>
      <Text>Revise a URL e tente novamente</Text>
    </Box>
  )
}
