import { ActionIcon, Box, Textarea } from '@mantine/core'
import { IconSend2 } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import sendWhatsappMessage from 'src/requests/sendWhatsappMessage'
import { parseAddWhatsAppPhonePrefix } from 'src/utils/parseBrazilianPhones'
import { useMeasure } from 'react-use'
import { useSuppliers } from 'src/hooks/useSuppliers'

interface ChatFooterProps {
  onChangeFooterHeight: (height: number) => void
  onSendMessage: () => void
}

export function ChatFooter(props: ChatFooterProps) {
  const { phone } = useParams()
  const [sendValue, setSendValue] = useState('')
  const [sendError, setSendError] = useState(false)
  const { showBoundary } = useErrorBoundary()
  const [loading, setLoading] = useState(false)
  const [measureRef, { height }] = useMeasure()

  const { suppliers } = useSuppliers()

  const supplier = suppliers.find((s) => s.phone === phone)

  function onInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setSendValue(e.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSubmit(event)
    }
  }

  useEffect(() => {
    props.onChangeFooterHeight(height + 32)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, props.onChangeFooterHeight])

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    try {
      setSendError(false)
      if (!sendValue || !supplier) {
        setSendError(true)
        return
      }

      setLoading(true)
      await sendWhatsappMessage({
        userNumber: parseAddWhatsAppPhonePrefix(supplier.phone),
        text: sendValue,
        isManual: true,
      })

      setSendValue('')
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      display="flex"
      ref={(node) => {
        if (node) {
          measureRef(node)
        }
      }}
    >
      <Textarea
        disabled={loading}
        value={sendValue}
        error={sendError}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
        name="message"
        placeholder="Envie uma mensagem..."
        flex="1"
        autosize={true}
        minRows={1}
        maxRows={5}
      />
      <ActionIcon
        loading={loading}
        disabled={!sendValue}
        type="submit"
        size="lg"
        variant="subtle"
        ml="xs"
        style={{ alignSelf: 'flex-end' }}
      >
        <IconSend2 />
      </ActionIcon>
    </Box>
  )
}
