import { Box, Flex, Group, Image, Text } from '@mantine/core'
import { BalloonProps, FileExtension } from '../Chat'
import BalloonText from './Text'
import styles from '../Chat.module.css'
import { getStatusIcon } from '../statuses'
import { Link } from 'react-router-dom'
import { IconArrowBigDownFilled } from '@tabler/icons-react'

type BalloonImageProps = Pick<
  BalloonProps,
  'mediaUrl' | 'hour' | 'message' | 'className' | 'status' | 'resendFailedMessage' | 'fileExtension' | 'mediaName'
>

function parseExtension(ext: FileExtension) {
  switch (ext) {
    case FileExtension.XLSX:
      return 'xlsx'
    default:
      return ext
  }
}

export default function BalloonFile({
  className,
  hour,
  message,
  status,
  resendFailedMessage,
  mediaUrl,
  fileExtension,
  mediaName = '',
}: BalloonImageProps) {
  const StatusIcon = getStatusIcon(status)
  const parsedExtension = fileExtension && parseExtension(fileExtension)

  return (
    <Box className={className}>
      <Link to={mediaUrl ?? ''} target="_blank" rel="noopener noreferrer">
        <Group
          bg="#f5f6f6"
          px="lg"
          py="sm"
          style={{
            borderRadius: 6,
          }}
        >
          <Group wrap="nowrap" w="100%">
            {parsedExtension && (
              <>
                <Image h={30} w={26} className={styles.message_balloon_image} src={`/icons/${parsedExtension}.png`} />
                <Box
                  flex={1}
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <Text truncate="start">{mediaName.replace(fileExtension, parsedExtension)}</Text>
                  <Text size="xs" c="#8696a0" tt="uppercase">
                    {parsedExtension}
                  </Text>
                </Box>
                <Flex
                  justify="center"
                  align="center"
                  w={34}
                  h={34}
                  style={{
                    border: '1px solid #8696a0',
                    borderRadius: '50%',
                  }}
                >
                  <IconArrowBigDownFilled
                    size={16}
                    style={{
                      color: '#8696a0',
                    }}
                  />
                </Flex>
              </>
            )}
          </Group>
        </Group>
      </Link>
      <BalloonText hideInfo={!!message} hour={hour} message={message} />
      <Box className={styles.message_balloon_data_info}>
        <Box component="span" className={styles.message_balloon_time}>
          {hour}
        </Box>
        {!!status && !!StatusIcon && <StatusIcon resendFailedMessage={resendFailedMessage} />}
      </Box>
    </Box>
  )
}
