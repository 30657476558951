import { Combobox, InputBase, useCombobox } from '@mantine/core'
import { useState } from 'react'

type SelectCreatableProps = {
  options?: string[]
  onChange?(val: string): void
  value?: string
  label?: string
  placeholder?: string
  error?: string
}

export default function SelectCreatable({
  options = [],
  onChange,
  value = '',
  label,
  placeholder,
  error,
}: SelectCreatableProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const [data, setData] = useState(options)
  const [search, setSearch] = useState(value ?? '')

  const normalizedSearch = normalizeSelectValue(search)
  const optionMatch = data.some((item) => normalizeSelectValue(item) === normalizedSearch)
  const filteredOptions = optionMatch
    ? data
    : data.filter((item) => normalizeSelectValue(item).includes(normalizedSearch))

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        if (val === '$create') {
          setData((current) => [...current, search])
          onChange?.(search)
        } else {
          onChange?.(val)
          setSearch(val)
        }

        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          label={label}
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
            setSearch(event.currentTarget.value)
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown()
            setSearch(value || '')
          }}
          placeholder={placeholder}
          rightSectionPointerEvents="none"
          error={error}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
          {filteredOptions.map((item) => (
            <Combobox.Option value={item} key={item}>
              {item}
            </Combobox.Option>
          ))}
          {!optionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">+ Criar {search}</Combobox.Option>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

function normalizeSelectValue(val: string) {
  return val
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
}