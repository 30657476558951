const WHATSAPP_PHONE_PREFFIX = 'whatsapp:+'

export function parseAddWhatsAppPhonePrefix(phone = '') {
  if (phone.startsWith(WHATSAPP_PHONE_PREFFIX)) return phone

  return WHATSAPP_PHONE_PREFFIX + phone
}

const DIAL_CODE_PHONE_PREFFIX = '55'
const PLUS_PHONE_PREFFIX = '+'

export function parseRemoveCountryDialCode(phone = '') {
  if (phone.startsWith(`${PLUS_PHONE_PREFFIX}${DIAL_CODE_PHONE_PREFFIX}`) && phone.length > 12 && phone.length < 15) {
    return phone.replace(`${PLUS_PHONE_PREFFIX}${DIAL_CODE_PHONE_PREFFIX}`, '')
  } else if (phone.startsWith(DIAL_CODE_PHONE_PREFFIX) && phone.length > 11 && phone.length < 14) {
    return phone.replace(DIAL_CODE_PHONE_PREFFIX, '')
  }

  return phone
}

export function removeNonDigit(phone = '') {
  return phone.replace(/[^\d]/g, '')
}

export function addNonDigit(phone = '', prefix = '') {
  return phone.replace(/(\d{2})(\d{2})(\d{8})/, `${prefix}$1 $2 $3`);
}
