import { AppShellHeader, Box, Burger, Image } from '@mantine/core'
import Dock from 'src/components/Dock'
import type { HeaderProps } from 'src/types'
import styles from './Header.module.css'

export default function Header(props: HeaderProps) {
  const { navbarIsOpen, openNavbar } = props
  return (
    <AppShellHeader>
      <Box className={styles.headerContent}>
        <Burger opened={navbarIsOpen} onClick={openNavbar} hiddenFrom="sm" size="sm" mr="md" />
        <Image src="/logo.png" height={50} alt="logo" className={styles.logo} />
        <Dock />
      </Box>
    </AppShellHeader>
  )
}
