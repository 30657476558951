import { ActionIcon, Button, Checkbox, Flex, InputBase, Menu, Table } from '@mantine/core'
import { IMaskInput } from 'react-imask'
import { Link, useNavigate } from 'react-router-dom'
import formatPrice from 'src/utils/formatPrice'
import { parseInfiniteStock } from 'src/utils/products/volume'
import { useUpdateDemand } from '../../hooks/useUpdateDemand'
import { useOrders } from 'src/providers/Orders'
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { useCallback, useState } from 'react'
import { Distributor, TCreateOrUpdateSKUProps } from 'src/types'
import { deleteSKU } from 'src/requests/firebase/sku'
import { useDistributor } from 'src/providers/Distributor'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import { useErrorBoundary } from 'react-error-boundary'
import DeleteModal from 'src/components/common/Modals/DeleteModal'
import { IsCheckboxChecked, OnIndividualCheckboxChange } from 'src/hooks/useBulkCheckbox'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { UseSKUsWithOrderDataReturn } from 'src/hooks/useSKUs/types'
import MobileBodySupply from './MobileBody'
import { useMediaQuery } from '@mantine/hooks'
import TableCellPopover from 'src/components/TableCellPopover/TableCellPopover'
import Text from 'src/components/Text'
import classes from 'src/components/Table/Table.module.css'

type Items = UseSKUsWithOrderDataReturn & {
  minPrice: number
  volume: number
  lastBuyoutPrice?: number
}

export type SupplyBySKUTableBodyProps = {
  items: Items[]
  isCheckboxChecked: IsCheckboxChecked
  onIndividualCheckboxChange: OnIndividualCheckboxChange
}

const DEMAND_INPUT_PROPS = {
  radix: '.',
  mapToRadix: [','],
  scale: 1,
  unmask: true,
}

export function SupplyBySKUTableBody(props: SupplyBySKUTableBodyProps) {
  const { setShouldRefetch } = useOrders()
  const { distributor } = useDistributor()
  const { showBoundary } = useErrorBoundary()
  const { getQueryParams } = useQueryParams()
  const searchParams = getQueryParams()

  const isMobile = useMediaQuery('(max-width: 767px)')
  const navigate = useNavigate()
  const [isDeleteModalOpen, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false)
  const [skuToDelete, setSkuToDelete] = useState<Distributor['skus'][number]>()

  const {
    updatingDemandInputRef,
    handleBlurDemand,
    handleChangeDemand,
    handleClickToUpdateDemand,
    handleSaveDemand,
    updatingProductCode,
  } = useUpdateDemand({
    onSave: () => setShouldRefetch(true),
  })

  const onEdit = useCallback(
    (code: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      navigate(`/ofertas/sku/${code}/editar`)
    },
    [navigate],
  )

  const onDelete = useCallback(
    (sku: Distributor['skus'][number]) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      if (sku) {
        setSkuToDelete({
          SKU: sku['SKU'],
          Código: sku['Código'],
          Medida: sku['Medida'],
          Categoria: sku['Categoria'],
        })
        openDeleteModal()
      }
    },
    [openDeleteModal],
  )

  const onCloseDeleteModal = useCallback(() => {
    setSkuToDelete(undefined)
    closeDeleteModal()
  }, [closeDeleteModal])

  const resetModal = useCallback(() => {
    onCloseDeleteModal()
    setShouldRefetch(true)
  }, [onCloseDeleteModal, setShouldRefetch])

  const handleDeleteSku = useCallback(async () => {
    const notification = pendingNotificationFactory({
      pending: {
        message: 'Isso não deve demorar muito',
        title: 'Removendo SKU...',
      },
      success: {
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
        title: 'SKU removido com sucesso!',
      },
      error: {
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador',
        title: 'Erro ao remover SKU.',
      },
    })

    try {
      await deleteSKU({
        ...(skuToDelete as TCreateOrUpdateSKUProps),
        distributorId: distributor!.distributorId,
      })
      notification.success()
      resetModal()
    } catch (error) {
      notification.error()
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    }
  }, [distributor, resetModal, showBoundary, skuToDelete])

  return (
    <>
      <DeleteModal
        show={isDeleteModalOpen}
        onCloseModal={onCloseDeleteModal}
        title="Remover SKU"
        description="Tem certeza que deseja remover esse SKU?"
        handleDelete={handleDeleteSku}
      />
      {!isMobile ? (
        <Table.Tbody>
          {props.items.map((sku) => {
            const lastBuyoutPrice = sku.lastBuyoutPrice ? formatPrice(sku.lastBuyoutPrice) : '-'
            const minPrice = formatPrice(sku.minPrice)
            const isLowOffer = sku.demand > sku.volume
            const stringSKU = String(sku['SKU'])
            const skuCode = String(sku['Código'])
            const isUpdatingDemand = updatingProductCode === skuCode

            return (
              <Table.Tr key={skuCode}>
                <Table.Td>
                  <Checkbox
                    aria-label="Select row"
                    defaultValue={stringSKU}
                    checked={props.isCheckboxChecked(stringSKU)}
                    onChange={props.onIndividualCheckboxChange}
                  />
                </Table.Td>
                <Table.Td>{skuCode}</Table.Td>
                <Table.Td>
                  <TableCellPopover
                    dropdown={
                      <Text truncate="end" span inherit>
                        {stringSKU}
                      </Text>
                    }
                  >
                    <Button
                      p="0"
                      component={Link}
                      to={{
                        pathname: skuCode,
                        search: searchParams.toString(),
                      }}
                      justify="start"
                      variant="transparent"
                      className={classes.tableCell}
                    >
                      <Text truncate="end" span inherit>
                        {stringSKU}
                      </Text>
                    </Button>
                  </TableCellPopover>
                </Table.Td>
                <Table.Td>{sku['Categoria']}</Table.Td>
                <Table.Td align="center">{lastBuyoutPrice}</Table.Td>
                <Table.Td align="center">{minPrice}</Table.Td>
                <Table.Td align="center">{parseInfiniteStock(Number(sku.volume))}</Table.Td>
                <Table.Td align="center" miw={100}>
                  {isUpdatingDemand ? (
                    <InputBase
                      component={IMaskInput}
                      w={80}
                      inputRef={updatingDemandInputRef}
                      variant="filled"
                      mask={Number}
                      defaultValue={sku.demand}
                      onAccept={handleChangeDemand}
                      onBlur={handleBlurDemand}
                      onKeyDown={handleSaveDemand}
                      {...DEMAND_INPUT_PROPS}
                    />
                  ) : (
                    <Flex align="center" justify="center" w="100%">
                      <Text c={sku.demand ? (isLowOffer ? 'red' : 'main') : 'unset'}>{sku.demand || '-'}</Text>
                      <ActionIcon
                        ml={4}
                        variant="subtle"
                        onClick={handleClickToUpdateDemand({
                          code: skuCode,
                          sku: stringSKU,
                        })}
                      >
                        <IconPencil />
                      </ActionIcon>
                    </Flex>
                  )}
                </Table.Td>
                <Table.Td align="center">{sku['Medida']}</Table.Td>
                <Table.Td align="center">
                  <Menu shadow="md" withArrow position="bottom-end">
                    <Menu.Target>
                      <Button onClick={(e) => e.stopPropagation()} variant="subtle" size="compact-sm">
                        <IconDotsVertical size={20} />
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item onClick={onEdit(skuCode)} leftSection={<IconPencil size={14} />}>
                        Editar
                      </Menu.Item>
                      <Menu.Item onClick={onDelete(sku)} color="red" leftSection={<IconTrash size={14} />}>
                        Remover
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
            )
          })}
        </Table.Tbody>
      ) : (
        <MobileBodySupply
          items={props.items}
          isCheckboxChecked={props.isCheckboxChecked}
          onIndividualCheckboxChange={props.onIndividualCheckboxChange}
          edit={onEdit}
          del={onDelete}
        />
      )}
    </>
  )
}
