import { arrayRemove, doc, getDoc, updateDoc } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { Distributor, TCreateUpdateOrDeleteSKU } from 'src/types'
import dataConverter from '../utils/dataConverter'

export async function deleteSKU({ distributorId, ...data }: TCreateUpdateOrDeleteSKU) {
  const distributorRef = doc(db, 'distributors', distributorId).withConverter(dataConverter<Distributor>())

  await updateDoc(distributorRef, {
    skus: arrayRemove(data),
  })

  const distributorDoc = await getDoc(distributorRef)

  const distributorData = distributorDoc.data()

  const skuDemand = distributorData?.demands?.find((demand) => demand.code === data['Código'])

  if (skuDemand) {
    await updateDoc(distributorRef, {
      demands: arrayRemove(skuDemand),
    })
  }
}
