import { Routes, Route } from 'react-router-dom'
import PrivatePage from 'src/components/PrivatePage'
import CreateOrUpdateProducer from 'src/components/Supplier/CreateOrUpdateSupplier'
import ProducersTable from 'src/components/Supplier/SuppliersTable'

export default function Producers() {
  return (
    <PrivatePage>
      <Routes>
        <Route index element={<ProducersTable />} />
        <Route path="cadastrar" element={<CreateOrUpdateProducer />} />
        <Route path="editar/:id" element={<CreateOrUpdateProducer />} />
      </Routes>
    </PrivatePage>
  )
}
