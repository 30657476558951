import { BackgroundImage, Grid } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import LoginBox from './LoginBox'
import styles from './Login.module.css'

export default function Login() {
  const isSmallMedium = useMediaQuery('(max-width: 1100px)')
  if (isSmallMedium)
    return (
      <BackgroundImage src="/login-hero-img.jpg" className={styles.background}>
        <LoginBox />
      </BackgroundImage>
    )
  return (
    <Grid className={styles.grid}>
      <Grid.Col span={6} className={styles.imageColumn}>
        <BackgroundImage src="/login-hero-img.jpg" className={styles.background} />
      </Grid.Col>
      <Grid.Col span={6} className={styles.loginColumn}>
        <LoginBox />
      </Grid.Col>
    </Grid>
  )
}
