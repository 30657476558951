import { setAnalyticsUserId } from 'src/configs/firebase'

export default function setAnalytics(userId: string) {
  if (import.meta.env.PROD) {
    try {
      setAnalyticsUserId(userId)
    } catch (error) {
      // do nothing, just prevent error propagating to user
    }
  }
}
