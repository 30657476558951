import { Menu, Box, Text, Badge } from '@mantine/core'
import { IconBell, IconMail, IconMailOpened } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'
import styles from './Dock.module.css'
import { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import httpErrorHandler from 'src/utils/httpErrorHandler'
import { format } from 'date-fns-tz/format'
import { useNavigate } from 'react-router-dom'
import OneSignal from 'react-onesignal'
import { Notification } from 'src/types'
import getNotifications from 'src/requests/notifications/getNotifications'
import readNotification from 'src/requests/notifications/readNotification'
import { notifications as mantineNotifications } from '@mantine/notifications'

export default function NotificationsMenu() {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [notifications, setNotifications] = useState([] as Notification[])
  const bellSize = isMobile ? 20 : 23
  const [updatedAt, setUpdatedAt] = useState(new Date())

  // TODO: extract to a hook
  useEffect(() => {
    OneSignal.Notifications.addEventListener('foregroundWillDisplay', handleWebPush)

    return () => {
      OneSignal.Notifications.removeEventListener('foregroundWillDisplay', handleWebPush)
    }
  }, [])

  async function fetchNotifications(): Promise<Notification[]> {
    const notifications = await getNotifications()
    setUpdatedAt(new Date())
    return notifications
  }

  const defaultInterval = 10 * 60 * 1000 // 10 minutes
  const refetchInterval = import.meta.env.VITE_NOTIFICATION_REFETCH_INTERVAL ?? defaultInterval

  const { data: queryNotifications, refetch } = useQuery<Notification[]>({
    queryKey: ['notifications'],
    queryFn: fetchNotifications,
    initialData: notifications,
    refetchInterval,
  })

  const unreadNotificationsCount = queryNotifications.filter((n) => !n.isRead).length

  async function markAsRead(notificationId: string) {
    try {
      await readNotification(notificationId)
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const { mutateAsync } = useMutation({
    mutationFn: markAsRead,
    onSuccess: async () => {
      await refetch()
    },
  })

  // TODO: extract to a hook
  async function handleWebPush(event: any) {

    if (import.meta.env.VITE_NOTIFICATION_LOCAL == 'true') {
      if (!event?.notification) return

      const { body, title } = event.notification
      mantineNotifications.show({
        title,
        message: body,
        autoClose: true,
        withCloseButton: true,
      })
    }

    if (!import.meta.env.VITE_NOTIFICATION_REFRESH_ON_PUSH) return

    await refetch()
  }

  const navigate = useNavigate()

  const handleNotificationClick = (notificationItem: Notification) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === notificationItem.id ? { ...notification, isRead: true } : notification,
      ),
    )

    mutateAsync(notificationItem.id)
    navigate(`/mensagens/${notificationItem.producerPhone}`)
  }

  return (
    <Menu position="left-start">
      <Menu.Target>
        <Box className={styles.bell}>
          {unreadNotificationsCount > 0 ? (
            <Badge size="xl" circle color="red">
              {unreadNotificationsCount}
            </Badge>
          ) : (
            <IconBell size={bellSize} />
          )}
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Notificações</Menu.Label>
        <Menu.Divider />
        {queryNotifications.length > 0 ? (
          queryNotifications.map((notification) => (
            <NotificationMenuItem key={notification.id} notification={notification} onClick={handleNotificationClick} />
          ))
        ) : (
          <Menu.Item disabled className={styles.menuItemText}>
            <Text className={styles.menuItemText}>Você não possui notificações</Text>
          </Menu.Item>
        )}
        {updatedAt ? (
          <Menu.Label style={{ textAlign: 'center', fontSize: '10px' }}>
            Atualizado às {updatedAt ? format(updatedAt, 'dd/MM/yyyy HH:mm:ss') : ''}
          </Menu.Label>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  )
}

interface NotificationMenuItemProps {
  notification: Notification
  onClick: (notification: Notification) => void
}

const NotificationMenuItem = ({ notification, onClick }: NotificationMenuItemProps) => {
  const menuItemStyles = `${styles['menu-item']} ${notification.isRead ? styles['read'] : ''}`
  const icon = notification.isRead ? <IconMailOpened size={14} /> : <IconMail size={16} />
  const titles: { [key: string]: string } = { OFFER_RECEIVED: 'Oferta recebida' }
  const messages: { [key: string]: string } = { OFFER_RECEIVED: `${notification.producerName} enviou uma oferta.` }
  const label = {
    title: titles[notification.notificationType],
    message: messages[notification.notificationType],
  }
  return (
    <Menu.Item
      className={menuItemStyles}
      key={notification.id}
      leftSection={icon}
      onClick={() => {
        onClick(notification)
      }}
    >
      <Text className={styles.menuItemTitle}>{label.title}</Text>
      <Text>{label.message}</Text>
    </Menu.Item>
  )
}
