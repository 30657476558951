import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { theme } from 'src/theme'
import type { MantineProps } from 'src/types'

export default function Mantine({ children }: MantineProps) {
  return (
    <MantineProvider theme={theme}>
      <ModalsProvider>
        <Notifications position="top-right"  />
        {children}
      </ModalsProvider>
    </MantineProvider>
  )
}
