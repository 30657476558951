import { Routes, Route } from 'react-router-dom'
import PrivatePage from 'src/components/PrivatePage'
import SupplyBySKU from 'src/components/SupplyBySKU'
import OrdersBySKU from 'src/components/OrdersBySKU'
import CreateOrUpdateSKU from 'src/components/SKU/CreateOrUpdateSKU'

export default function SKUSupply() {
  return (
    <PrivatePage>
      <Routes>
        <Route index element={<SupplyBySKU />} />
        <Route path="cadastrar" element={<CreateOrUpdateSKU />} />
        <Route path=":code/editar" element={<CreateOrUpdateSKU />} />
        <Route path=":sku" element={<OrdersBySKU />} />
      </Routes>
    </PrivatePage>
  )
}
