import { Unsubscribe, collection, doc, getDoc, limit, onSnapshot, orderBy, query, startAfter } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDistributor } from 'src/providers/Distributor'
import { Channel, Message, MESSAGE_STATUS } from 'src/types'
import converter from 'src/requests/firebase/utils/dataConverter'
import { useErrorBoundary } from 'react-error-boundary'
import { parseAddWhatsAppPhonePrefix } from 'src/utils/parseBrazilianPhones'
import { dispatchErrorNotification } from 'src/utils/dispatchErrorNotification'

export function useChannel(phone?: string) {
  const { distributor } = useDistributor()
  const { showBoundary } = useErrorBoundary()
  const navigate = useNavigate()
  const [isLoadingChannel, setIsLoadingChannel] = useState(true)
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false)
  const supplier = distributor?.producers.find((p) => p.phone === phone)
  const [channel, setChannel] = useState<Channel>({
    supplier,
    createdAt: new Date(),
    chatlogs: [],
    updatedAt: new Date(),
  })
  const [page, setPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(false)
  const [lastChatlog, setLastChatlog] = useState<Message>()
  const [triggerSentMessage, setTriggerSentMessage] = useState(false)

  const increasePage = () => {
    if (isLoadingMoreMessages) return
    setPage((prev) => prev + 1)
    setIsLoadingMoreMessages(true)
  }

  function getMessageStatusOrder(status: MESSAGE_STATUS) {
    switch (status) {
      case MESSAGE_STATUS.FAILED:
        return 0
      case MESSAGE_STATUS.QUEUED:
        return 1
      case MESSAGE_STATUS.SENDING:
        return 2
      case MESSAGE_STATUS.SENT:
        return 3
      case MESSAGE_STATUS.DELIVERED:
        return 4
      case MESSAGE_STATUS.UNDELIVERED:
        return 5
      case MESSAGE_STATUS.READ:
        return 6
      case MESSAGE_STATUS.CANCELED:
        return 7
      default:
        return -1
    }
  }

  useEffect(() => {
    let unsub: Unsubscribe | undefined
    ;(async () => {
      if (phone && distributor?.distributorId) {
        if (!supplier) {
          const error = new Error('Unavailable supplier')
          const context = {
            title: 'Fornecedor indisponível',
            message: 'Você será direcionado para a home em instantes'
          }
          const callback = () => {
            navigate('/')
          }

          await dispatchErrorNotification(error, context, { callback })

          return
        }
    
        const channelRef = doc(
          db,
          'distributors',
          distributor.distributorId,
          'channels',
          parseAddWhatsAppPhonePrefix(supplier.phone),
        ).withConverter(converter<Channel>())

        const q = query(
          collection(channelRef, 'chatlogs'),
          orderBy('createdAt', 'desc'),
          ...(lastChatlog ? [startAfter(lastChatlog?.createdAt)] : []),
          limit(50),
        ).withConverter(converter<Message>())

        const cn = await getDoc(channelRef)

        unsub = onSnapshot(q, (querySnapshot) => {
          try {
            const newChatlogs: Message[] = []
            querySnapshot.forEach((doc) => {
              newChatlogs.push({
                ...doc.data(),
                id: doc.id,
              })
            })

            setIsLastPage(newChatlogs.length < 50 * page)

            if (cn.exists()) {
              setChannel((prev) => {
                const chatLogsMap = new Map(prev.chatlogs.map((log) => [log.id, log]))

                newChatlogs.forEach((newLog) => {
                  const existingLog = chatLogsMap.get(newLog.id)
                  if (existingLog && newLog.status) {
                    const existingStatus = existingLog.status
                    if (
                      existingStatus &&
                      getMessageStatusOrder(existingStatus) > getMessageStatusOrder(newLog.status)
                    ) {
                      return
                    }
                  }
                  chatLogsMap.set(newLog.id, newLog)
                })

                const updatedChatlogs = Array.from(chatLogsMap.values())

                if (page > 1) {
                  setLastChatlog(updatedChatlogs[updatedChatlogs.length - 1])
                } else if (page === 1) {
                  setLastChatlog(newChatlogs[newChatlogs.length - 1])
                }

                return {
                  ...prev,
                  chatlogs: updatedChatlogs,
                }
              })

              setIsLastPage(newChatlogs.length < 50)
            }
          } catch (error) {
            if (error instanceof Error) {
              console.log(error.message)
              showBoundary(error)
            } else {
              console.log(error)
              showBoundary(error)
            }
          } finally {
            setIsLoadingChannel(false)
            if (page > 1) setIsLoadingMoreMessages(false)
          }
        })
      }
    })()

    return () => {
      if (unsub) {
        unsub()
      }
    };
    // FIXME: Run ESLint and fix useEffect dependencies
  }, [phone, distributor?.distributorId, page, triggerSentMessage])

  return {
    channel,
    isLoadingChannel,
    isLoadingMoreMessages,
    loadMoreMessages: () => increasePage(),
    isLastPage,
    sentMessage: () => setTriggerSentMessage((prev) => !prev),
  }
}
