import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth'
import { getAnalytics, setUserId, logEvent } from 'firebase/analytics'
import firebaseCredentials from '../../firebase-credentials.json'

const firebaseConfig = firebaseCredentials

const firebaseapp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseapp)
const firebaseAuth = getAuth(firebaseapp)
const logoutFirebase = () => signOut(firebaseAuth)
const analytics = getAnalytics(firebaseapp)

const setAnalyticsUserId = (userId: string) => setUserId(analytics, userId)
const logAnalyticsEvent = (eventName: string, eventParams?: Record<string, any>) =>
  logEvent(analytics, eventName, eventParams)

export default db
export { firebaseAuth, signInWithCustomToken, logoutFirebase, setAnalyticsUserId, logAnalyticsEvent }
