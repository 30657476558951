import { Alert, Box, Button, Flex, Group, Table, Text } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { modals } from '@mantine/modals'
import { IconFileTypeCsv, IconUpload, IconX } from '@tabler/icons-react'
import { parse as csvParse } from 'csv-parse/sync'
import { useMemo, useState } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { Demand, SKU } from 'src/types'

export type UploadDemandModalContentProps = {
  onConfirm?: (Demands: Demand[]) => void
}

export function UploadDemandModalContent({ onConfirm }: UploadDemandModalContentProps) {
  const [data, setData] = useState<Demand[]>([])
  const [error, setError] = useState('')
  const { distributor } = useDistributor()

  const handleFileUpload = (files: File[]) => {
    setData([])
    const file = files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const text = e.target?.result
        if (text && typeof text === 'string') {
          try {
            const parsedData = parseCSV(text)

            const sanitizedData = parsedData.map((data) => ({
              code: String(data.code ?? data.Código ?? data.Codigo ?? data.codigo ?? data.código).toUpperCase(),
              value: Number(data.value ?? data.Quantidade ?? data.quantidade),
              sku: String(data.SKU ?? data.sku).toUpperCase(),
            }))

            setData(sanitizedData)
          } catch (error) {
            console.log(error)
            setError('Ocorreu um erro, garanta que seu arquivo não contém comentários e esteja na estrutura esperada.')
          }
        }
      }
      reader.readAsText(file)
    }
  }

  const sanitizedSheetData = useMemo(() => {
    if (!data) return []

    return data.map((item) => {
      const sku = distributor?.skus.find((p) => p['Código'] === item.code)

      return sanitizeSheetData(item, sku)
    })
  }, [data, distributor?.skus])

  const shouldDisableButtons = useMemo(() => {
    return sanitizedSheetData.some((item) => !item.sku.found)
  }, [sanitizedSheetData])

  const parseCSV = (csvText: string): Record<string, string>[] => {
    return csvParse(csvText, { columns: true, skip_empty_lines: true, trim: true })
  }

  function closeModal() {
    modals.close('send-demand')
  }

  function handleConfirm() {
    onConfirm?.(
      sanitizedSheetData.map((demand) => ({
        code: demand.sku.code,
        sku: demand.sku.SKU!,
        value: demand.value,
      })),
    )
  }

  return (
    <Box>
      <Dropzone onDrop={handleFileUpload} accept={['text/csv']} multiple={false}>
        <Group justify="center" gap="xl" style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload size={32} stroke={1.5} />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={32} stroke={1.5} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileTypeCsv size={32} stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Arraste o arquivo aqui ou clique para selecionar o arquivo
            </Text>
            <Text size="sm" c="dimmed" inline mt={7}>
              O arquivo deve ser do tipo CSV e ter as colunas Código e Quantidade.
            </Text>
          </div>
        </Group>
      </Dropzone>
      {!!error && (
        <Alert mt="md" variant="light" color="red" title="Erro" icon={<IconX />}>
          {error}
        </Alert>
      )}
      {!!data.length && (
        <Box
          mah={300}
          style={{
            overflowY: 'auto',
          }}
        >
          <Table mt="md">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Código</Table.Th>
                <Table.Th>SKU</Table.Th>
                <Table.Th>Quantidade</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {sanitizedSheetData.map((item) => (
                <Table.Tr key={item.sku.code} bg={colorConditional(!item.sku.found, 'var(--mantine-color-red-light)')}>
                  <Table.Td>{item.sku.code}</Table.Td>
                  <Table.Td c={colorConditional(!item.sku.found, 'red')}>{item.sku.SKU ?? 'Não encontrado'}</Table.Td>
                  <Table.Td>{item.value}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Box>
      )}
      <Flex justify="end" align="center" mt="xl" gap="sm">
        <Button onClick={closeModal} variant="outline" color="gray" c="black">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} disabled={shouldDisableButtons}>
          Confirmar
        </Button>
      </Flex>
    </Box>
  )
}

function colorConditional(cond: boolean, color: string) {
  if (!cond) return

  return color
}

function sanitizeSheetData(item: Demand, sku?: SKU) {
  return {
    sku: {
      ...sku,
      found: !!sku,
      code: sku?.['Código'] ?? item.code,
    },
    value: item.value,
  }
}
