import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { SUPPLIER_TYPE } from 'src/types'
import { removeDuplicatedArrayItems } from 'src/utils/arrays/removeDuplicated'

export function useMiddlemen() {
  const { distributor } = useDistributor()

  const middlemen = useMemo(() => {
    return distributor?.producers.filter((s) => s.type === SUPPLIER_TYPE.MIDDLEMAN) || []
  }, [distributor?.producers])

  const labelValueMiddlemen = useMemo(() => {
    const options = middlemen.map((middleman) => ({
      value: middleman.id,
      label: middleman.name,
      description: middleman.phone,
    }))

    return removeDuplicatedArrayItems(options, 'value')
  }, [middlemen])

  return {
    middlemen,
    labelValueMiddlemen,
  }
}
