import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useQueryParams<T extends Record<string, any>>() {
  const [currentQueryParameters, setSearchParams] = useSearchParams()

  function getQueryParams(key: string): string
  function getQueryParams(): URLSearchParams
  function getQueryParams(key?: string) {
    if (key) {
      return currentQueryParameters.get(key) ?? ''
    }
    return currentQueryParameters
  }

  const setQueryParams = useCallback(
    (object: T) => {
      Object.entries(object).forEach(([key, value]) => {
        const currentValue = currentQueryParameters.get(key)

        if (value) {
          currentQueryParameters.set(key, String(value))
        } else if (currentValue && !value) {
          currentQueryParameters.delete(key)
        }
      })

      setSearchParams(currentQueryParameters)
    },
    [setSearchParams, currentQueryParameters],
  )

  return {
    getQueryParams,
    setQueryParams,
  }
}
