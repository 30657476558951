import { api } from 'src/services/api'
import httpErrorHandler from 'src/utils/httpErrorHandler'

export default async function getCustomToken() {
  try {
    const result = await api.get<{ customToken: string }>('/custom-token')

    return result.data.customToken
  } catch (error) {
    httpErrorHandler(error)
    return ''
  }
}
