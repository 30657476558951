import { Button, Collapse, Stack } from '@mantine/core'
import {
  IconChevronDown
} from '@tabler/icons-react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { NavItemType } from './NavbarContent'
import styles from './Navbar.module.css'

type navLinkClassNameContext = {
  isActive: boolean
}

function navLinkClassName({ isActive }: navLinkClassNameContext) {
  return isActive ? styles.linkActive : styles.link
}

export default function NavbarItem({ item }: { item: NavItemType }) {
  const [opened, setOpened] = useState(false)

  if (item.children) {
    return (
      <>
        <Stack style={{ display: 'grid', justifyContent: 'left'}}>
          <Button 
            onClick={() => setOpened((o) => !o)} 
            variant='transparent' 
            fullWidth
            leftSection={
              <IconChevronDown
                size={17}
                style={{
                  transform: opened ? `rotate(180deg)` : 'none',
                  transition: 'transform 200ms ease',
                }}
              />
            }
          >
            {item.text}
          </Button>
        </Stack>
        <Collapse in={opened}>
            {item.children.map((child, index) => (
              <NavLink
                className={navLinkClassName}
                key={index}
                to={child.to || '#'}
                end
              >
                <Button leftSection={child.icon} variant="white" fullWidth style={{ paddingLeft: 30 }}>
                  {child.text}
                </Button>
              </NavLink>
            ))}
        </Collapse>
      </>
    )
  }

  return (
    <NavLink
      to={item.to || '#'}
      className={navLinkClassName}
    >
      <Button leftSection={item.icon} variant="white" fullWidth>
        {item.text}
      </Button>
    </NavLink>
  )
}