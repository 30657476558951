import { ActionIcon, Flex, InputBase } from '@mantine/core'
import { useUpdatePrice } from '../OrdersBySKU/hooks/useUpdatePrice'
import IntlCurrencyInput from 'react-intl-currency-input'
import { IconPencil } from '@tabler/icons-react'

interface PriceProps {
  setShouldRefetchOrders: (value: boolean) => void
  product: {
    price: number
    unit: string
    sku: string
  }
  orderId: string
}

export const Price = ({ setShouldRefetchOrders, product, orderId }: PriceProps) => {
  const {
    priceRef,
    handleBlurPrice,
    handleChangePrice,
    handleClickToUpdatePrice,
    handleEnterPrice,
    isUpdatingPriceId,
    updatedPrice,
  } = useUpdatePrice({
    onSave: () => setShouldRefetchOrders(true),
  })

  return (
    <Flex>
      {isUpdatingPriceId ? (
        <InputBase
          inputRef={priceRef}
          component={IntlCurrencyInput}
          variant="filled"
          currency="BRL"
          defaultValue={updatedPrice ?? product.price}
          value={updatedPrice ?? product.price}
          max={Number.MAX_SAFE_INTEGER}
          style={{ width: 80 }}
          onChange={handleChangePrice}
          onBlur={handleBlurPrice}
          onKeyPress={handleEnterPrice}
          config={{
            locale: 'pt-BR',
            formats: {
              number: {
                BRL: {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              },
            },
          }}
        />
      ) : (
        <Flex align="center" justify="center" w="100%">
          R${product.price}/{product.unit}
          <ActionIcon
            ml={2}
            variant="subtle"
            onClick={handleClickToUpdatePrice({
              orderId,
              skuName: product.sku,
            })}
          >
            <IconPencil size="20" />
          </ActionIcon>
        </Flex>
      )}
    </Flex>
  )
}
