import { api } from 'src/services/api'
import { OrderImport } from 'src/types'

type ImportOrdersContext = {
  distributorId: string
  orders: OrderImport[]
  send: boolean
}

export default async function importOrders(body: ImportOrdersContext) {
  await api.post(`/import-orders`, body)
}
