import { Box, Flex } from '@mantine/core'
import { BalloonProps } from '../Chat'
import { getStatusIcon } from '../statuses'
import styles from '../Chat.module.css'

type BalloonUnsupportedProps = Pick<BalloonProps, 'className' | 'status' | 'hour' | 'resendFailedMessage'> & {
  hideInfo?: boolean
}

export function BalloonUnsupported({
  className,
  hour,
  status,
  hideInfo,
  resendFailedMessage,
  ...rest
}: BalloonUnsupportedProps) {
  const StatusIcon = getStatusIcon(status)

  return (
    <Flex direction="column" className={className} {...rest}>
      <Box component="span" className={styles.message_balloon_unsupported}>
        Tipo da mensagem não suportado.
      </Box>
      <Box className={styles.message_balloon_data_info}>
        <Box component="span" className={styles.message_balloon_time}>
          {hour}
        </Box>
        {!!status && !!StatusIcon && <StatusIcon resendFailedMessage={resendFailedMessage} />}
      </Box>
    </Flex>
  )
}
