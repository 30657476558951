import { notifications } from '@mantine/notifications'
import { KeyboardEvent, useCallback, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import updateOrderWithAIPreview from 'src/requests/solver/updateOrderWithAIPreview'
import { GreatPurchase } from 'src/types'

type Options = {
  onSuccess?(data?: GreatPurchase): void
}

type ProductData = {
  skuName: string
  orderId: string
  greatPurchaseId: string
}

export function useUpdateDesiredQty(options?: Options) {
  const desiredQtyRef = useRef<HTMLInputElement>(null)
  const [productData, setProductData] = useState<ProductData | null>(null)
  const [updatedQuantities, setUpdatedQuantities] = useState<Record<string, number>>({})
  const { distributor } = useDistributor()

  const { showBoundary } = useErrorBoundary()

  const saveDesiredQty = useCallback(
    async (data: ProductData, newQty: number) => {
      try {
        const purchase = await updateOrderWithAIPreview({
          desiredQty: newQty,
          orderId: data.orderId,
          distributorId: distributor!.distributorId,
          id: data.greatPurchaseId,
          sku: data.skuName,
        })

        setProductData(null)
        options?.onSuccess?.(purchase)
        notifications.show({
          autoClose: 3000,
          message: 'Quantidade alterada com sucesso!',
        })
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    },
    [distributor, options, showBoundary],
  )

  const handleClickToUpdateQty = useCallback(
    (data: ProductData) => () => {
      setProductData(data)
      setTimeout(() => {
        desiredQtyRef.current?.focus()
      }, 100)
    },
    [],
  )

  function handleChangeQty(val: string | number) {
    setUpdatedQuantities((old) => ({
      ...old,
      [generateUpdateQtyId(productData)]: Number(val),
    }))
  }

  const handleEnterQty = useCallback(
    (event: KeyboardEvent) => {
      const newQty = updatedQuantities[generateUpdateQtyId(productData)]
      const enterPressed = event.key === 'Enter'
      if (productData && enterPressed && (newQty || typeof newQty === 'number')) {
        saveDesiredQty(productData, newQty)
      } else if (enterPressed) {
        setProductData(null)
      }
    },
    [saveDesiredQty, updatedQuantities, productData],
  )

  const handleBlurQty = useCallback(() => {
    const newQty = updatedQuantities[generateUpdateQtyId(productData)]
    if (productData && (newQty || typeof newQty === 'number')) {
      saveDesiredQty(productData, newQty)
    } else {
      setProductData(null)
    }
  }, [saveDesiredQty, updatedQuantities, productData])

  return {
    handleClickToUpdateQty,
    desiredQtyRef,
    isUpdatingQtyId: generateUpdateQtyId(productData),
    handleChangeQty,
    updatedQuantities,
    handleEnterQty,
    handleBlurQty,
  }
}

export function generateUpdateQtyId(productData: Pick<ProductData, 'orderId' | 'skuName'> | null) {
  return `${productData?.orderId}${productData?.skuName}`
}
