import { MarketSummary } from 'src/components/MarketReports/SelectView/types'
import { api } from 'src/services/api'

type Options = {
  signal?: AbortSignal
  params: {
    startDate: Date
    endDate: Date
    items: string
  }
}

export async function getMarketSummary(distributorId: string, options: Options) {
  const response = await api.get<MarketSummary[]>(`/distributors/${distributorId}/market-summary`, options)

  return response.data
}
