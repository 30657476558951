import { ActionIcon, Flex, InputBase } from '@mantine/core'
import { IconPencil } from '@tabler/icons-react'
import { useUpdateVolume } from '../OrdersBySKU/hooks/useUpdateVolume'
import { IMaskInput } from 'react-imask'
import { isInfiniteStock, parseInfiniteStock } from 'src/utils/products/volume'

interface PriceProps {
  setShouldRefetchOrders: (value: boolean) => void
  product: {
    price: number
    unit: string
    sku: string
    volume: number
  }
  orderId: string
}

export const Volume = ({ setShouldRefetchOrders, product, orderId }: PriceProps) => {
  const {
    volumeRef,
    handleClickToUpdateVolume,
    isUpdatingVolumeId,
    handleChangeVolume,
    handleEnterVolume,
    handleBlurVolume,
  } = useUpdateVolume({
    onSave: () => setShouldRefetchOrders(true),
  })

  const isUpdatingVolume = isUpdatingVolumeId === orderId

  return (
    <Flex>
      {isUpdatingVolume ? (
        <InputBase
          inputRef={volumeRef}
          variant="filled"
          component={IMaskInput}
          mask={/^\d+$/}
          defaultValue={product.volume}
          onChange={handleChangeVolume}
          onBlur={handleBlurVolume}
          onKeyDown={handleEnterVolume}
          style={{ width: 80 }}
        />
      ) : (
        <Flex align="center" justify="center" w="100%">
          {parseInfiniteStock(product.volume)} {product.unit}
          {!isInfiniteStock(product.volume) && (
            <ActionIcon
              ml={4}
              variant="subtle"
              onClick={handleClickToUpdateVolume({
                orderId: orderId,
                skuName: product.sku,
              })}
            >
              <IconPencil size="20" />
            </ActionIcon>
          )}
        </Flex>
      )}
    </Flex>
  )
}
