import { arrayRemove, arrayUnion, doc, runTransaction } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { Distributor, TCreateUpdateOrDeleteSKU } from 'src/types'
import dataConverter from '../utils/dataConverter'

export async function updateSKU({ distributorId, ...updatedProduct }: TCreateUpdateOrDeleteSKU) {
  const distributorRef = doc(db, 'distributors', distributorId).withConverter(dataConverter<Distributor>())
  await runTransaction(db, async (txn) => {
    const distributorDoc = await txn.get(distributorRef)

    const distributorData = distributorDoc.data()

    const sku = distributorData?.skus.find((sku) => sku['Código'] === updatedProduct['Código'])

    if (sku) {
      txn.update(distributorRef, {
        skus: arrayRemove(sku),
      })
    }
    txn.update(distributorRef, {
      skus: arrayUnion(updatedProduct),
    })

    const skuDemand = distributorData?.demands?.find((demand) => demand.code === sku?.['Código'])

    if (skuDemand) {
      txn.update(distributorRef, {
        demands: arrayRemove(skuDemand),
      })

      txn.update(distributorRef, {
        demands: arrayUnion({
          code: skuDemand.code,
          value: skuDemand.value,
          sku: updatedProduct.SKU,
        }),
      })
    }
  })
}
