import { useRef } from 'react'

import { Modal, Button, Text, NumberInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { object, number, date, InferType, array } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useDistributor } from 'src/providers/Distributor'
import { dispatchErrorNotification } from 'src/utils/dispatchErrorNotification'
import { api } from 'src/services/api'
import type { DateValue } from '@mantine/dates'

const schema = object({
  integrationId: number().required().integer().positive(),
  dateRange: array().of(date()).min(2).required(),
})

type Schema = InferType<typeof schema>

type Props = {
  title: string
  opened: boolean
  onClose: () => void
}

export default function ExportSAPModal({ opened, title, onClose }: Props) {
  const { distributor } = useDistributor()
  const downloadEl = useRef<HTMLAnchorElement>(null)
  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      integrationId: undefined,
      dateRange: [],
    },
  })

  const onSubmit = async (data: Schema) => {
    if (!distributor) {
      const error = new Error('Invalid distributor')
      const context = {
        title: 'Distribuidor inválido',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
      }

      await dispatchErrorNotification(error, context, { callback: () => {} })

      return
    }

    let [startDate, endDate] = data.dateRange
    startDate?.setUTCHours(0)
    endDate?.setUTCHours(0)

    const res = await api.post(
      '/orders/export',
      {
        distributorId: distributor.distributorId,
        integrationId: data.integrationId,
        startDate: startDate?.toJSON(),
        endDate: endDate?.toJSON(),
      },
      {
        responseType: 'blob',
      },
    )

    const matches = String(res.headers['content-disposition']).match(/filename="([^"]+)"/)
    let filename = ''

    if (!matches) {
      filename = 'orders.zip'
    } else {
      const [, matchedFilename] = matches
      filename = matchedFilename
    }

    // XXX: Handle exported orders download
    const url = URL.createObjectURL(res.data)
    downloadEl.current!.href = url
    downloadEl.current!.download = filename
    downloadEl.current!.click()
  }

  return (
    <Modal opened={opened} onClose={onClose} title={<Text fw={500}>{title}</Text>}>
      <a ref={downloadEl} style={{ display: 'none' }} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Controller
          control={form.control}
          name="integrationId"
          render={({ field, fieldState }) => (
            <NumberInput
              label="Insira o DocNum"
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={form.control}
          name="dateRange"
          render={({ field, fieldState }) => (
            <DatePickerInput
              labelProps={{
                fw: 400,
              }}
              label="Selecione a data de entrega para exportação"
              allowSingleDateInRange
              valueFormat="DD, MMM/YY"
              locale="pt-br"
              clearable
              type="range"
              placeholder="Uma data ou um intervalo de datas"
              value={field.value as [DateValue, DateValue]}
              onChange={field.onChange}
              error={fieldState.error?.message}
            />
          )}
        />

        <Button
          type="submit"
          disabled={!(form.formState.isDirty && form.formState.isValid)}
          loading={form.formState.isSubmitting}
          size="sm"
          radius="sm"
          mt="lg"
        >
          Exportar
        </Button>
      </form>
    </Modal>
  )
}
