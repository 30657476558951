import { ActionIcon, Button, Checkbox, Flex, InputBase, Menu, Text, Card, Group, Stack } from '@mantine/core'
import { IMaskInput } from 'react-imask'
import formatPrice from 'src/utils/formatPrice'
import { parseInfiniteStock } from 'src/utils/products/volume'
import { useUpdateDemand } from '../../hooks/useUpdateDemand'
import { useOrders } from 'src/providers/Orders'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react'
import { Link } from 'react-router-dom'

const MobileBodySupply: React.FC<any> = ({ items, edit, del, onIndividualCheckboxChange, isCheckboxChecked }) => {
  const { setShouldRefetch } = useOrders()

  const {
    updatingDemandInputRef,
    handleBlurDemand,
    handleChangeDemand,
    handleClickToUpdateDemand,
    handleSaveDemand,
    updatingProductCode,
  } = useUpdateDemand({
    onSave: () => setShouldRefetch(true),
  })
  const { getQueryParams } = useQueryParams()

  return (
    <Stack>
      {items.map((sku: any) => {
        const lastBuyoutPrice = sku.lastBuyoutPrice ? formatPrice(sku.lastBuyoutPrice) : '-'
        const minPrice = formatPrice(sku.minPrice)
        const isLowOffer = sku.demand > sku.volume
        const stringSKU = String(sku['SKU'])
        const skuCode = String(sku['Código'])
        const isUpdatingDemand = updatingProductCode === skuCode
        const searchParams = getQueryParams()

        return (
          <Card key={skuCode} shadow="sm" padding="lg">
            <Group mb="xs" justify="space-between">
              <Button
                p="0"
                component={Link}
                to={{
                  pathname: skuCode,
                  search: searchParams.toString(),
                }}
                variant="transparent"
              >
                {stringSKU}
              </Button>

              <Menu shadow="md" withArrow position="bottom-end">
                <Menu.Target>
                  <Button onClick={(e) => e.stopPropagation()} variant="subtle" size="compact-sm">
                    <IconDotsVertical size={20} />
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item onClick={edit(skuCode)} leftSection={<IconPencil size={14} />}>
                    Editar
                  </Menu.Item>
                  <Menu.Item onClick={del(sku)} color="red" leftSection={<IconTrash size={14} />}>
                    Remover
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
            <Text>{sku['Categoria']}</Text>

            <Text size="sm" c="text">
              Código: {skuCode}
            </Text>
            <Text size="sm" c="text">
              Preço da última compra: {lastBuyoutPrice}
            </Text>
            <Text size="sm" c="text">
              Preço mínimo ofertado: {minPrice}
            </Text>
            <Text size="md" fw={600} c="text">
              Volume: {parseInfiniteStock(Number(sku.volume))}
            </Text>
            <Text size="sm" c="text">
              Medida: {sku['Medida']}
            </Text>
            <Flex align="center" justify="space-between" mt="md">
              {isUpdatingDemand ? (
                <InputBase
                  w={80}
                  inputRef={updatingDemandInputRef}
                  variant="filled"
                  component={IMaskInput}
                  mask={/^\d+$/}
                  defaultValue={sku.demand}
                  onAccept={handleChangeDemand}
                  onBlur={handleBlurDemand}
                  onKeyDown={handleSaveDemand}
                />
              ) : (
                <Flex align="center" justify="flex-start" w="100%">
                  <Text c={sku.demand ? (isLowOffer ? 'red' : 'main') : 'unset'}>Demanda: {sku.demand || '-'}</Text>
                  <ActionIcon
                    ml={4}
                    variant="subtle"
                    onClick={handleClickToUpdateDemand({
                      code: skuCode,
                      sku: stringSKU,
                    })}
                  >
                    <IconPencil />
                  </ActionIcon>
                </Flex>
              )}
              <Checkbox
                aria-label="Select row"
                defaultValue={stringSKU}
                checked={isCheckboxChecked(stringSKU)}
                onChange={onIndividualCheckboxChange}
              />
            </Flex>
          </Card>
        )
      })}
    </Stack>
  )
}

export default MobileBodySupply
