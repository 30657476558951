import { isSameDay } from 'date-fns'
import { useMemo } from 'react'
import { UseSKUsWithOrderDataReturn } from 'src/hooks/useSKUs/types'
import { useOrders } from 'src/providers/Orders'
import { Order, SKU } from 'src/types'

type Filters = {
  deliveryDate?: Date | null
  selectedSuppliers?: string[]
}

export function useCalculateSKUsMetrics(
  list: UseSKUsWithOrderDataReturn[],
  { deliveryDate, selectedSuppliers }: Filters,
) {
  const { duplicatedOrdersWithExpirationDate: orders } = useOrders()

  const listWithMetrics = useMemo(() => {
    return list.map((item) => {
      const ordersWithSku = orders?.filter((order) =>
        order.products.find((product) => product.sku === item['SKU'] && order.sentOrder.length === 0),
      )

      const filteredOrders = ordersWithSku?.filter((item) => {
        const filters = []

        if (deliveryDate) {
          filters.push(isSameDay(new Date(item.deliveryDate), deliveryDate))
        }

        if (selectedSuppliers?.length) {
          filters.push(selectedSuppliers.includes(item.id))
        }

        if (!filters.length) {
          return true
        }

        return filters.every((bool) => bool)
      })

      const volumes = calculateVolume({
        orders: filteredOrders,
        sku: item,
      })

      const productAveragePrice =
        ordersWithSku?.[0]?.products.find((p) => p.sku === item.SKU)?.metrics?.pastDaysAverageBuyoutPrice ?? 0

      const prices = filteredOrders
        ?.map((order) => order.products.find((product) => product.sku === item['SKU'])?.price ?? 0)
        .filter((price) => price > 0)
      const minPrice = prices && prices.length > 0 ? Math.min(...prices) : 0
      const volume = volumes || 0

      return {
        ...item,
        volume,
        minPrice,
        pastDaysAverageBuyoutPrice: productAveragePrice,
      }
    })
  }, [deliveryDate, list, orders, selectedSuppliers])

  return listWithMetrics
}

type CalculateContext = {
  sku: SKU
  orders?: Order[]
}

function calculateVolume({ orders = [], sku }: CalculateContext) {
  return orders.reduce((acc, order) => {
    const product = order.products.find((product) => product.sku === sku['SKU'])
    return acc + Number(product?.volume || 0)
  }, 0)
}
