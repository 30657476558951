import { Modal, Text } from '@mantine/core'
import { UploadDemandModalContent, UploadDemandModalContentProps } from './UploadDemandModalContent'

type UploadDemandModalProps = UploadDemandModalContentProps & {
  opened: boolean
  onClose: () => void
}

export function UploadDemandModal(props: UploadDemandModalProps) {
  return (
    <Modal title={<Text fw={500}>Criar demanda</Text>} size="lg" opened={props.opened} onClose={props.onClose}>
      <UploadDemandModalContent onConfirm={props.onConfirm} />
    </Modal>
  )
}
