import { Routes, Route } from 'react-router-dom'
import { MarketReports } from 'src/components/MarketReports'
import PrivatePage from 'src/components/PrivatePage'

export default function MarketReportsPage() {
  return (
    <PrivatePage>
      <Routes>
        <Route index element={<MarketReports />} />
      </Routes>
    </PrivatePage>
  )
}
