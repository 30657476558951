import { Accordion, Alert, Box, Button, Flex, Group, Loader, LoadingOverlay, Modal, Stack, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useDistributor } from 'src/providers/Distributor'
import { useOrders } from 'src/providers/Orders'
import applyOrderWithAIPreview from 'src/requests/solver/applyOrderWithAIPreview'
import deleteOrderWithAIPreview from 'src/requests/solver/deleteOrderWithAIPreview'
import generateOrderWithAIPreview from 'src/requests/solver/generateOrderWithAIPreview'
import { parseInfiniteStock } from 'src/utils/products/volume'
import styles from '../SupplyBySKU.module.css'
import { GenerateOrderWithAITable } from '../Tables/GenerateOrderWithAI'
import { useEffect, useMemo, useState } from 'react'
import { DatePickerInput } from '@mantine/dates'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { GreatPurchase, SolverStatus } from 'src/types'
import { IconXboxX } from '@tabler/icons-react'
import { formatSign } from 'src/utils/intl/formatSign'
import formatPrice from 'src/utils/formatPrice'
import { useUpdateDesiredQty } from '../Tables/GenerateOrderWithAI/hooks/useUpdateDesiredQty'
import { generateDifferingMessage, sumProductOffersQuantity, sumTotalOrderPrice } from './utils'
import { parseDeliveryDate } from 'src/utils/dates/parseDeliveryDate'
import { AxiosError } from 'axios'
import { UseSKUsWithOrderDataReturn } from 'src/hooks/useSKUs/types'

type GenerateOrderWithAIModalProps = {
  opened: boolean
  onClose?: () => void
  selectedSKUs: string[]
  offers: UseSKUsWithOrderDataReturn[]
}

const SOLVER_SUCCESS_STATUSES = [SolverStatus.FEASIBLE, SolverStatus.OPTIMAL]

const SOLVER_STATUS_MESSAGES: Record<SolverStatus, string> = {
  [SolverStatus.OPTIMAL]: '',
  [SolverStatus.FEASIBLE]:
    'A solução encontrada é uma alternativa viável, porém não é perfeita (Houve potencial arrendondamento).',
  [SolverStatus.INFEASIBLE]: 'Dadas as ofertas e demanda informadas, não foi possível encontrar uma solução.',
  [SolverStatus.NOFEASIBLE]: 'Dadas as ofertas e demanda informadas, não há uma solução que satisfaça as restrições.',
  [SolverStatus.UNBNDOUNDED]: 'Esse problema não tem uma solução finita ótima.',
  [SolverStatus.UNDEFINED]: 'Dados insuficientes para encontrar uma solução, favor contatar o time de suporte.',
  [SolverStatus.UNKNOWN]: 'Erro inesperado, favor contatar o time de suporte.',
}

export function GenerateOrderWithAIModal({ opened, onClose, selectedSKUs, offers }: GenerateOrderWithAIModalProps) {
  const { setShouldRefetch } = useOrders()
  const { distributor } = useDistributor()
  const navigate = useNavigate()
  const { getQueryParams } = useQueryParams()
  const [preview, setPreview] = useState<GreatPurchase | undefined>()

  const updateDesiredQty = useUpdateDesiredQty({
    onSuccess(data) {
      setPreview(data)
    },
  })

  const deliveryDateQueryParam = getQueryParams('date')

  const createMutation = useMutation({
    mutationFn: generateOrderWithAIPreview,
    onSuccess(data) {
      setPreview(data)
    },
    onError(error) {
      if (error instanceof Error) {
        notifications.show({
          color: 'red',
          title: 'Erro ao gerar pedido',
          message: error.message,
        })
      }
    }
  })

  const deleteMutation = useMutation({
    mutationFn: deleteOrderWithAIPreview,
    onError(error) {
      if (error instanceof Error) {
        notifications.show({
          color: 'red',
          title: 'Erro ao deletar pedido',
          message: error.message,
        })
      }
    }
  })

  const applyMutation = useMutation({
    mutationFn: applyOrderWithAIPreview,
    onError(error) {
      if (error instanceof Error) {
        notifications.show({
          color: 'red',
          title: 'Erro ao criar pedido',
          message: error.message,
        })
      }
    }
  })

  function handleClose() {
    onClose?.()
    deletePreview()
    setPreview(undefined)
  }

  async function handleConfirm() {
    if (!distributor || !preview) return

    await applyMutation.mutateAsync({ distributorId: distributor.distributorId, id: preview.id })
    setShouldRefetch(true)
    navigate('/pedidos-abertos')
    notifications.show({
      color: 'teal',
      message: 'Pedido(s) aberto(s) com sucesso!',
      autoClose: 4000,
    })
  }

  function deletePreview() {
    if (!distributor || !preview) return

    deleteMutation.mutate({ distributorId: distributor.distributorId, id: preview.id })
  }

  function handleChangeDate(date: Date | null) {
    if (!date) {
      return
    }

    deletePreview()

    const { utcDate } = parseDeliveryDate(date.toISOString())

    const selectedSkusWithOffer = filterSelectedSkus(utcDate)

    if (!selectedSkusWithOffer.length) {
      notifications.show({
        color: 'red',
        message: 'Nenhuma oferta foi encontrada para o(s) SKU(s) selecionado(s)',
        autoClose: 4000,
      })
      return
    }

    createMutation.mutate({
      deliveryDate: utcDate.toISOString(),
      products: selectedSkusWithOffer,
    })
  }

  function filterSelectedSkus(utcDate: Date) {
    return selectedSKUs.filter((sku) => {
      return offers.some((offer) => offer.SKU === sku && offer.deliveryDates.some((deliveryDate) => {
        const { utcDate: deliveryDateUtc } = parseDeliveryDate(new Date(deliveryDate).toISOString())
        return deliveryDateUtc.toISOString() === utcDate.toISOString()}))
    })
  }

  useEffect(() => {
    if (!deliveryDateQueryParam || !selectedSKUs || !opened) return

    const { utcDate } = parseDeliveryDate(deliveryDateQueryParam)

    const selectedSkusWithOffer = filterSelectedSkus(utcDate)

    if (!selectedSkusWithOffer.length) {
      notifications.show({
        color: 'red',
        message: 'Nenhuma oferta foi encontrada para o(s) SKU(s) selecionado(s)',
        autoClose: 4000,
      })
      return
    }

    createMutation.mutate({
      deliveryDate: utcDate.toISOString(),
      products: selectedSkusWithOffer,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDateQueryParam, selectedSKUs, opened])

  const isPurchaseFulfilled = useMemo(() => {
    if (!preview?.status) return false

    return SOLVER_SUCCESS_STATUSES.includes(preview.status)
  }, [preview?.status])

  const statusMessage = useMemo(() => {
    if (!preview?.status) return ''

    return SOLVER_STATUS_MESSAGES[preview.status]
  }, [preview?.status])

  const shouldShowError = preview && !isPurchaseFulfilled

  const emptyDemandError =
    !preview && createMutation.error instanceof AxiosError && createMutation.error.response?.status === 400

  const shouldShowEmptyMessage = !preview?.products?.length && isPurchaseFulfilled

  const shouldShowResult = !!preview?.products?.length && isPurchaseFulfilled

  const shouldShowNotOptimalAlert = statusMessage && shouldShowResult && preview?.status !== SolverStatus.OPTIMAL

  const differingMessage = generateDifferingMessage(preview?.products)

  const totalOrderPrice = sumTotalOrderPrice(preview?.products)

  return (
    <Modal size="xl" title={<Text fw={500}>Criar pedido com AI</Text>} opened={opened} onClose={handleClose}>
      <Stack mih={150} pos="relative" gap={0}>
        <Box mb="sm">
          <DatePickerInput
            defaultValue={deliveryDateQueryParam ? new Date(deliveryDateQueryParam) : null}
            label="Data de entrega"
            placeholder="Selecione uma data de entrega"
            locale="pt-br"
            valueFormat="DD/MMM"
            onChange={handleChangeDate}
          />
        </Box>
        {shouldShowError && (
          <Alert variant="light" color="red" title="Erro" icon={<IconXboxX />}>
            {statusMessage}
          </Alert>
        )}
        {emptyDemandError && (
          <Alert title="Atenção" color="red" variant="light" opacity={0.6} icon={<IconXboxX />}>
            Nenhum item selecionado tem demanda definida.
          </Alert>
        )}
        {shouldShowEmptyMessage && (
          <Text opacity={0.6} size="sm" mb="xs">
            São gerados pedidos apenas para itens com demanda definida.
          </Text>
        )}
        {shouldShowResult && (
          <>
            {shouldShowNotOptimalAlert && (
              <Alert mb="lg" variant="light" color="yellow" title="Atenção" icon={<IconXboxX />}>
                {statusMessage}
              </Alert>
            )}
            {!!differingMessage && (
              <Alert
                mb="lg"
                variant="light"
                color="yellow"
                title="Atenção"
                icon={<IconXboxX />}
                style={{
                  whiteSpaceCollapse: 'preserve-breaks',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {differingMessage}
              </Alert>
            )}
            <Accordion
              variant="contained"
              multiple={true}
              classNames={{
                content: styles['generate-order__accordion__content'],
              }}
            >
              {preview.products.map((product) => {
                const productOffersQty = sumProductOffersQuantity(product.offers)
                const rest = productOffersQty - product.demand
                const formattedRest = formatSign(rest)

                return (
                  <Accordion.Item key={product.name} value={product.name}>
                    <Accordion.Control>
                      <Flex gap={8}>
                        <Text fw={500}>{product.name}</Text>
                        <Text c={rest < 0 ? 'red' : 'main'}>{rest ? `${formattedRest}` : ''}</Text>
                      </Flex>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Group gap={6}>
                        <Text fw={600} component="span">{`Demanda:`}</Text>
                        <Text component="span">
                          {parseInfiniteStock(product.demand, { suffix: product.offers[0].unit })}
                        </Text>
                        {rest !== 0 && (
                          <>
                            |
                            <Text component="span" fw={500} c={rest < 0 ? 'red' : 'main'}>
                              {formattedRest} no pedido
                            </Text>
                          </>
                        )}
                      </Group>
                      <GenerateOrderWithAITable
                        updateQtyProps={updateDesiredQty}
                        greatPurchaseId={preview.id}
                        items={product.offers}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              })}
            </Accordion>
            <Text mt="xs" ta="end" fw={600}>
              Total do pedido: R$ {formatPrice(totalOrderPrice)}
            </Text>
          </>
        )}
        <LoadingOverlay
          visible={createMutation.isPending}
          loaderProps={{
            children: (
              <Stack
                align="center"
                gap={0}
                style={{
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
              >
                <Text opacity={0.5}>Carregando seu pedido</Text>
                <Loader type="dots" />
              </Stack>
            ),
          }}
        />
      </Stack>
      <Flex mt="lg" gap="sm" align="center" justify="flex-end">
        <Button onClick={handleClose} variant="outline" color="gray" c="black">
          Cancelar
        </Button>
        <Button
          loading={applyMutation.isPending}
          disabled={applyMutation.isPending || createMutation.isPending || !isPurchaseFulfilled}
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </Flex>
    </Modal>
  )
}
