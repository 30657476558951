import { Menu, Box, Text } from '@mantine/core'
import { IconLogout, IconSwitch } from '@tabler/icons-react'
import UserAvatar from './UserAvatar'
import type { UserMenuProps } from 'src/types'
import styles from './UserMenu.module.css'
import { useDistributor } from 'src/providers/Distributor'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { useMemo } from 'react'

type Organization = {
  id: string
  name: string
}

export default function UserMenu(props: UserMenuProps) {
  const { picture, initials, email, logout } = props
  const { distributor } = useDistributor()
  const { getClaim, login, getOrganization } = useKindeAuth()

  const organizations = useMemo(() => {
    const orgs = getClaim('organizations', 'id_token')?.value as Organization[]

    if (!orgs) {
      return []
    }

    return orgs
  }, [getClaim])

  return (
    <Menu position="left-start">
      <Menu.Target>
        <UserAvatar picture={picture} initials={initials} />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <Box className={styles.userInfo}>
            <UserAvatar picture={picture} initials={initials} notClickable />
            <Box>
              <Text className={styles.userInfoEmail}>{email}</Text>
              <Text className={styles.userInfoTeam}>{distributor?.information.name ?? ' '}</Text>
            </Box>
          </Box>
        </Menu.Label>
        <Menu.Divider />
        {/* <Menu.Item leftSection={<IconSettings size={16} />}>
          <Link to="/settings">
            <Text className={styles.menuItemText}>Configurações</Text>
          </Link>
        </Menu.Item> */}
        <Menu withArrow position="right-start">
          <Menu.Target>
            <Menu.Item closeMenuOnClick={false} leftSection={<IconSwitch size={16} />}>
              <Text className={styles.menuItemText}>Trocar organização</Text>
            </Menu.Item>
          </Menu.Target>
          <Menu.Dropdown>
            {organizations.map((item) => {
              const isCurrent = getOrganization().orgCode === item.id

              return (
                <Menu.Item disabled={isCurrent} key={item.id} onClick={() => login({ org_code: item.id })}>
                  <Text>
                    {item.name}
                    {isCurrent && ' (Atual)'}
                  </Text>
                </Menu.Item>
              )
            })}
          </Menu.Dropdown>
        </Menu>
        <Menu.Item leftSection={<IconLogout size={16} />} onClick={logout}>
          <Text className={styles.menuItemText}>Sair</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
