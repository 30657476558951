import { cloneElement, ReactElement, ReactNode, useRef, useState, useEffect } from 'react'
import { type PopoverProps, Popover } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

interface Props extends PopoverProps {
  dropdown: ReactNode
}

function ChildrenWrapper(props: any) {
  useEffect(() => props.setHasMounted(true), [])

  return <>{props.children}</>
}

export default function TableCellPopover({ position = 'bottom', dropdown, children }: Props) {
  const [opened, { close, open: baseOpen }] = useDisclosure(false)
  const ref = useRef<HTMLElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const [hasMounted, setHasMounted] = useState(false)
  const Children = cloneElement(children as ReactElement, { onMouseEnter: baseOpen, onMouseLeave: close, ref })

  useEffect(() => {
    if (hasMounted) {
      const isTruncated = !!ref.current?.querySelector('[data-is-truncated="true"]')
      setIsTruncated(isTruncated)
    }
  }, [hasMounted])

  if (!isTruncated) {
    return (
      <>
        <ChildrenWrapper setHasMounted={setHasMounted}>{Children}</ChildrenWrapper>
      </>
    )
  }

  return (
    <Popover position={position} withArrow shadow="md" opened={opened}>
      <Popover.Target>{Children}</Popover.Target>
      <Popover.Dropdown style={{ pointerEvents: 'none' }}>{dropdown}</Popover.Dropdown>
    </Popover>
  )
}
