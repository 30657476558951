import { api } from 'src/services/api'
import httpErrorHandler from 'src/utils/httpErrorHandler'

type GenerateOrderWithAIPreviewContext = {
  distributorId: string
  id: string
}

export default async function deleteOrderWithAIPreview(context: GenerateOrderWithAIPreviewContext) {
  try {
    await api.delete(`/solver/${context.id}`, {
      params: {
        distributorId: context.distributorId,
      },
    })
  } catch (error) {
    httpErrorHandler(error)
  }
}
