import { Distributor, TCreateUpdateOrDeleteSKU } from "src/types";
import db from "src/configs/firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import dataConverter from "../utils/dataConverter";

export async function createSKU({ distributorId, ...data }: TCreateUpdateOrDeleteSKU) {
  const distributorRef = doc(db, 'distributors', distributorId).withConverter(dataConverter<Distributor>())

  await updateDoc(distributorRef, {
    skus: arrayUnion(data)
  })
}