import { Box } from '@mantine/core'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import NotificationsMenu from './NotificationsMenu'
import UserMenu from 'src/components/UserMenu'
import getInitialsFromNames from 'src/utils/getInitialsFromNames'
import styles from './Dock.module.css'
import { useLogout } from 'src/hooks/useLogout'

export default function Dock() {
  const { user } = useKindeAuth()
  const { logout } = useLogout()
  const picture = user?.picture ?? ''
  const email = user?.email ?? ''
  const given_name = user?.given_name ?? ''
  const family_name = user?.family_name ?? ''
  const initials = getInitialsFromNames(given_name, family_name)
  const userMenuProps = { picture, initials, email, logout }

  return (
    <Box className={styles.dock}>
      <NotificationsMenu />
      <UserMenu {...userMenuProps} />
    </Box>
  )
}
