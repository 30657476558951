export default function Illustration({ ...props }) {
  return (
    <svg width="276" height="264" viewBox="0 0 276 264" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_f_5_990)">
        <ellipse cx="132.607" cy="217.338" rx="110.607" ry="21.3375" fill="#1F88F8" fillOpacity="0.35" />
      </g>
      <path
        d="M136.701 243.402C200.049 243.402 251.402 192.049 251.402 128.701C251.402 65.3534 200.049 14 136.701 14C73.3534 14 22 65.3534 22 128.701C22 192.049 73.3534 243.402 136.701 243.402Z"
        fill="url(#paint0_linear_5_990)"
      />
      <path d="M225.432 60.3657H79.7427V132.262H225.432V60.3657Z" fill="url(#paint1_linear_5_990)" />
      <path
        d="M225.433 60.3657H240.056C249.589 60.3657 258.731 64.1527 265.472 70.8935C272.213 77.6343 276 86.7768 276 96.3098C276 105.843 272.213 114.985 265.472 121.726C258.731 128.467 249.589 132.254 240.056 132.254H225.433V60.3657Z"
        fill="url(#paint2_linear_5_990)"
      />
      <path
        d="M234.891 70.9417H243.256C249.69 70.9417 255.861 73.4975 260.41 78.047C264.96 82.5964 267.515 88.7668 267.515 95.2007V97.4276C267.515 103.862 264.96 110.032 260.41 114.581C255.861 119.131 249.69 121.687 243.256 121.687H234.891V70.9417Z"
        fill="url(#paint3_linear_5_990)"
      />
      <path d="M225.432 72.4319H91.978V132.262H225.432V72.4319Z" fill="url(#paint4_linear_5_990)" />
      <rect x="44.9341" y="144.231" width="54.1073" height="69.2953" fill="url(#paint5_linear_5_990)" />
      <rect x="111.382" y="144.231" width="54.1073" height="69.2953" fill="url(#paint6_linear_5_990)" />
      <rect x="177.83" y="144.231" width="35.1223" height="69.2953" fill="url(#paint7_linear_5_990)" />
      <path d="M225.433 60.3657H212.994V132.262H225.433V60.3657Z" fill="url(#paint8_linear_5_990)" />
      <path
        d="M111.382 131.891L99.0415 131.891L99.0415 225.867L111.382 225.867L111.382 131.891Z"
        fill="url(#paint9_linear_5_990)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.34 131.891L45.4629 131.891L45.4629 225.867H37.34C34.7187 225.867 32.5938 223.742 32.5938 221.121L32.5938 136.637C32.5938 134.016 34.7187 131.891 37.34 131.891ZM171.185 144.231V131.891H45.4631V144.231H171.185ZM171.185 225.867V213.527H45.4631V225.867H171.185Z"
        fill="url(#paint10_linear_5_990)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.829 131.891H170.236C167.614 131.891 165.489 134.016 165.489 136.637L165.489 225.867H177.829V225.867H212.952L212.952 225.867H220.546C223.167 225.867 225.292 223.742 225.292 221.121L225.292 131.891L212.952 131.891H177.829ZM212.952 144.231H177.83L177.83 213.527H212.952V144.231Z"
        fill="url(#paint11_linear_5_990)"
      />
      <path
        d="M89.5059 132.415V132.55V136.826V167.978H123.502C124.026 167.978 124.451 167.553 124.451 167.029V136.826V133.364C124.451 132.84 124.026 132.415 123.501 132.415H89.5059Z"
        fill="url(#paint12_linear_5_990)"
      />
      <path
        d="M87.4493 132.415C86.925 132.415 86.5 132.84 86.5 133.364V167.029C86.5 167.553 86.925 167.978 87.4493 167.978H121.445V132.415H87.4493Z"
        fill="url(#paint13_linear_5_990)"
      />
      <path
        d="M115.617 135.819H92.3355C91.287 135.819 90.437 136.669 90.437 137.717V162.387C90.437 163.436 91.287 164.286 92.3355 164.286H115.617C116.666 164.286 117.516 163.436 117.516 162.387V137.717C117.516 136.669 116.666 135.819 115.617 135.819Z"
        fill="url(#paint14_linear_5_990)"
      />
      <g filter="url(#filter1_d_5_990)">
        <path
          d="M25 108.347L38.1414 140.667C38.3209 141.11 38.586 141.514 38.9217 141.854C39.2574 142.194 39.6569 142.465 40.0974 142.651C40.5379 142.836 41.0108 142.933 41.4888 142.936C41.9668 142.938 42.4406 142.847 42.8831 142.666L72.3327 130.701C73.2282 130.337 73.9424 129.632 74.3187 128.741C74.6949 127.851 74.7024 126.847 74.3394 125.951L59.149 88.5929C58.7846 87.6996 58.0806 86.9874 57.1916 86.6127C56.3025 86.238 55.3011 86.2315 54.4072 86.5946L29.9365 96.5438L25 108.347Z"
          fill="url(#paint15_linear_5_990)"
        />
      </g>
      <g filter="url(#filter2_d_5_990)">
        <path
          d="M233.132 89.3622L219.99 121.682C219.811 122.125 219.546 122.529 219.21 122.869C218.874 123.209 218.475 123.48 218.034 123.666C217.594 123.851 217.121 123.948 216.643 123.951C216.165 123.953 215.691 123.861 215.249 123.681L185.799 111.716C184.904 111.352 184.189 110.647 183.813 109.756C183.437 108.866 183.429 107.862 183.792 106.966L198.983 69.6078C199.347 68.7145 200.051 68.0022 200.94 67.6276C201.829 67.2529 202.831 67.2464 203.725 67.6095L228.195 77.5587L233.132 89.3622Z"
          fill="url(#paint16_linear_5_990)"
        />
      </g>
      <path d="M25 108.347L33.3573 104.952L29.945 96.5437L25 108.347Z" fill="url(#paint17_linear_5_990)" />
      <path d="M233.132 89.3621L224.775 85.9667L228.187 77.5586L233.132 89.3621Z" fill="url(#paint18_linear_5_990)" />
      <path
        d="M107.541 151.246C108.619 150.489 109.427 149.407 109.848 148.16C110.27 146.912 110.283 145.562 109.886 144.306C109.489 143.05 108.702 141.954 107.639 141.175C106.577 140.397 105.294 139.978 103.976 139.978C102.659 139.978 101.376 140.397 100.314 141.175C99.2513 141.954 98.4643 143.05 98.067 144.306C97.6697 145.562 97.6828 146.912 98.1044 148.16C98.526 149.407 99.3342 150.489 100.412 151.246L97.7529 160.399H110.192L107.541 151.246Z"
        fill="url(#paint19_linear_5_990)"
      />
      <path
        d="M184.154 55.1923C184.154 56.5242 183.954 57.8485 183.562 59.1211C182.925 61.1617 181.8 63.0161 180.285 64.5233C179.84 64.9737 179.362 65.3898 178.854 65.768C178.52 66.0282 178.169 66.2658 177.804 66.4793C177.44 66.7026 177.064 66.9061 176.678 67.089C176.102 67.3731 175.505 67.611 174.891 67.8002C173.618 68.193 172.294 68.3928 170.962 68.3929H168.507V132.025H99.9209V53.8121C99.9321 50.6828 101.18 47.6848 103.393 45.4721C105.606 43.2593 108.604 42.0113 111.733 42.0001H170.928C172.577 41.9938 174.212 42.3012 175.746 42.9061C176.172 43.0711 176.587 43.2605 176.991 43.4734C177.194 43.575 177.389 43.6851 177.584 43.8036C177.744 43.8915 177.899 43.9876 178.049 44.0915C178.85 44.5975 179.591 45.1911 180.259 45.8612C181.157 46.7609 181.921 47.7845 182.529 48.901C182.939 49.6534 183.274 50.4448 183.528 51.2634L183.706 51.8985C183.815 52.3227 183.903 52.7523 183.968 53.1855C184.082 53.8486 184.144 54.5195 184.154 55.1923Z"
        fill="url(#paint20_linear_5_990)"
      />
      <path
        d="M184.155 55.1922C184.154 56.5241 183.955 57.8484 183.562 59.121C183.302 59.9376 182.968 60.7284 182.563 61.4834C181.949 62.5983 181.183 63.6216 180.285 64.5232C179.84 64.9736 179.362 65.3897 178.854 65.7679C178.515 66.022 178.168 66.2506 177.804 66.4792C177.44 66.7025 177.064 66.906 176.678 67.0889C176.101 67.3694 175.503 67.6072 174.891 67.8001C173.619 68.1929 172.294 68.3927 170.962 68.3928H168.507V53.812C168.512 51.4803 169.205 49.202 170.499 47.2621C171.793 45.3223 173.63 43.807 175.78 42.906C176.204 43.0754 176.627 43.2616 177.025 43.4733C177.228 43.5749 177.423 43.685 177.618 43.8035C177.778 43.8914 177.933 43.9875 178.083 44.0914C178.884 44.5974 179.625 45.191 180.293 45.8611C181.191 46.7608 181.955 47.7844 182.563 48.9009C182.97 49.655 183.304 50.446 183.562 51.2633L183.74 51.8984C183.85 52.3224 183.938 52.752 184.002 53.1854C184.105 53.8494 184.156 54.5203 184.155 55.1922Z"
        fill="url(#paint21_linear_5_990)"
      />
      <path
        d="M154.815 108.858H111.504C110.779 108.858 110.191 109.444 110.191 110.167C110.191 110.889 110.779 111.475 111.504 111.475H154.815C155.539 111.475 156.127 110.889 156.127 110.167C156.127 109.444 155.539 108.858 154.815 108.858Z"
        fill="#A5C4F1"
      />
      <path
        d="M154.815 95.4377H111.504C110.779 95.4377 110.191 96.0235 110.191 96.746C110.191 97.4685 110.779 98.0542 111.504 98.0542H154.815C155.539 98.0542 156.127 97.4685 156.127 96.746C156.127 96.0235 155.539 95.4377 154.815 95.4377Z"
        fill="#A5C4F1"
      />
      <path
        d="M154.814 82.0083H126.923C126.198 82.0083 125.61 82.594 125.61 83.3165C125.61 84.039 126.198 84.6247 126.923 84.6247H154.814C155.539 84.6247 156.127 84.039 156.127 83.3165C156.127 82.594 155.539 82.0083 154.814 82.0083Z"
        fill="#A5C4F1"
      />
      <path
        d="M136.517 68.5791H111.199C110.643 68.5791 110.191 69.0302 110.191 69.5867V70.1879C110.191 70.7444 110.643 71.1955 111.199 71.1955H136.517C137.073 71.1955 137.524 70.7444 137.524 70.1879V69.5867C137.524 69.0302 137.073 68.5791 136.517 68.5791Z"
        fill="#A5C4F1"
      />
      <path
        d="M120.818 82.0083H111.504C110.779 82.0083 110.191 82.594 110.191 83.3165C110.191 84.039 110.779 84.6247 111.504 84.6247H120.818C121.543 84.6247 122.13 84.039 122.13 83.3165C122.13 82.594 121.543 82.0083 120.818 82.0083Z"
        fill="#A5C4F1"
      />
      <path d="M45.4087 144.231H49.6803V213.527H45.4087V144.231Z" fill="url(#paint22_linear_5_990)" />
      <path d="M111.382 167.962H115.179V213.526H111.382V167.962Z" fill="url(#paint23_linear_5_990)" />
      <path d="M177.83 144.231H182.102V213.527H177.83V144.231Z" fill="url(#paint24_linear_5_990)" />
      <g filter="url(#filter3_f_5_990)">
        <ellipse cx="61.5786" cy="85.7574" rx="36.5786" ry="11.7574" fill="#FF4C77" fillOpacity="0.3" />
      </g>
      <path
        d="M27.2779 68.6242C27.9834 73.8909 30.0114 78.8932 33.1727 83.1643C36.334 87.4354 40.5256 90.8362 45.3565 93.0495C50.1874 95.2628 55.5002 96.2164 60.7992 95.8214C66.0983 95.4264 71.211 93.6956 75.6604 90.7906L87.7827 94.9116C88.1484 95.0322 88.541 95.0454 88.914 94.9499C89.287 94.8543 89.6248 94.654 89.8875 94.3725C90.1502 94.091 90.3268 93.7402 90.3964 93.3615C90.4661 92.9828 90.4258 92.5921 90.2804 92.2356L85.4189 80.6395C88.0045 76.3322 89.5206 71.4682 89.8405 66.4547C90.1604 61.4411 89.2746 56.4239 87.2574 51.823C85.2402 47.222 82.1502 43.1712 78.2461 40.0096C74.3419 36.848 69.7373 34.6676 64.8175 33.6509C59.8977 32.6341 54.806 32.8107 49.9684 34.1658C45.1309 35.5208 40.6884 38.015 37.0126 41.4395C33.3369 44.8639 30.5349 49.119 28.8413 53.8487C27.1476 58.5783 26.6116 63.6449 27.2779 68.6242Z"
        fill="url(#paint25_linear_5_990)"
      />
      <path
        d="M57.6285 80.545C61.0439 80.545 63.4767 78.2057 63.4767 74.9307C63.4767 71.6557 61.0439 69.3164 57.6285 69.3164C54.2599 69.3164 51.7803 71.6557 51.7803 74.9307C51.7803 78.2057 54.2599 80.545 57.6285 80.545ZM62.3071 68.3807C62.3071 65.2929 64.0849 64.17 66.5178 63.0471C69.746 61.55 72.1321 58.5089 72.1321 54.7661C72.1321 48.8711 66.3306 44.7539 58.4706 44.7539C50.0492 44.7539 44.1074 49.4793 43.5928 56.0761L54.3535 58.0411C54.6342 55.4678 55.991 53.6432 57.9092 53.6432C59.3596 53.6432 60.4356 54.6257 60.4356 55.9825C60.4356 57.5732 58.8449 58.5557 57.5817 59.3043C54.2131 61.5032 52.9499 63.9361 52.9499 68.3807H62.3071Z"
        fill="white"
      />
      <path
        d="M214.886 46.0964C214.886 48.7419 212.742 50.8865 210.096 50.8865C207.451 50.8865 205.306 48.7419 205.306 46.0964C205.306 43.451 207.451 41.3064 210.096 41.3064C212.742 41.3064 214.886 43.451 214.886 46.0964Z"
        stroke="url(#paint26_linear_5_990)"
        strokeWidth="2.61276"
      />
      <circle cx="182.096" cy="237.096" r="4.79006" stroke="url(#paint27_linear_5_990)" strokeWidth="2.61276" />
      <path
        d="M21.0311 175.236C22.3551 175.024 23.4121 176.325 22.9337 177.577L19.7047 186.033C19.2263 187.285 17.5713 187.55 16.7257 186.51L11.0177 179.486C10.1721 178.445 10.7701 176.879 12.0941 176.667L21.0311 175.236Z"
        fill="url(#paint28_linear_5_990)"
      />
      <path
        d="M241.978 168.006C241.647 166.707 242.846 165.536 244.137 165.899L252.851 168.348C254.141 168.71 254.555 170.335 253.596 171.271L247.119 177.593C246.159 178.529 244.546 178.076 244.214 176.777L241.978 168.006Z"
        fill="url(#paint29_linear_5_990)"
      />
      <path
        d="M125.87 8.41711C126.385 6.28414 129.419 6.28414 129.934 8.41711L131.667 15.5959C131.851 16.3582 132.446 16.9533 133.208 17.1373L140.387 18.8702C142.52 19.3851 142.52 22.419 140.387 22.9339L133.208 24.6668C132.446 24.8508 131.851 25.446 131.667 26.2082L129.934 33.387C129.419 35.52 126.385 35.52 125.87 33.387L124.137 26.2082C123.953 25.446 123.358 24.8508 122.596 24.6668L115.417 22.9339C113.284 22.419 113.284 19.3851 115.417 18.8702L122.596 17.1373C123.358 16.9533 123.953 16.3582 124.137 15.5959L125.87 8.41711Z"
        fill="url(#paint30_linear_5_990)"
      />
      <path
        d="M72.8702 230.417C73.3851 228.284 76.419 228.284 76.9339 230.417L78.6668 237.596C78.8508 238.358 79.446 238.953 80.2082 239.137L87.387 240.87C89.52 241.385 89.52 244.419 87.387 244.934L80.2082 246.667C79.446 246.851 78.8508 247.446 78.6668 248.208L76.9339 255.387C76.419 257.52 73.3851 257.52 72.8702 255.387L71.1373 248.208C70.9533 247.446 70.3582 246.851 69.5959 246.667L62.4171 244.934C60.2841 244.419 60.2841 241.385 62.4171 240.87L69.5959 239.137C70.3582 238.953 70.9533 238.358 71.1373 237.596L72.8702 230.417Z"
        fill="url(#paint31_linear_5_990)"
      />
      <defs>
        <filter
          id="filter0_f_5_990"
          x="0.227005"
          y="174.227"
          width="264.76"
          height="86.221"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_5_990" />
        </filter>
        <filter
          id="filter1_d_5_990"
          x="22.1522"
          y="86.3269"
          width="68.5913"
          height="76.5433"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6.64476" dy="10.4418" />
          <feGaussianBlur stdDeviation="4.74626" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.254902 0 0 0 0 0.443137 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_990" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_990" result="shape" />
        </filter>
        <filter
          id="filter2_d_5_990"
          x="180.678"
          y="67.3418"
          width="68.5913"
          height="76.5433"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6.64476" dy="10.4418" />
          <feGaussianBlur stdDeviation="4.74626" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.254902 0 0 0 0 0.443137 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_990" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_990" result="shape" />
        </filter>
        <filter
          id="filter3_f_5_990"
          x="3.22701"
          y="52.227"
          width="116.703"
          height="67.0608"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_5_990" />
        </filter>
        <linearGradient
          id="paint0_linear_5_990"
          x1="135.817"
          y1="-23.3495"
          x2="138.167"
          y2="379.463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAE0FF" stopOpacity="0" />
          <stop offset="1" stopColor="#BCD8FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_990"
          x1="152.588"
          y1="60.3657"
          x2="152.588"
          y2="132.262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCCFF" />
          <stop offset="1" stopColor="#70A8FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_990"
          x1="250.716"
          y1="60.3657"
          x2="250.716"
          y2="132.254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5_990"
          x1="272.28"
          y1="123.348"
          x2="240.005"
          y2="80.6314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5_990"
          x1="158.705"
          y1="72.4319"
          x2="158.705"
          y2="132.262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A64BE" />
          <stop offset="1" stopColor="#6095E7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5_990"
          x1="113.755"
          y1="239.156"
          x2="47.3072"
          y2="176.506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5_990"
          x1="168.337"
          y1="219.697"
          x2="101.889"
          y2="192.168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5_990"
          x1="227.191"
          y1="221.121"
          x2="165.49"
          y2="162.742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5_990"
          x1="219.213"
          y1="60.3657"
          x2="219.213"
          y2="132.262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCCFF" />
          <stop offset="1" stopColor="#70A8FF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5_990"
          x1="83.8535"
          y1="160.843"
          x2="75.3102"
          y2="243.903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCCFF" />
          <stop offset="1" stopColor="#70A8FF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_5_990"
          x1="30.2206"
          y1="148.503"
          x2="165.964"
          y2="211.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCCFF" />
          <stop offset="1" stopColor="#70A8FF" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_5_990"
          x1="272.28"
          y1="190.27"
          x2="187.322"
          y2="193.118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_5_990"
          x1="106.978"
          y1="132.415"
          x2="106.978"
          y2="167.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_5_990"
          x1="103.972"
          y1="132.415"
          x2="103.972"
          y2="167.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCCFF" />
          <stop offset="1" stopColor="#70A8FF" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_5_990"
          x1="103.976"
          y1="135.819"
          x2="103.976"
          y2="164.286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_5_990"
          x1="69.2227"
          y1="111.521"
          x2="35.8226"
          y2="108.186"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6DDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_5_990"
          x1="188.909"
          y1="92.5354"
          x2="222.309"
          y2="89.2006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6DDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_5_990"
          x1="29.1787"
          y1="96.5437"
          x2="29.1787"
          y2="108.347"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_5_990"
          x1="228.953"
          y1="77.5586"
          x2="228.953"
          y2="89.3621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_5_990"
          x1="100.94"
          y1="154.673"
          x2="113.28"
          y2="155.147"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A64BE" />
          <stop offset="1" stopColor="#4B80D1" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_5_990"
          x1="142.038"
          y1="42"
          x2="142.038"
          y2="132.025"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_5_990"
          x1="178.304"
          y1="64.9687"
          x2="159.794"
          y2="50.7299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_5_990"
          x1="15.5073"
          y1="210.204"
          x2="105.362"
          y2="200.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09375" stopColor="#2A64BE" />
          <stop offset="1" stopColor="#2A64BE" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_5_990"
          x1="84.8028"
          y1="211.342"
          x2="163.859"
          y2="199.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09375" stopColor="#2A64BE" />
          <stop offset="1" stopColor="#2A64BE" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_5_990"
          x1="147.929"
          y1="210.204"
          x2="221.57"
          y2="216.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09375" stopColor="#265FB8" />
          <stop offset="1" stopColor="#84B4FF" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_5_990"
          x1="27.3033"
          y1="37.2325"
          x2="89.9793"
          y2="96.6361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF698D" />
          <stop offset="1" stopColor="#FF3868" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_5_990"
          x1="202.603"
          y1="42.2862"
          x2="221.654"
          y2="58.7974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94BFFF" />
          <stop offset="1" stopColor="#4C94FE" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_5_990"
          x1="174.603"
          y1="233.286"
          x2="193.654"
          y2="249.797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94BFFF" />
          <stop offset="1" stopColor="#4C94FE" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_5_990"
          x1="24.0101"
          y1="174.759"
          x2="10.4922"
          y2="185.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_5_990"
          x1="241.233"
          y1="165.083"
          x2="253.399"
          y2="177.548"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_5_990"
          x1="127.902"
          y1="0"
          x2="127.902"
          y2="41.8041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_5_990"
          x1="74.9021"
          y1="222"
          x2="74.9021"
          y2="263.804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
