import { api } from 'src/services/api'
import { Notification } from 'src/types'

export default async function getNotifications() {
  const limit = import.meta.env.VITE_NOTIFICATION_QUERY_LIMIT ?? 5
  const response = await api.get<{ notifications: Notification[] }>('/notifications', {
    params: { limit },
  })

  return response.data.notifications
}
