import { INFINITE_STOCK } from 'src/constants/products'

type Options = {
  suffix?: string
  preffix?: string
}

export function isInfiniteStock(volume: number) {
  return volume >= INFINITE_STOCK
}

export function parseInfiniteStock(volume: number, options?: Options) {
  if (isInfiniteStock(volume)) {
    return '∞'
  }

  if (!options) {
    options = {
      preffix: '',
      suffix: '',
    }
  }

  if (!options.preffix) {
    options.preffix = ''
  }

  if (!options.suffix) {
    options.suffix = ''
  }

  return options.preffix + volume + options.suffix
}
