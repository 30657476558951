import { Box, Image } from '@mantine/core'
import { BalloonProps } from '../Chat'
import BalloonText from './Text'
import styles from '../Chat.module.css'
import { getStatusIcon } from '../statuses'
import { Link } from 'react-router-dom'

type BalloonImageProps = Pick<
  BalloonProps,
  'mediaUrl' | 'hour' | 'message' | 'className' | 'status' | 'resendFailedMessage'
>

export default function BalloonImage({
  className,
  hour,
  message,
  status,
  resendFailedMessage,
  mediaUrl,
}: BalloonImageProps) {
  const StatusIcon = getStatusIcon(status)
  return (
    <Box className={className}>
      <Link to={mediaUrl ?? ''} target="_blank" rel="noopener noreferrer">
        <Image radius="md" className={styles.message_balloon_image} src={mediaUrl} />
      </Link>
      <BalloonText hideInfo={!!message} hour={hour} message={message} />
      <Box className={styles.message_balloon_data_info}>
        <Box component="span" className={styles.message_balloon_time}>
          {hour}
        </Box>
        {!!status && !!StatusIcon && <StatusIcon resendFailedMessage={resendFailedMessage} />}
      </Box>
    </Box>
  )
}
