import { useState } from 'react'
import { Center, Box, Title, Text, TextInput, Button } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { Link } from 'react-router-dom'
import { useErrorBoundary } from 'react-error-boundary'
import styles from './CreateTeam.module.css'

export default function CreateTeam() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showBoundary } = useErrorBoundary()
  const form = useForm({
    initialValues: { orgName: '' },
    validate: {
      orgName: (value) => (value.length < 3 ? 'Nome deve ter pelo menos 3 caracteres' : null),
    },
  })
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      // await createTeam()
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    } finally {
      setIsSubmitting(false)
    }
  }
  const isSmall = useMediaQuery('(max-width: 900px)')
  const btnSize = isSmall ? 'md' : 'lg'
  return (
    <Center>
      <Box>
        <Title className={styles.registerTitle}>Criar nova conta</Title>
        <Text className={styles.registerText}>Escolha um nome para a sua organização</Text>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Nome da organização"
            placeholder="Acme Ltda."
            {...form.getInputProps('orgName')}
            disabled={isSubmitting}
            classNames={{ input: styles.input, label: styles.label, error: styles.error }}
          />
          <Button
            loading={isSubmitting}
            type="submit"
            variant="filled"
            fullWidth
            size={btnSize}
            className={styles.btnSubmit}
          >
            Continuar
          </Button>
        </form>
        <Link to={isSubmitting ? '' : '..'} style={{ display: 'grid', width: '100%' }}>
          <Button disabled={isSubmitting} variant="light" size={btnSize} className={styles.btnBack}>
            Voltar
          </Button>
        </Link>
      </Box>
    </Center>
  )
}
