import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { removeDuplicatedValuesFromArray } from 'src/utils/removeDuplicatedValuesFromArray'

export function useCategories() {
  const { distributor } = useDistributor()

  const filteredCategories = useMemo(() => {
    const skus = distributor?.skus ?? []

    return removeDuplicatedValuesFromArray(skus.map((sku) => String(sku['Categoria'])) ?? [])
  }, [distributor?.skus])

  return filteredCategories
}
