import { Card, Stack, Title } from '@mantine/core'
import { PricePerUnitChart } from './Charts/PricePerUnit'
import { Volume } from './Charts/Volume'
import { ExpensesChart } from './Charts/Expenses'

export function MarketReports() {
  return (
    <Card
      py={{
        base: 'md',
        md: 'xl',
      }}
      px={25}
      shadow="md"
      radius="md"
    >
      <Title order={3} mb="xl">
        Relatórios
      </Title>

      <Stack gap="xl">
        <PricePerUnitChart />
        <Volume />
        <ExpensesChart />
      </Stack>
    </Card>
  )
}
