import { doc, onSnapshot } from 'firebase/firestore'
import db from 'src/configs/firebase'
import converter from 'src/requests/firebase/utils/dataConverter'
import type { Distributor } from 'src/types'

type GetOrCreateDistributorParams = {
  distributorId: string
  userId: string
  userName: string
}

type GetOrCreateDistributorCallback = (value: Distributor) => void

export default function getOrCreateDistributor(
  { distributorId }: GetOrCreateDistributorParams,
  callback: GetOrCreateDistributorCallback,
) {
  const distributorRef = doc(db, 'distributors', distributorId).withConverter(converter<Distributor>())

  const unsub = onSnapshot(distributorRef, (distributorDoc) => {
    if (!distributorDoc.exists()) {
      // const information = { logo: '', name: '' }
      // try {
      //   information.name = localStorage.getItem('orgName') || ''
      //   localStorage.removeItem('orgName')
      // } catch (error) {
      //   // does nothing, just prevents error when localStorage is disabled (incognito mode)
      // }
      // const distributorToCreate = {
      //   distributorId,
      //   fixedPrices: [],
      //   information,
      //   members: { [userId]: { name: userName, role: 'Admin' } },
      //   skus: [],
      //   producers: [],
      // }

      // await setDoc(distributorRef, distributorToCreate)
      return
    }

    callback(distributorDoc.data())
  })

  return unsub
}
