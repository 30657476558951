import { useMemo, useState } from 'react'
import { Card, Title, Button, Space, Center, Text, Flex, Group, ActionIcon } from '@mantine/core'
import { IconFileDownload, IconRefresh } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { useDistributor } from 'src/providers/Distributor'
import { useReceivedOffers } from 'src/hooks/useReceivedOffers'
import { sanitizeReceivedOffersToCsv } from 'src/utils/prepareDataToCsvExport'
import { ExportToCSVModal } from 'src/components/common/Modals/ExportModal'
import { CustomTable } from './Table'
import { OrderFromBackend, Producer, ReceivedOffer, SKU } from 'src/types'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import { parseInfiniteStock } from 'src/utils/products/volume'
import formatPrice from 'src/utils/formatPrice'
import { enforceUTCDate, formatDate } from 'src/utils/formatDate'
import { sortByDateDesc } from 'src/utils/arrays/sortByObjectField'
import { eachDayOfInterval, isSameDay } from 'date-fns'
import { UTCDate } from '@date-fns/utc'
import { useSuppliers } from 'src/hooks/useSuppliers'

const INITIAL_DATES_STATE = {
  startDate: null,
  endDate: null,
}

type ExportDates = {
  startDate: Date | null
  endDate: Date | null
}

export function ReceivedOffersTable() {
  const [exportDates, setExportDates] = useState<ExportDates>(INITIAL_DATES_STATE)
  const [opened, { open, close }] = useDisclosure()
  const { distributor } = useDistributor()
  const { suppliers } = useSuppliers()
  function handleClose() {
    setExportDates(INITIAL_DATES_STATE)
    close()
  }

  const { offers, isFetching, refetchOffers } = useReceivedOffers()

  const offersWithProducerName: ReceivedOffer[] = useMemo(() => {
    const skus = distributor?.skus ?? []

    const flattenOffers = makeFlattenOffers(offers, suppliers, skus, true)

    return sortByDateDesc(flattenOffers, 'deliveryDate')
  }, [suppliers, distributor?.skus, offers])

  const csvOffers = useMemo(() => {
    const skus = distributor?.skus ?? []

    return makeFlattenOffers(makeOrdersWithExpirationDate(offers), suppliers, skus)
  }, [offers, suppliers, distributor?.skus])

  const csvData = sanitizeReceivedOffersToCsv(csvOffers, exportDates)

  return (
    <Card
      py={{
        base: 'md',
        md: 'xl',
      }}
      px={0}
      shadow="md"
      radius="md"
    >
      <ExportToCSVModal
        title="Exportar ofertas recebidas"
        filenamePrefix="ofertas"
        exportDates={exportDates}
        setExportDates={setExportDates}
        opened={opened}
        onClose={handleClose}
        csvData={csvData}
      />
      <Flex justify="space-between" px={25}>
        <Title order={3}>Ofertas recebidas</Title>
        <Group>
          <ActionIcon
            onClick={refetchOffers}
            loading={isFetching}
            loaderProps={{ type: 'dots' }}
            variant="filled"
            title="Recarregar"
          >
            <IconRefresh size={20} />
          </ActionIcon>
          <Button
            onClick={open}
            size="sm"
            variant="default"
            radius="sm"
            leftSection={<IconFileDownload strokeWidth={1.5} size={18} />}
          >
            Exportar
          </Button>
        </Group>
      </Flex>
      <Space h="md" />
      {!offersWithProducerName?.length && !isFetching ? (
        <Center>
          <Text>Você ainda não tem ofertas recebidas</Text>
        </Center>
      ) : (
        <CustomTable items={offersWithProducerName} />
      )}
    </Card>
  )
}

function makeOrdersWithExpirationDate(orders: OrderFromBackend[]) {
  return orders.flatMap((order, index) => {
    if (!order.validUntil) return [order]

    const days = eachDayOfInterval({
      start: new UTCDate(enforceUTCDate(new Date())),
      end: new UTCDate(order.validUntil),
    })

    const filteredDays = days.filter((day) => {
      const alreadyExists = orders.some((o, idx) => {
        const sameSupplierId = o.supplierId === order.supplierId
        const sameDeliveryDate = isSameDay(day.toISOString(), o.deliveryDate)
        const notSent = !o.sentAt
        const notSameOrder = index !== idx

        return sameSupplierId && sameDeliveryDate && notSent && notSameOrder
      })

      return !alreadyExists
    })

    return filteredDays.map((day) => ({
      ...order,
      deliveryDate: day,
    }))
  })
}

function makeFlattenOffers(
  orders: OrderFromBackend[],
  suppliers: Producer[],
  skus?: SKU[],
  showExpirationDate?: boolean,
) {
  return orders.flatMap((offer) => {
    const supplier = suppliers.find((s) => s.id === offer.supplierId || (s.phone && s.phone === offer.phone))
    const middleman = suppliers.find((s) => s.id === supplier?.middlemanId)
    const date = offer.createdAt ? formatDate(offer.createdAt, 'dd/MM/yyyy') : '-' // FIXME: set it to BRT

    return offer.products.map((product) => ({
      id: offer.id,
      supplier: {
        name: supplier?.name ?? 'Não encontrado',
        phone: formatBrazilianNumber(supplier?.phone || middleman?.phone || '').slice(3),
      },
      createdAt: offer.createdAt,
      date,
      sku: product.sku,
      category: skus?.find((sku) => sku['SKU'] === product.sku)?.['Categoria'],
      volume: parseInfiniteStock(product.volume, {
        suffix: product.unit,
      }),
      deliveryDate: offer.validUntil && showExpirationDate ? offer.validUntil : offer.deliveryDate,
      price: formatPrice(product.price),
      priceExceedsFixedPrice: product.priceExceedsFixedPrice,
      unit: product.unit,
      requester: offer.requester,
    }))
  })
}
