import { api } from 'src/services/api'
import httpErrorHandler from 'src/utils/httpErrorHandler'

type UpdateDraftOrderQtyContext = {
  distributorId: string
  orderId: string
  sku: string
  desiredQty: number
}

export default async function updateDraftOrder({ orderId, ...body }: UpdateDraftOrderQtyContext) {
  try {
    await api.patch(`/draft-orders/${orderId}`, body)
  } catch (error) {
    httpErrorHandler(error)
  }
}
