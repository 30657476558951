import { useState, useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { DistributorProvider } from './DistributorContextValue'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import useFirebaseUser from 'src/hooks/useFirebaseUser'
import { useFirebaseAuth } from 'src/providers/FirebaseAuth'
import getOrCreateDistributor from 'src/requests/firebase/getOrCreateDistributor'
import type { DistributorProps, Distributor } from 'src/types'
import { Unsubscribe } from 'firebase/firestore'

export default function Distributor({ children }: DistributorProps) {
  const [isFetching, setIsFetching] = useState(true)
  const [distributor, setDistributor] = useState<Distributor | null>(null)
  const { showBoundary } = useErrorBoundary()
  const { user: kindeUser, getOrganization } = useKindeAuth()
  const { firebaseUser } = useFirebaseUser()
  const { isAuthenticating } = useFirebaseAuth()
  const contextValue = {
    isFetching,
    setDistributor,
    distributor,
  }

  useEffect(() => {
    let unsub: Unsubscribe | undefined
    const fetchDistributor = () => {
      try {
        const userId = kindeUser?.id ?? ''
        const userName = `${kindeUser?.given_name} ${kindeUser?.family_name}`.trim()

        const userOrganization = getOrganization()

        // Get first user organization
        const distributorId = userOrganization.orgCode

        if (!distributorId) {
          throw new Error('distributorId not found')
        }

        unsub = getOrCreateDistributor(
          {
            distributorId,
            userId,
            userName,
          },
          setDistributor,
        )
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      } finally {
        setIsFetching(false)
      }
    }
    if (!isAuthenticating && kindeUser && firebaseUser) fetchDistributor()

    return () => {
      if (unsub) {
        unsub()
      }
    }
  }, [isAuthenticating, kindeUser, getOrganization, firebaseUser])

  useEffect(() => {
    if (!isAuthenticating && !firebaseUser) setIsFetching(false)
  }, [isAuthenticating, firebaseUser])

  return <DistributorProvider value={contextValue}>{children}</DistributorProvider>
}
