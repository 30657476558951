import { notifications } from '@mantine/notifications'
import { ChangeEvent, KeyboardEvent, useCallback, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import updateOrderProductsVolume from 'src/requests/firebase/draftOrder/updateProductsVolume'

type Options = {
  onSave?(): void
}

type ProductData = {
  skuName: string
  orderId: string
  deliveryDate?: string
}

export function useUpdateVolume(options?: Options) {
  const volumeRef = useRef<HTMLInputElement>(null)
  const [productData, setProductData] = useState<ProductData | null>(null)
  const [updatedVolume, setUpdatedVolume] = useState<number | undefined>()
  const { distributor } = useDistributor()

  const { showBoundary } = useErrorBoundary()

  const saveVolume = useCallback(
    async (data: ProductData, newVolume: number) => {
      try {
        await updateOrderProductsVolume({
          distributorId: distributor!.distributorId,
          newVolume,
          orderId: data.orderId,
          sku: data.skuName,
        })
        setProductData(null)
        setUpdatedVolume(undefined)
        options?.onSave?.()
        notifications.show({
          autoClose: 3000,
          message: 'Volume alterado com sucesso!',
        })
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    },
    [distributor, options, showBoundary],
  )

  const handleClickToUpdateVolume = useCallback(
    (data: ProductData) => () => {
      setProductData(data)
      setTimeout(() => {
        volumeRef.current?.focus()
      }, 100)
    },
    [],
  )

  function handleChangeVolume(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value

    setUpdatedVolume(Number(value))
  }

  const handleEnterVolume = useCallback(
    (event: KeyboardEvent) => {
      const newVolume = updatedVolume
      const enterPressed = event.key === 'Enter'
      if (productData && enterPressed && (newVolume || typeof newVolume === 'number')) {
        saveVolume(productData, newVolume)
      } else if (enterPressed) {
        setProductData(null)
      }
    },
    [saveVolume, updatedVolume, productData],
  )

  const handleBlurVolume = useCallback(() => {
    const newVolume = updatedVolume
    if (productData && (newVolume || typeof newVolume === 'number')) {
      saveVolume(productData, newVolume)
    } else {
      setProductData(null)
    }
  }, [saveVolume, updatedVolume, productData])

  return {
    handleClickToUpdateVolume,
    volumeRef,
    isUpdatingVolumeId: productData ? productData?.orderId + (productData?.deliveryDate ?? '') : '',
    handleChangeVolume,
    updatedVolume,
    handleEnterVolume,
    handleBlurVolume,
  }
}
