import { api } from 'src/services/api'
import { OrderFromBackend, Pagination, PaginationReturn } from 'src/types'

interface GetSentOrdersContext extends Pagination {
  distributorId: string
  startDate?: Date
  endDate?: Date
}

export async function getSentOrders(ctx: GetSentOrdersContext) {
  const response = await api.get<PaginationReturn<OrderFromBackend[]>>('/orders/sent', {
    params: ctx,
  })

  return response.data
}
