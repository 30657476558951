import { ActionIcon, Flex, NumberInput, Table, Text } from '@mantine/core'
import { IconPencil } from '@tabler/icons-react'
import { GreatPurchaseOffer } from 'src/types'
import formatPrice from 'src/utils/formatPrice'
import { parseInfiniteStock } from 'src/utils/products/volume'
import { generateUpdateQtyId, useUpdateDesiredQty } from './hooks/useUpdateDesiredQty'
import { formatPercentage } from 'src/utils/intl/formatPercentage'
import { sumProductOffersPrice } from '../../Modals/utils'

export type GenerateOrderWithAITableBodyProps = {
  items: GreatPurchaseOffer[]
  greatPurchaseId: string
  updateQtyProps: ReturnType<typeof useUpdateDesiredQty>
}

export function GenerateOrderWithAITableBody({
  greatPurchaseId,
  items,
  updateQtyProps,
}: GenerateOrderWithAITableBodyProps) {
  const productOffersPrice = sumProductOffersPrice(items)

  return (
    <Table.Tbody>
      {items.map((offer) => {
        const desiredQtyId = generateUpdateQtyId({
          orderId: offer.orderId,
          skuName: offer.sku,
        })

        return (
          <Table.Tr key={offer.orderId}>
            <Table.Td pl={0}>{offer.producer.name}</Table.Td>
            <Table.Td ta="center">{formatPercentage(offer.producer.rejectionRate)}</Table.Td>
            <Table.Td ta="center">{formatPrice(offer.price)}</Table.Td>
            <Table.Td ta="center">
              {parseInfiniteStock(offer.volume, {
                suffix: offer.unit,
              })}
            </Table.Td>

            <Table.Td pr={0} ta="center">
              {updateQtyProps.isUpdatingQtyId === desiredQtyId ? (
                <NumberInput
                  w={80}
                  ref={updateQtyProps.desiredQtyRef}
                  variant="filled"
                  defaultValue={offer.desiredQty}
                  onChange={updateQtyProps.handleChangeQty}
                  onBlur={updateQtyProps.handleBlurQty}
                  onKeyDown={updateQtyProps.handleEnterQty}
                  max={offer.volume}
                  min={0}
                  clampBehavior="strict"
                />
              ) : (
                <Flex wrap="nowrap" align="center" justify="center" w="100%">
                  <Text
                    size="sm"
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {parseInfiniteStock(offer.desiredQty, {
                      suffix: offer.unit,
                    })}
                  </Text>
                  <ActionIcon
                    ml={4}
                    variant="subtle"
                    onClick={updateQtyProps.handleClickToUpdateQty({
                      orderId: offer.orderId,
                      skuName: offer.sku,
                      greatPurchaseId: greatPurchaseId,
                    })}
                  >
                    <IconPencil />
                  </ActionIcon>
                </Flex>
              )}
            </Table.Td>
          </Table.Tr>
        )
      })}
      <Table.Tr>
        <Table.Td fw={500} pl={0} pr={12} ta="end" colSpan={5}>
          Total do produto: R$ {formatPrice(productOffersPrice)}
        </Table.Td>
      </Table.Tr>
    </Table.Tbody>
  )
}
