import { Button, Checkbox, Menu, Table } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react'
import { useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { Link, useNavigate } from 'react-router-dom'
import DeleteModal from 'src/components/common/Modals/DeleteModal'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import { IsCheckboxChecked, OnIndividualCheckboxChange } from 'src/hooks/useBulkCheckbox'
import { useSuppliers } from 'src/hooks/useSuppliers'
import { deleteSupplierAndFixedPrices } from 'src/requests/suppliers'
import { SUPPLIER_TYPE, type Producer as BaseProducer } from 'src/types'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import { formatDateDistanceFromNow } from 'src/utils/formatDate'

type Producer = BaseProducer & {
  id?: string
}

export type ProducerTableBodyProps = {
  items: Producer[]
  currentSendingSupplierId: string
  successSentProducersIds: string[]
  sendMessage: (phone: string) => Promise<void>
  isCheckboxChecked: IsCheckboxChecked
  onIndividualCheckboxChange: OnIndividualCheckboxChange
}

export function CustomTableBody(props: ProducerTableBodyProps) {
  const { suppliers } = useSuppliers()
  const { showBoundary } = useErrorBoundary()

  const [isModalOpen, { open: openModal, close: closeModal }] = useDisclosure(false)
  const [producerToDelete, setProducerToDelete] = useState<Producer>()
  const navigate = useNavigate()

  const sendIndividualMessage = (supplierId: string) => async () => {
    await props.sendMessage(supplierId)
  }

  async function handleDeleteProducer() {
    const notification = pendingNotificationFactory({
      pending: {
        title: 'Removendo fornecedor...',
        message: 'Isso não deve demorar muito',
      },
      success: {
        title: 'Fornecedor removido com sucesso!',
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
      },
      error: {
        title: 'Erro ao remover Fornecedor.',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
      },
    })

    try {
      const producer = suppliers.find((p) => p.code === producerToDelete?.code)

      if (!producer) return

      await deleteSupplierAndFixedPrices(producer.id)
      notification.success()
      closeModal()
      setProducerToDelete(undefined)
    } catch (error) {
      notification.error()
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    }
  }

  const onEdit = (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    navigate(`/fornecedores/editar/${id}`)
  }

  const onDelete = (producer: Producer) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (producer) {
      setProducerToDelete(producer)
      openModal()
    }
  }

  function onCloseModal() {
    setProducerToDelete(undefined)
    closeModal()
  }

  return (
    <>
      <DeleteModal
        show={isModalOpen}
        title="Remover fornecedor"
        description="Tem certeza que deseja remover esse fornecedor?"
        onCloseModal={onCloseModal}
        handleDelete={handleDeleteProducer}
      />
      <Table.Tbody>
        {props.items.map((supplier) => {
          const key = supplier?.id ?? supplier.code
          const name = supplier.name
          const phoneToDisplay = formatBrazilianNumber(supplier.phone).slice(3)
          const supplierId = supplier.id

          const rowState = {
            sent: props.successSentProducersIds.some((phone) => phone === supplierId),
            loading: props.currentSendingSupplierId === supplierId,
          }

          const isMiddleman = supplier.type === SUPPLIER_TYPE.MIDDLEMAN

          return (
            <>
              <Table.Tr key={key}>
                <Table.Td>
                  <Checkbox
                    aria-label="Select row"
                    defaultValue={supplier.id}
                    checked={props.isCheckboxChecked(supplier.id)}
                    onChange={props.onIndividualCheckboxChange}
                  />
                </Table.Td>
                <Table.Td>
                  <Button p="0" component={Link} to={`/mensagens/${supplier.phone}`} variant="transparent">
                    {name}
                  </Button>
                </Table.Td>
                <Table.Td>{phoneToDisplay}</Table.Td>
                <Table.Td align="center">
                  {supplier?.lastRequest ? formatDateDistanceFromNow(supplier?.lastRequest?.toDate()) : '*'}
                </Table.Td>
                <Table.Td align="center">
                  {supplier?.lastOffer ? formatDateDistanceFromNow(supplier?.lastOffer?.toDate()) : '*'}
                </Table.Td>
                <Table.Td align="right">
                  <Button
                    onClick={sendIndividualMessage(supplierId)}
                    disabled={rowState.sent}
                    loading={rowState.loading}
                    loaderProps={{ type: 'dots' }}
                  >
                    {rowState.sent ? 'Solicitado!' : 'Solicitar oferta'}
                  </Button>
                </Table.Td>
                <Table.Td align="center" w={0} miw="fit-content">
                  <Menu shadow="md" withArrow position="bottom-end">
                    <Menu.Target>
                      <Button onClick={(e) => e.stopPropagation()} variant="subtle" size="compact-sm">
                        <IconDotsVertical size={20} />
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item onClick={onEdit(supplier.id)} leftSection={<IconPencil size={14} />}>
                        Editar
                      </Menu.Item>
                      <Menu.Item onClick={onDelete(supplier)} color="red" leftSection={<IconTrash size={14} />}>
                        Remover
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
              {isMiddleman && !!supplier.representedSuppliers?.length && (
                <Table.Tr>
                  <Table.Td></Table.Td>
                  <Table.Td colSpan={6} pt={0}>
                    <Table withRowBorders={false} w="95%" ml="auto">
                      <Table.Tbody>
                        {supplier.representedSuppliers?.map((id) => {
                          const supplier = suppliers.find((s) => s.id === id)

                          if (!supplier) return null

                          return (
                            <Table.Tr key={supplier.id}>
                              <Table.Td c="gray.7" fw={500} pl={0} pt={0} pb={12}>
                                {supplier.name}
                              </Table.Td>
                              <Table.Td align="center" w={0} miw="fit-content" pr={0} pt={0} pb={12}>
                                <Menu shadow="md" withArrow position="bottom-end">
                                  <Menu.Target>
                                    <Button onClick={(e) => e.stopPropagation()} variant="subtle" size="compact-sm">
                                      <IconDotsVertical size={20} />
                                    </Button>
                                  </Menu.Target>

                                  <Menu.Dropdown>
                                    <Menu.Item onClick={onEdit(supplier.id)} leftSection={<IconPencil size={14} />}>
                                      Editar
                                    </Menu.Item>
                                    <Menu.Item
                                      onClick={onDelete(supplier)}
                                      color="red"
                                      leftSection={<IconTrash size={14} />}
                                    >
                                      Remover
                                    </Menu.Item>
                                  </Menu.Dropdown>
                                </Menu>
                              </Table.Td>
                            </Table.Tr>
                          )
                        })}
                      </Table.Tbody>
                    </Table>
                  </Table.Td>
                </Table.Tr>
              )}
            </>
          )
        })}
      </Table.Tbody>
    </>
  )
}
