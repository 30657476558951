import { Box, Button, Modal, Text } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useCallback, useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { formatDate } from 'src/utils/formatDate'

type ExportDates = {
  startDate: Date | null
  endDate: Date | null
}

type ExportSentOrdersModalProps = {
  opened: boolean
  onClose?: () => void
  exportDates: ExportDates
  setExportDates: (val: ExportDates) => void
  csvData: (string | number)[][]
  filenamePrefix: string
  title: string
  isLoading?: boolean
}

export function ExportToCSVModal(props: ExportSentOrdersModalProps) {
  const handleClose = useCallback(() => {
    props.onClose?.()
  }, [props])

  const csvNameWithDates = useMemo(() => {
    if (!props.exportDates.startDate || !props.exportDates.endDate) return 'pedidos.csv'

    return `${props.filenamePrefix}-${formatDate(props.exportDates.startDate, 'dd, MMM/yy')}-${formatDate(props.exportDates.endDate, 'dd, MMM/yy')}.csv`
  }, [props.exportDates.endDate, props.exportDates.startDate, props.filenamePrefix])

  return (
    <Modal opened={props.opened} onClose={handleClose} title={<Text fw={500}>{props.title}</Text>}>
      <Box>
        <DatePickerInput
          labelProps={{
            fw: 400,
          }}
          label="Selecione a data de entrega para exportação"
          value={[props.exportDates.startDate, props.exportDates.endDate]}
          onChange={(dates) => {
            const startDate = dates[0]
            const endDate = dates[1]

            props.setExportDates({
              startDate,
              endDate,
            })
          }}
          allowSingleDateInRange
          valueFormat="DD, MMM/YY"
          locale="pt-br"
          clearable
          type="range"
          placeholder="Uma data ou um intervalo de datas"
        />
        <CSVLink filename={csvNameWithDates} data={props.csvData}>
          <Button
            disabled={!props.exportDates.endDate || !props.exportDates.startDate || props.isLoading}
            loading={props.isLoading}
            size="sm"
            radius="sm"
            mt="lg"
          >
            Exportar
          </Button>
        </CSVLink>
      </Box>
    </Modal>
  )
}
