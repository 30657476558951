import { useQuery } from '@tanstack/react-query'
import { useDistributor } from 'src/providers/Distributor'
import { api } from 'src/services/api'
import { OrderFromBackend } from 'src/types'

interface FetchOffersContext {
  distributorId: string
}

async function fetchOffers({ distributorId }: FetchOffersContext) {
  const response = await api.get<OrderFromBackend[]>('/offers', {
    params: {
      distributorId,
    },
  })

  return response.data
}

export function useReceivedOffers() {
  const { distributor } = useDistributor()

  const { data, isFetching, refetch } = useQuery({
    initialData: [],
    queryKey: ['offers', distributor!.distributorId],
    queryFn: () =>
      fetchOffers({
        distributorId: distributor!.distributorId,
      }),
    enabled: !!distributor?.distributorId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    throwOnError: true,
  })

  function refetchOffers() {
    refetch()
  }

  return {
    offers: data,
    isFetching,
    refetchOffers,
  }
}
