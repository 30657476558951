import { api } from 'src/services/api'
import { GreatPurchase } from 'src/types'
import httpErrorHandler from 'src/utils/httpErrorHandler'

type GenerateOrderWithAIPreviewContext = {
  distributorId: string
  id: string
  orderId: string
  sku: string
  desiredQty: number
}

export default async function updateOrderWithAIPreview(context: GenerateOrderWithAIPreviewContext) {
  try {
    const response = await api.put<GreatPurchase>(
      `/solver/${context.id}`,
      { orderId: context.orderId, sku: context.sku, desiredQty: context.desiredQty },
      {
        params: {
          distributorId: context.distributorId,
        },
      },
    )

    return response.data
  } catch (error) {
    httpErrorHandler(error)
  }
}
