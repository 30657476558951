import { useEffect } from 'react'
import { Drawer, AppShellNavbar, Image } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useLocation } from 'react-router-dom'
import NavbarContent from './NavbarContent'
import type { NavbarProps } from 'src/types'
import styles from './Navbar.module.css'

export default function Navbar(props: NavbarProps) {
  const { navbarIsOpen, closeNavbar, useDrawer } = props
  const { pathname } = useLocation()
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isSmallMobile = useMediaQuery('(max-width: 480px)')
  const drawerSize = isSmallMobile ? '65%' : '50%'
  useEffect(() => closeNavbar(), [closeNavbar, pathname])
  if (isMobile)
    return (
      <Drawer
        opened={navbarIsOpen}
        onClose={closeNavbar}
        withCloseButton={false}
        size={drawerSize}
        classNames={{ content: styles.drawerContent }}
      >
        <NavbarContent pathname={pathname} isMobile={isMobile} />
      </Drawer>
    )
  return useDrawer ? (
    <Drawer
      opened={navbarIsOpen}
      onClose={closeNavbar}
      withCloseButton={false}
      classNames={{
        header: styles.drawerHeader,
        content: styles.drawerContent,
      }}
      title={<Image src="/logo.png" fit="contain" height={40} alt="logo" className={styles.logo} />}
    >
      <NavbarContent pathname={pathname} isMobile={!!isMobile} />
    </Drawer>
  ) : (
    <AppShellNavbar hidden={!navbarIsOpen} p="md" className={styles.nav}>
      <NavbarContent pathname={pathname} isMobile={!!isMobile} />
    </AppShellNavbar>
  )
}
