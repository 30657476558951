import { TCreateUpdateProducer } from 'src/types'
import { api } from 'src/services/api'

export async function createSupplierAndFixedPrices({ distributorId, fixedPrices, ...data }: TCreateUpdateProducer) {
  await api.post('/suppliers', {
    distributorId,
    fixedPrices,
    supplier: data,
  })
}
