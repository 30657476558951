import { notifications } from '@mantine/notifications'
import { useCallback, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import updateOrderProductsPrice from 'src/requests/firebase/draftOrder/updateProductsPrice'

type Options = {
  onSave?(): void
}

type ProductData = {
  skuName: string
  orderId: string
  deliveryDate?: string
}

export function useUpdatePrice(options?: Options) {
  const priceRef = useRef<HTMLInputElement>(null)
  const [productData, setProductData] = useState<ProductData | null>()
  const [updatedPrice, setUpdatedPrice] = useState<number | undefined>()
  const { distributor } = useDistributor()

  const { showBoundary } = useErrorBoundary()

  const savePrice = useCallback(
    async (data: ProductData, newPrice: number) => {
      try {
        await updateOrderProductsPrice({
          distributorId: distributor!.distributorId,
          newPrice,
          orderId: data.orderId,
          sku: data.skuName,
        })
        options?.onSave?.()
        setProductData(null)
        setUpdatedPrice(undefined)
        notifications.show({
          autoClose: 3000,
          message: 'Preço alterado com sucesso!',
        })
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    },
    [distributor, options, showBoundary],
  )

  const handleClickToUpdatePrice = useCallback(
    (data: ProductData) => () => {
      setProductData(data)
      setTimeout(() => {
        priceRef.current?.focus()
      }, 100)
    },
    [],
  )

  function handleChangePrice(_: unknown, rawValue: number) {
    setUpdatedPrice(rawValue)
  }

  const handleEnterPrice = useCallback(
    (event: KeyboardEvent) => {
      const newPrice = updatedPrice
      const enterPressed = event.key === 'Enter'
      if (productData && enterPressed && (newPrice || typeof newPrice === 'number')) {
        savePrice(productData, newPrice)
      } else if (enterPressed) {
        setProductData(null)
      }
    },
    [savePrice, updatedPrice, productData],
  )

  const handleBlurPrice = useCallback(() => {
    const newPrice = updatedPrice
    if (productData && (newPrice || typeof newPrice === 'number')) {
      savePrice(productData, newPrice)
    } else {
      setProductData(null)
    }
  }, [savePrice, updatedPrice, productData])

  return {
    handleClickToUpdatePrice,
    isUpdatingPriceId: productData && productData?.orderId + (productData?.deliveryDate ?? ''),
    handleChangePrice,
    updatedPrice,
    handleEnterPrice,
    handleBlurPrice,
    priceRef,
    activeProductDataPrice: productData,
  }
}
