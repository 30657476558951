import { Flex, Pagination, Table } from '@mantine/core'
import { CustomTableBody } from './Body'
import { CustomTableHead } from './Head'
import { useSortProducers } from '../hooks/useSortProducers'
import { OnIndividualCheckboxChange, useBulkCheckbox } from 'src/hooks/useBulkCheckbox'
import { useCallback, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import { Producer } from 'src/types'
import { usePaginateArray } from 'src/hooks/usePagination'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { addDays, parseISO } from 'date-fns'
import requestOffer from 'src/requests/requestOffer'

type CustomTableProps = {
  items: Producer[]
}

export function CustomTable(props: CustomTableProps) {
  const { distributor } = useDistributor()

  const {
    OrderIcon,
    sortedProducers,
    toggleOrderByDate,
    orderByDateStatus,
    LastOfferIcon,
    toggleOrderByLastOffer,
    orderByLastOffer,
  } = useSortProducers(props.items)

  const { showBoundary } = useErrorBoundary()
  const { getQueryParams } = useQueryParams()
  const deliveryDate = getQueryParams('date') ? parseISO(getQueryParams('date')) : addDays(new Date(), 1)
  const selectedSKUs = getQueryParams('skus').split(',').filter(Boolean)

  const [currentSendingSupplierId, setCurrentSendingSupplierId] = useState('')
  const [successSentProducersIds, setSuccessSentProducersIds] = useState<string[]>([])

  const {
    onIndividualCheckboxChange,
    onMultipleCheckboxChange,
    selectedOptions,
    anyOptionSelected,
    allOptionsSelected,
    isCheckboxChecked,
    someOptionsSelected,
  } = useBulkCheckbox(props.items.map((p) => p.id))

  async function sendMessage(supplierId: string) {
    try {
      setCurrentSendingSupplierId(supplierId)
      if (distributor) {
        await requestOffer({
          date: deliveryDate,
          distributorId: distributor.distributorId,
          products: selectedSKUs,
          supplierId,
        })
        setSuccessSentProducersIds((old) => [...old, supplierId])
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    } finally {
      setCurrentSendingSupplierId('')
    }
  }

  const handleMultipleCheckboxChange = useCallback(() => {
    onMultipleCheckboxChange()
    setSuccessSentProducersIds([])
  }, [onMultipleCheckboxChange])

  const handleIndividualCheckboxChange: OnIndividualCheckboxChange = useCallback(
    (e) => {
      onIndividualCheckboxChange(e)
      setSuccessSentProducersIds([])
    },
    [onIndividualCheckboxChange],
  )

  const { activePage, totalPages, onChangePage, paginated } = usePaginateArray(sortedProducers, 15)

  return (
    <>
      <Table.ScrollContainer minWidth={300}>
        <Table withRowBorders={false} verticalSpacing="sm">
          <CustomTableHead
            OrderIcon={OrderIcon}
            LastOfferIcon={LastOfferIcon}
            allOptionsSelected={allOptionsSelected}
            anyOptionSelected={anyOptionSelected}
            selectedOptions={selectedOptions}
            onMultipleCheckboxChange={handleMultipleCheckboxChange}
            someOptionsSelected={someOptionsSelected}
            currentSendingSupplierId={currentSendingSupplierId}
            orderByDateStatus={orderByDateStatus}
            orderByLastOffer={orderByLastOffer}
            toggleOrderByDate={toggleOrderByDate}
            toggleOrderByLastOffer={toggleOrderByLastOffer}
            sendMessage={sendMessage}
            successSentProducersIds={successSentProducersIds}
          />
          <CustomTableBody
            items={paginated}
            isCheckboxChecked={isCheckboxChecked}
            onIndividualCheckboxChange={handleIndividualCheckboxChange}
            currentSendingSupplierId={currentSendingSupplierId}
            sendMessage={sendMessage}
            successSentProducersIds={successSentProducersIds}
          />
        </Table>
      </Table.ScrollContainer>
      <Flex px={25} justify="center" mt="xs">
        <Pagination total={totalPages} value={activePage} onChange={onChangePage} />
      </Flex>
    </>
  )
}
