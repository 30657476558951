import { doc, getDoc, updateDoc, arrayRemove, deleteDoc } from 'firebase/firestore'
import db from 'src/configs/firebase'
import type { Order } from 'src/types'
import dataConverter from '../utils/dataConverter'

type DeleteOrderProductContext = {
  distributorId: string
  orderId: string
  sku: string
}

export default async function deleteOrderProduct({
  distributorId,
  orderId,
  sku
}: DeleteOrderProductContext) {
  const orderRef = doc(db, 'distributors', distributorId, 'orders', orderId).withConverter(dataConverter<Order>())
  const orderDocSnapshot = await getDoc(orderRef)
  if (orderDocSnapshot.exists()) {
    const oldOrder = orderDocSnapshot.data()
    const oldDraftOrder = oldOrder?.draftOrder
    const orderItem = oldDraftOrder.find((item) => item.sku === sku)

    const productItem = oldOrder.products.find((p) => p.sku === sku)
    
    if (orderItem) {
      if (productItem && oldOrder.products.length === 1) {
        await deleteDoc(orderRef)
        return
      }
      await updateDoc(orderRef, { 
        draftOrder: arrayRemove(orderItem),
        products: arrayRemove(productItem)
      })
      return
    }

    if (productItem && oldOrder.products.length === 1) {
      await deleteDoc(orderRef)
      return
    }
    
    if (productItem) {
      await updateDoc(orderRef, { 
        products: arrayRemove(productItem)
      })
    }
  }
}
