import { Flex, Pagination, Table } from '@mantine/core'
import { SupplyBySKUTableHead, SupplyBySKUTableHeadProps } from './Head'
import { SupplyBySKUTableBody, SupplyBySKUTableBodyProps } from './Body'
import { usePaginateArray } from 'src/hooks/usePagination'
import { useScreenSize } from 'src/hooks/useScreenSize'
import { useSortOffers } from '../../hooks/useSortOffers'
import classes from 'src/components/Table/Table.module.css'

type CustomTableProps = Partial<SupplyBySKUTableHeadProps> & SupplyBySKUTableBodyProps

export function SupplyBySKUTable(props: CustomTableProps) {
  const {
    sortedOffers,
    VolumeIcon,
    DemandIcon,
    SkuIcon,
    toggleOrderByVolume,
    toggleOrderByDemand,
    toggleOrderBySku,
    orderByVolume,
    orderByDemand,
    orderBySku,
  } = useSortOffers(props.items)

  const { activePage, totalPages, onChangePage, paginated } = usePaginateArray(sortedOffers, 15)
  const screenSize = useScreenSize()

  return (
    <>
      <Table.ScrollContainer minWidth={300}>
        <Table withRowBorders={false} verticalSpacing="sm" className={classes.table}>
          {screenSize === 'desktop' && (
            <SupplyBySKUTableHead
              allOptionsSelected={props.allOptionsSelected || false}
              someOptionsSelected={props.someOptionsSelected || false}
              onMultipleCheckboxChange={props.onMultipleCheckboxChange || (() => {})}
              volumeIcon={VolumeIcon}
              demandIcon={DemandIcon}
              skuIcon={SkuIcon}
              toggleOrderByVolume={toggleOrderByVolume}
              toggleOrderByDemand={toggleOrderByDemand}
              toggleOrderBySku={toggleOrderBySku}
              orderByVolume={orderByVolume}
              orderByDemand={orderByDemand}
              orderBySku={orderBySku}
            />
          )}
          <SupplyBySKUTableBody
            items={paginated}
            isCheckboxChecked={props.isCheckboxChecked}
            onIndividualCheckboxChange={props.onIndividualCheckboxChange}
          />
        </Table>
      </Table.ScrollContainer>
      <Flex px={25} justify="center" mt="xs">
        <Pagination total={totalPages} value={activePage} onChange={onChangePage} />
      </Flex>
    </>
  )
}
