import { ActionIcon, AppShell, Avatar, Box, Button, Drawer, Flex, Loader, useMantineTheme } from '@mantine/core'
import { useDisclosure, useMediaQuery, useWindowScroll } from '@mantine/hooks'
import Header from '../Header'
import { useParams, useNavigate } from 'react-router-dom'
import { useChannel } from 'src/hooks/useChannel'
import styles from './Chat.module.css'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { IconChevronLeft, IconRefresh } from '@tabler/icons-react'
import { getInitials } from 'src/utils/getInitials'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import { ChatFooter } from './Footer'
import { useEffect, useMemo, useRef, useState } from 'react'
import { MESSAGE_STATUS, MESSAGE_TYPE, SUPPLIER_TYPE } from 'src/types'
import BalloonText from './balloons/Text'
import BalloonImage from './balloons/Image'
import BalloonAudio from './balloons/Audio'
import { getIsFailedMessage } from 'src/utils/getIsFailedMessage'
import sendWhatsappMessage from 'src/requests/sendWhatsappMessage'
import { parseAddWhatsAppPhonePrefix } from 'src/utils/parseBrazilianPhones'
import { formatDate } from 'src/utils/formatDate'
import { useOrders } from 'src/providers/Orders'
import { Offers } from './Offers'
import BalloonFile from './balloons/File'
import { useSuppliers } from 'src/hooks/useSuppliers'
import { BalloonUnsupported } from './balloons/Unsupported'
import BalloonInteractive from './balloons/Interactive'
import OffersIcon from './OffersIcon'

export enum FileExtension {
  'XLSX' = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'PDF' = 'pdf',
  'PNG' = 'png',
  'JPEG' = 'jpeg',
  'JPG' = 'jpg',
}

export type BalloonProps = {
  message?: string | null
  hour: string
  type: MESSAGE_TYPE | string
  mediaUrl?: string | null
  status?: MESSAGE_STATUS | null
  className?: string
  resendFailedMessage?(): void
  isManual?: boolean
  mediaName?: string
  fileExtension?: FileExtension
}

const BalloonTypes: Record<string, React.FC<BalloonProps>> = {
  [MESSAGE_TYPE.AUDIO]: BalloonAudio,
  [MESSAGE_TYPE.IMAGE]: BalloonImage,
  [MESSAGE_TYPE.TEXT_DEFAULT]: BalloonText,
  [MESSAGE_TYPE.TEXT]: BalloonText,
  [MESSAGE_TYPE.APPLICATION]: BalloonFile,
  [MESSAGE_TYPE.INTERACTIVE]: BalloonInteractive,
}

function LeftSideBalloon(props: Omit<BalloonProps, 'className' | 'status'>) {
  const Balloon = BalloonTypes[props.type] ?? BalloonUnsupported
  return <Balloon className={styles.message_balloon_left} {...props} />
}

function RightSideBalloon({ isManual, ...props }: Omit<BalloonProps, 'className'>) {
  const Balloon = BalloonTypes[props.type] ?? BalloonUnsupported
  return <Balloon className={styles.message_balloon_right} data-manual={isManual} {...props} />
}

export default function Chat() {
  const navigate = useNavigate()
  const [navbarIsOpen, { open: openNavbar }] = useDisclosure(false)
  const headerProps = { navbarIsOpen, openNavbar }
  const { phone } = useParams()
  const { channel, isLoadingChannel, loadMoreMessages, isLoadingMoreMessages, isLastPage, sentMessage } =
    useChannel(phone)
  const firstScrollRef = useRef(true)
  const { orders, isFetching, setShouldRefetch: setShouldRefetchOrders } = useOrders()
  const [footerHeight, setFooterHeight] = useState(70)
  const theme = useMantineTheme()
  const { suppliers } = useSuppliers()

  const supplier = suppliers.find((s) => s.phone === phone)

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const isMobileOffers = useMediaQuery('(max-width: 575px)')
  const [scroll] = useWindowScroll()

  const [drawerOffers, { open: openDrawerOffers, close: closeDrawerOffers }] = useDisclosure(false)

  useEffect(() => {
    if (firstScrollRef.current && !isLoadingChannel) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'auto',
      })
      firstScrollRef.current = false
    }
  }, [channel.chatlogs])

  useEffect(() => {
    if (scroll.y === 0 && !isLoadingMoreMessages && !isLoadingChannel && !isLastPage) {
      const previousScrollPosition = window.scrollY
      loadMoreMessages()
      const newScrollPosition = previousScrollPosition + (window.innerHeight - previousScrollPosition)
      window.scrollTo({ top: newScrollPosition, behavior: 'auto' })
    }
  }, [scroll.y])

  const modifiedChannels = useMemo(() => {
    return {
      ...channel,
      chatlogs: channel.chatlogs
        .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
        .map((log) => ({
          ...log,
          formattedHour: formatDate(log.createdAt.toDate(), 'dd/MM/yy - HH:mm'),
        })),
    }
  }, [channel])

  const resendFailedMessage = (messageSid: string, isManual?: boolean) => () => {
    const message = modifiedChannels.chatlogs.find((c) => c.id === messageSid)

    if (!supplier || !message || !getIsFailedMessage(message.status)) return

    sendWhatsappMessage({
      userNumber: parseAddWhatsAppPhonePrefix(supplier.phone),
      resend: true,
      messageSid,
      ...(isManual && { isManual }),
    })
  }

  const supplierOffers = useMemo(() => {
    const isMiddleman = supplier?.type === SUPPLIER_TYPE.MIDDLEMAN

    const representedSuppliers = supplier?.representedSuppliers ?? []

    return (
      orders?.filter((order) => {
        const isNotSent = !order.sentAt

        const isNotEmpty = !!order.products.length

        const oldIsSamePhone = !isMiddleman && order.phone === supplier?.phone

        const isSameSupplierId = !isMiddleman && order.supplierId === supplier?.id

        const isMiddlemanRepresentedSupplier = isMiddleman && representedSuppliers.includes(order.supplierId)

        return isNotSent && isNotEmpty && (oldIsSamePhone || isSameSupplierId || isMiddlemanRepresentedSupplier)
      }) ?? []
    )
  }, [orders, supplier])

  function refreshAll() {
    setShouldRefetchOrders(true)
  }

  return (
    <AppShell layout="alt" navbar={{ width: 250, breakpoint: 'sm' }} header={{ height: 60 }}>
      <Header {...headerProps} />
      <AppShell.Navbar className={styles.navbar}>
        <Box className={styles.producer_info}>
          <Box className={styles.icon_and_avatar}>
            <div className={styles.icon_chevron}>
              <Button onClick={() => navigate(-1)} variant="transparent" w="fit-content">
                <IconChevronLeft size={20} />
              </Button>
            </div>
            <Avatar color="cyan" radius="999" alt={channel.supplier?.name} className={styles.producer_info_avatar}>
              {getInitials(channel.supplier?.name ?? '')}
            </Avatar>
          </Box>
          <Box className={styles.producer_info_text}>
            <Box className={styles.producer_info_text_title} component="span">
              {channel.supplier?.name ?? ''}
            </Box>
            <Box component="span" className={styles.producer_info_phone}>
              {formatBrazilianNumber(supplier?.phone ?? '')}
            </Box>
          </Box>
        </Box>
        <Box className={styles.draft_offer_wrap}>
          {isMobile ? (
            <>
              <Button size="sm" variant="default" onClick={openDrawerOffers}>
                {isMobileOffers ? <OffersIcon /> : 'Ofertas'}
              </Button>
              <Drawer
                opened={drawerOffers}
                onClose={closeDrawerOffers}
                title={
                  <Box className={styles.draft_offer_title}>
                    <Box component="span">
                      <ActionIcon
                        onClick={refreshAll}
                        loading={isFetching}
                        loaderProps={{ type: 'dots' }}
                        variant="filled"
                        title="Recarregar"
                      >
                        <IconRefresh size={20} />
                      </ActionIcon>
                    </Box>
                    <Box component="span" ml={2}>
                      Ofertas:
                    </Box>
                  </Box>
                }
              >
                <Offers listOffers={supplierOffers} refreshOffers={setShouldRefetchOrders} />
              </Drawer>
            </>
          ) : (
            <>
              <Box className={styles.draft_offer_title}>
                <Box component="span">
                  <ActionIcon
                    onClick={refreshAll}
                    loading={isFetching}
                    loaderProps={{ type: 'dots' }}
                    variant="filled"
                    title="Recarregar"
                  >
                    <IconRefresh size={20} />
                  </ActionIcon>
                </Box>
                <Box component="span" ml={2}>
                  Ofertas:
                </Box>
              </Box>
              <Offers listOffers={supplierOffers} refreshOffers={setShouldRefetchOrders} />
            </>
          )}
        </Box>
      </AppShell.Navbar>

      <ErrorBoundary>
        <AppShell.Main bg="#f8f9fa" pt={isMobile ? '10rem' : 'revert-layer'} pb={footerHeight}>
          {isLoadingMoreMessages && (
            <Flex className={styles.loading_container} pt={20} justify="center" align="center">
              <Loader color="cyan" />
            </Flex>
          )}
          <Box className={styles.messages_container}>
            {modifiedChannels.chatlogs.map((log) =>
              log.fromOrg ? (
                <RightSideBalloon
                  key={log.id}
                  message={log.content}
                  hour={log.formattedHour}
                  type={log.type}
                  mediaUrl={log.mediaUrl}
                  status={log.status}
                  resendFailedMessage={resendFailedMessage(log.id, log.isManual)}
                  isManual={log.isManual}
                />
              ) : (
                <LeftSideBalloon
                  key={log.id}
                  message={log.content}
                  hour={log.formattedHour}
                  type={log.type}
                  mediaUrl={log.mediaUrl}
                  mediaName={log.mediaName}
                  fileExtension={log.fileExtension}
                />
              ),
            )}
          </Box>
        </AppShell.Main>
        <AppShell.Footer p="md">
          <ChatFooter onChangeFooterHeight={setFooterHeight} onSendMessage={sentMessage} />
        </AppShell.Footer>
      </ErrorBoundary>
    </AppShell>
  )
}
