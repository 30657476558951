import { doc, getDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore'
import db from 'src/configs/firebase'
import type { Order } from 'src/types'

type UpdateOrderProductsVolumeContext = {
  distributorId: string
  orderId: string
  newVolume: number
  sku: string
}

export default async function updateOrderProductsVolume({
  distributorId,
  orderId,
  newVolume,
  sku,
}: UpdateOrderProductsVolumeContext) {
  const orderRef = doc(db, 'distributors', distributorId, 'orders', orderId)
  const orderDocSnapshot = await getDoc(orderRef)
  if (orderDocSnapshot.exists()) {
    const oldOrder = orderDocSnapshot.data() as Order
    const oldDraftOrder = oldOrder?.draftOrder
    const orderItem = oldDraftOrder.find((item) => item.sku === sku)

    const productItem = oldOrder.products.find((p) => p.sku === sku)

    if (orderItem) {
      await updateDoc(orderRef, {
        draftOrder: arrayRemove(orderItem),
        products: arrayRemove(productItem),
      })
      await updateDoc(orderRef, {
        draftOrder: arrayUnion({
          ...orderItem,
          volume: newVolume,
        }),
        products: arrayUnion({
          ...productItem,
          volume: newVolume,
        }),
      })
      return
    }

    await updateDoc(orderRef, {
      products: arrayRemove(productItem),
    })
    await updateDoc(orderRef, {
      products: arrayUnion({
        ...productItem,
        volume: newVolume,
      }),
    })
  }
}
