import { useMemo } from 'react'
import { useOrderBy } from 'src/hooks/useOrderBy'
import { UseSKUsWithOrderDataReturn } from 'src/hooks/useSKUs/types'

type Items = UseSKUsWithOrderDataReturn & {
  lastBuyoutPrice?: number
  minPrice: number
  volume: number
}

export function useSortOffers(items: Items[]) {
  const [orderByVolume, toggleOrderByVolume, VolumeIcon] = useOrderBy()
  const [orderByDemand, toggleOrderByDemand, DemandIcon] = useOrderBy()
  const [orderBySku, toggleOrderBySku, SkuIcon] = useOrderBy()

  const sortedOffers = useMemo(() => {
    if (!orderByVolume && !orderByDemand && !orderBySku) {
      return items
    }

    const sorted = [...items]

    sorted.sort((a, b) => {
      let comparison = 0

      if (orderByVolume) {
        const volumeComparison = orderByVolume === 'asc' ? a.volume - b.volume : b.volume - a.volume
        if (volumeComparison !== 0) {
          comparison = volumeComparison
        }
      }

      if (comparison === 0 && orderByDemand) {
        comparison = orderByDemand === 'asc' ? a.demand - b.demand : b.demand - a.demand
      }

      if (comparison === 0 && orderBySku) {
        comparison = orderBySku === 'asc' ? a.SKU.localeCompare(b.SKU) : b.SKU.localeCompare(a.SKU)
      }

      return comparison
    })

    return sorted
  }, [items, orderByVolume, orderByDemand, orderBySku])

  return {
    toggleOrderByVolume,
    toggleOrderByDemand,
    toggleOrderBySku,
    VolumeIcon,
    DemandIcon,
    SkuIcon,
    orderByVolume,
    orderByDemand,
    orderBySku,
    sortedOffers,
  }
}
