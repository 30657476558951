import { useCallback, useMemo, useState } from 'react'

export type OnIndividualCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => void

export type OnMultipleCheckboxChange = () => void

export type IsCheckboxChecked = (val: string) => boolean

type UseBulkCheckboxReturn = {
  onMultipleCheckboxChange: OnMultipleCheckboxChange
  onIndividualCheckboxChange: OnIndividualCheckboxChange
  isCheckboxChecked: IsCheckboxChecked
  allOptionsSelected: boolean
  someOptionsSelected: boolean
  anyOptionSelected: boolean
  selectedOptions: string[]
}

export function useBulkCheckbox(alOptions: string[]): UseBulkCheckboxReturn {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const anyOptionSelected = useMemo(() => !!selectedOptions.length, [selectedOptions.length])
  const allOptionsSelected = useMemo(() => {
    return !!alOptions.length && selectedOptions.length === alOptions.length
  }, [alOptions.length, selectedOptions.length])

  const someOptionsSelected = useMemo(() => {
    return anyOptionSelected && selectedOptions.length !== alOptions.length
  }, [alOptions.length, anyOptionSelected, selectedOptions.length])

  const selectOption = useCallback((phone: string) => {
    setSelectedOptions((old) => [...old, phone])
  }, [])

  const removeOption = useCallback((phone: string) => {
    setSelectedOptions((old) => old.filter((p) => p !== phone))
  }, [])

  const onIndividualCheckboxChange: OnIndividualCheckboxChange = useCallback(
    (event) => {
      const { checked, defaultValue } = event.target

      if (checked) {
        return selectOption(defaultValue)
      }

      return removeOption(defaultValue)
    },
    [removeOption, selectOption],
  )

  const onMultipleCheckboxChange = useCallback(() => {
    // Check if it is the appropriate behavior, if not, remove the if statement below
    if (someOptionsSelected) {
      return setSelectedOptions([])
    }

    if (!allOptionsSelected) {
      return setSelectedOptions(alOptions)
    }

    return setSelectedOptions([])
  }, [alOptions, allOptionsSelected, someOptionsSelected])

  const isCheckboxChecked = useCallback(
    (val: string) => {
      return selectedOptions.includes(val)
    },
    [selectedOptions],
  )

  return {
    onIndividualCheckboxChange,
    onMultipleCheckboxChange,
    isCheckboxChecked,
    allOptionsSelected,
    someOptionsSelected,
    anyOptionSelected,
    selectedOptions,
  }
}
