import { Box, Flex } from '@mantine/core'
import styles from '../Chat.module.css'
import { BalloonProps } from '../Chat'
import { getStatusIcon } from '../statuses'

type BalloonTextProps = Pick<BalloonProps, 'message' | 'className' | 'status' | 'hour' | 'resendFailedMessage'> & {
  hideInfo?: boolean
}

export default function BalloonText({
  message,
  className,
  status,
  hour,
  resendFailedMessage,
  hideInfo,
  ...rest
}: BalloonTextProps) {
  const StatusIcon = getStatusIcon(status)

  return (
    !!message && (
      <Flex direction="column" className={className} {...rest}>
        <Box component="span" className={styles.message_balloon_text}>
          {message}
        </Box>
        {!hideInfo && (
          <Box className={styles.message_balloon_data_info}>
            <Box component="span" className={styles.message_balloon_time}>
              {hour}
            </Box>
            {!!status && !!StatusIcon && <StatusIcon resendFailedMessage={resendFailedMessage} />}
          </Box>
        )}
      </Flex>
    )
  )
}
