import { api } from 'src/services/api'

type UpdateSkuContext = {
  offerId: string
  sku: string
  deliveryDate: string
}

export default async function updateOfferSku({ offerId, sku, ...body }: UpdateSkuContext) {
  await api.patch(`/offers/${offerId}/sku/${sku}`, body)
}