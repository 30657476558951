import { api } from 'src/services/api'
import { GreatPurchase } from 'src/types'
import httpErrorHandler from 'src/utils/httpErrorHandler'

type Context = {
  products: string[]
  deliveryDate: string
}

export default async function generateOrderWithAIPreview(context: Context) {
  try {
    const response = await api.post<GreatPurchase>(`/solver`, {
      products: context.products,
      deliveryDate: context.deliveryDate,
    })
  
    return response.data
  } catch (error) {
    httpErrorHandler(error)
  }
}
