import { ActionIcon, Checkbox, Flex, Table, Box } from '@mantine/core'
import { CSSProperties } from 'react'
import { OnMultipleCheckboxChange } from 'src/hooks/useBulkCheckbox'
import { OrderBy, ToggleOrder, TOrderIcon } from 'src/hooks/useOrderBy'

export type SupplyBySKUTableHeadProps = {
  allOptionsSelected: boolean
  someOptionsSelected: boolean
  onMultipleCheckboxChange: OnMultipleCheckboxChange
  orderByVolume: OrderBy
  orderByDemand: OrderBy
  orderBySku: OrderBy
  toggleOrderByVolume: ToggleOrder
  toggleOrderByDemand: ToggleOrder
  toggleOrderBySku: ToggleOrder
  volumeIcon: TOrderIcon
  demandIcon: TOrderIcon
  skuIcon: TOrderIcon
}

export function SupplyBySKUTableHead(props: SupplyBySKUTableHeadProps) {
  const center: CSSProperties = { textAlign: 'center' }

  return (
    <Table.Thead>
      <Table.Tr>
        <Table.Th>
          <Checkbox
            aria-label="Select row"
            checked={props.allOptionsSelected}
            indeterminate={props.someOptionsSelected}
            onChange={props.onMultipleCheckboxChange}
          />
        </Table.Th>
        <Table.Th>Código</Table.Th>
        <Table.Th>
          <Flex align="center" gap={8}>
            SKU
            <ActionIcon size="xs" variant={props.orderBySku ? 'light' : 'transparent'} onClick={props.toggleOrderBySku}>
              <props.skuIcon />
            </ActionIcon>
          </Flex>
        </Table.Th>
        <Table.Th>Categoria</Table.Th>
        <Table.Th style={center}>
          <Box>Preço</Box>
          <Box>da última compra</Box>
        </Table.Th>
        <Table.Th style={center}>
          <Box>Preço</Box>
          <Box>mín. ofertado</Box>
        </Table.Th>
        <Table.Th style={center}>
          <Flex align="center" gap={8} justify="center">
            Volume
            <ActionIcon
              size="xs"
              variant={props.orderByVolume ? 'light' : 'transparent'}
              onClick={props.toggleOrderByVolume}
            >
              <props.volumeIcon />
            </ActionIcon>
          </Flex>
        </Table.Th>
        <Table.Th style={center}>
          <Flex align="center" gap={8} justify="center">
            Demanda
            <ActionIcon
              size="xs"
              variant={props.orderByDemand ? 'light' : 'transparent'}
              onClick={props.toggleOrderByDemand}
            >
              <props.demandIcon />
            </ActionIcon>
          </Flex>
        </Table.Th>
        <Table.Th style={center}>Medida</Table.Th>
        <Table.Th style={center}>Ações</Table.Th>
      </Table.Tr>
    </Table.Thead>
  )
}
