export function groupBy<T extends Record<string, any>>(items: T[], key: keyof T): Record<string, T[]> {
  return items.reduce<Record<string, T[]>>((acc, item) => {
    const groupByKey = item[key]
    if (!acc[groupByKey]) {
      acc[groupByKey] = []
    }

    acc[groupByKey].push(item)
    return acc
  }, {})
}
