import { compareAsc, compareDesc } from 'date-fns'
import { useMemo } from 'react'
import { useOrderBy } from 'src/hooks/useOrderBy'
import { Producer } from 'src/types'

export function useSortProducers(producers: Producer[]) {
  const [orderByDateStatus, toggleOrderByDate, OrderIcon] = useOrderBy()
  const [orderByLastOffer, toggleOrderByLastOffer, LastOfferIcon] = useOrderBy()

  const sortedProducers = useMemo(() => {
    const sorted = [...producers]
    sorted.sort((a, b) => {
      let dateA, dateB

      if (orderByDateStatus) {
        dateA = a.lastRequest?.toDate()
        dateB = b.lastRequest?.toDate()
      } else if (orderByLastOffer) {
        dateA = a.lastOffer?.toDate()
        dateB = b.lastOffer?.toDate()
      }

      if (dateA && dateB) {
        if (orderByDateStatus === 'asc' || orderByLastOffer === 'asc') {
          return compareAsc(dateA, dateB)
        } else if (orderByDateStatus === 'desc' || orderByLastOffer === 'desc') {
          return compareDesc(dateA, dateB)
        }
      } else {
        if (!dateA) return 1
        if (!dateB) return -1
      }

      return a.name.localeCompare(b.name)
    })

    return sorted
  }, [producers, orderByDateStatus, orderByLastOffer])

  return {
    toggleOrderByDate,
    OrderIcon,
    sortedProducers,
    orderByDateStatus,
    toggleOrderByLastOffer,
    LastOfferIcon,
    orderByLastOffer,
  }
}