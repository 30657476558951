import { Route, Routes } from 'react-router-dom'
import { CreateOffer, ReceivedOffersTable } from 'src/components/Offers'
import PrivatePage from 'src/components/PrivatePage'

export default function OffersPagesRoute() {
  return (
    <PrivatePage>
      <Routes>
        <Route index element={<ReceivedOffersTable />} />
        <Route path="/criar" element={<CreateOffer />} />
      </Routes>
    </PrivatePage>
  )
}
