import { Box, Button, Modal, Text } from '@mantine/core'
import styles from './Modals.module.css'

type DeleteModalProps = {
  onCloseModal?(): void
  handleDelete?: (obj: Record<string, any>) => Promise<void> | void
  show?: boolean
  title: string
  description?: string
}

export default function DeleteModal({
  handleDelete,
  onCloseModal = () => {},
  show = false,
  title,
  description,
}: DeleteModalProps) {
  return (
    <Modal opened={show} onClose={onCloseModal} title={<Text fw={600}>{title}</Text>} centered>
      <Box opacity={0.6}>{description}</Box>
      <Box className={styles.modal__buttons}>
        <Button fw={500} variant="outline" color="gray" c="black" onClick={onCloseModal}>
          Cancelar
        </Button>
        <Button color="red" onClick={handleDelete}>
          Remover
        </Button>
      </Box>
    </Modal>
  )
}
