import { api } from 'src/services/api'

type sendOrderToBackendContext = {
  distributorId: string
  orderId: string
}

export default async function sendCancelOrderToBackend({ orderId, ...body }: sendOrderToBackendContext) {
  await api.post(`/cancel-order/${orderId}`, body)
}
