import { api } from 'src/services/api'
import { Order } from 'src/types'

type CreateOfferContext = {
  distributorId: string
  supplierId: string
  products: Order['products']
  deliveryDate: Date
  changedBy?: 'SUPPLIER' | 'OPERATOR'
}

export default async function createOffer(body: CreateOfferContext) {
  await api.post(`/offers`, body)
}
