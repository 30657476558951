import { ActionIcon, Box, Button, Fieldset, Flex, Grid, InputBase, NumberInput, Select, Stack, Text, TextInput } from '@mantine/core'
import { IconCirclePlus, IconTrash } from '@tabler/icons-react'
import { Control, Controller, UseFieldArrayReturn, UseFormRegister } from 'react-hook-form'
import IntlCurrencyInput from 'react-intl-currency-input'
import { AddOfferFormType } from './OfferForm'
import { AddSkuFormType } from 'src/components/Chat/AddSkuModal'

type ItemsArrayProps = {
  control: Control<AddOfferFormType> | Control<AddSkuFormType>
  fieldArray: UseFieldArrayReturn<AddOfferFormType> | UseFieldArrayReturn<AddSkuFormType>
  stringNameSKUs: string[]
  register: UseFormRegister<AddOfferFormType>
  onChangeSku: ({ index, value }: { index: number; value: string | null }) => void
}

export function ItemsArray({ control, fieldArray, stringNameSKUs, register, onChangeSku }: ItemsArrayProps ) {
  return (
    <Stack>
      {fieldArray.fields.map((item, idx) => {
        return (
          <Box key={item.id}>
            <Grid component={Fieldset} grow p="md" m={0} key={item.id}>
              <Grid.Col>
                <Flex justify="space-between">
                  <Text size="sm" fw={500}>
                    Item {idx + 1}
                  </Text>
                  {fieldArray.fields.length > 1 && (
                    <ActionIcon
                      color="red"
                      variant="light"
                      size="lg"
                      type="button"
                      onClick={() => {
                        fieldArray.remove(idx)
                      }}
                    >
                      <IconTrash />
                    </ActionIcon>
                  )}
                </Flex>
              </Grid.Col>
              <Grid.Col
                span={{
                  base: 12,
                  xs: 6,
                  md: 4,
                  lg: 3,
                }}
              >
                <Controller
                  name={`products.${idx}.sku`}
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState }) => (
                    <Select
                      placeholder="Selecione um"
                      allowDeselect={false}
                      checkIconPosition="right"
                      searchable
                      labelProps={{
                        fw: 400,
                      }}
                      label="SKU"
                      data={stringNameSKUs}
                      error={fieldState.error?.message}
                      onChange={(val) => {
                        onChangeSku({
                          index: idx,
                          value: val,
                        })
                        return onChange(val)
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  base: 12,
                  xs: 6,
                  md: 4,
                  lg: 3,
                }}
              >
                <Controller
                  control={control}
                  name={`products.${idx}.price`}
                  render={({ field, fieldState }) => (
                    <InputBase
                      labelProps={{
                        fw: 400,
                      }}
                      label="Preço"
                      component={IntlCurrencyInput}
                      currency="BRL"
                      defaultValue={field.value}
                      value={field.value}
                      max={Number.MAX_SAFE_INTEGER}
                      onChange={(_, rawValue: number) => {
                        return field.onChange(rawValue)
                      }}
                      error={fieldState.error?.message}
                      config={{
                        locale: 'pt-BR',
                        formats: {
                          number: {
                            BRL: {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  base: 12,
                  xs: 6,
                  md: 4,
                  lg: 3,
                }}
              >
                <Controller
                  name={`products.${idx}.volume`}
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState }) => (
                    <NumberInput
                      labelProps={{
                        fw: 400,
                      }}
                      onFocus={(e) => {
                        e.currentTarget.select()
                      }}
                      allowNegative={false}
                      hideControls
                      placeholder="Quantidade desejada"
                      label="Volume"
                      error={fieldState.error?.message}
                      onChange={(val) => {
                        return onChange(val || 0)
                      }}
                      decimalSeparator=","
                      {...field}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  base: 12,
                  xs: 6,
                  md: 4,
                  lg: 3,
                }}
              >
                <TextInput
                  labelProps={{ fw: 400 }}
                  label="Medida"
                  aria-readonly
                  readOnly
                  {...register(`products.${idx}.unit`)}
                />
              </Grid.Col>
            </Grid>
          </Box>
        )
      })}
      <Flex justify="center">
        <Button
          radius="xl"
          leftSection={<IconCirclePlus size={24} stroke={1.5} />}
          w="fit-content"
          variant="light"
          onClick={() => {
            fieldArray.append(
              {
                price: 0,
                sku: '',
                volume: 0,
                unit: '',
              },
              {
                shouldFocus: false,
              },
            )
          }}
        >
          Adicionar item
        </Button>
      </Flex>
    </Stack>
  )
}
