import { isAxiosError, isPlainObject, isCustomError, isStandardError } from './typeGuards'
import { captureException } from 'src/utils/captureException'

export default function httpErrorHandler(error: unknown) {
  captureException(error)

  if (isAxiosError(error)) {
    const axiosError = error.response?.data
    if (isPlainObject(axiosError)) {
      if (isCustomError(axiosError)) {
        const customError = axiosError.customError.message
        console.log('customError', customError)
        throw new Error(customError)
      }

      throw new Error((axiosError as any).message)
    }
    console.log(error.message)
    throw error
  } else if (isStandardError(error)) {
    const standardError = error.message
    console.log(standardError)
    throw new Error(standardError)
  }
  console.log(error)
  throw new Error('Unexpected error')
}
