import { format, formatDistance } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale'

type Formats =
  | 'dd/MM/yyyy HH:mm:ss'
  | 'dd/MM/yyyy HH:mm'
  | 'dd/MM/yyyy'
  | 'HH:mm'
  | 'dd/MM/yy - HH:mm'
  | 'dd, MMM/yy'
  | 'EEEE'
  | 'dd/MM/yyyy, EEEE'
  | 'dd/MM, EEEE'

export function formatDate(date: Date, formatString: Formats) {
  return format(date, formatString, {
    locale: ptBR,
  })
}

export function formatDateDistanceFromNow(date: Date) {
  return formatDistance(date, new Date(), {
    addSuffix: true,
    locale: ptBR,
  })
}

export function formatUTCDate(date: Date | string, formatString: Formats) {
  return formatInTimeZone(date, 'UTC', formatString, {
    locale: ptBR,
  })
}

export function setToStartOfUTCDay(d: string | Date) {
  const date = new Date(d)

  const utcYear = date.getUTCFullYear()
  const utcMonth = date.getUTCMonth()
  const utcDate = date.getUTCDate()

  const startOfDayUTC = new Date(Date.UTC(utcYear, utcMonth, utcDate, 0, 0, 0))

  return startOfDayUTC
}

export function enforceUTCDate(d: string | Date) {
  const date = new Date(d)

  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  const startOfDayUTC = new Date(Date.UTC(year, month, day, 0, 0, 0))

  return startOfDayUTC
}
