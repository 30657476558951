import { useState, useEffect } from 'react'

export const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                return 'mobile'
            } else if (window.innerWidth < 1024) {
                return 'tablet'
            } else {
                return 'desktop'
            }
        }
        return ''
    })

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setScreenSize('mobile')
            } else if (window.innerWidth < 1024) {
                setScreenSize('tablet')
            } else {
                setScreenSize('desktop')
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return screenSize
}
