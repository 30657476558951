import { Center, Box, Title, Text, TextInput, Button } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { Link } from 'react-router-dom'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import styles from './RegisterOrganization.module.css'

export default function RegisterOrganization() {
  const { createOrg } = useKindeAuth()
  const form = useForm({
    initialValues: { orgName: '' },
    validate: {
      orgName: (value) => (value.length < 3 ? 'Nome deve ter pelo menos 3 caracteres' : null),
    },
  })
  const handleSubmit = () => {
    try {
      localStorage.setItem('orgName', form.values.orgName)
    } catch (error) {
      // does nothing, just prevents error when localStorage is disabled (incognito mode)
    }
    createOrg({ org_name: form.values.orgName })
  }
  const isSmall = useMediaQuery('(max-width: 900px)')
  const btnSize = isSmall ? 'md' : 'lg'
  return (
    <Center>
      <Box className={styles.box}>
        <Title className={styles.registerTitle}>Criar nova conta</Title>
        <Text className={styles.registerText}>Escolha um nome para a sua organização</Text>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Nome da organização"
            placeholder="Acme Ltda."
            {...form.getInputProps('orgName')}
            classNames={{ input: styles.input }}
          />
          <Button type="submit" variant="filled" fullWidth size={btnSize} className={styles.btnSubmit}>
            Continuar
          </Button>
        </form>
        <Link to=".." style={{ display: 'grid', width: '100%' }}>
          <Button variant="light" size={btnSize} className={styles.btnBack}>
            Voltar
          </Button>
        </Link>
      </Box>
    </Center>
  )
}
