import { IconArrowsSort, IconSortAscending, IconSortDescending, TablerIconsProps } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'

export type OrderBy = 'desc' | 'asc' | ''

export type ToggleOrder = () => void

export type TOrderIcon = (props: TablerIconsProps) => React.JSX.Element

const ICONS_SORT_MAP = {
  desc: IconSortDescending,
  asc: IconSortAscending,
  '': IconArrowsSort,
}

export const useOrderBy = (): [OrderBy, ToggleOrder, TOrderIcon] => {
  const [order, setOrder] = useState<OrderBy>('')

  const toggleOrder = useCallback(() => {
    if (order === 'desc') {
      setOrder('asc')
    } else if (order === 'asc') {
      setOrder('')
    } else {
      setOrder('desc')
    }
  }, [order])

  const Icon = useMemo(() => ICONS_SORT_MAP[order], [order])

  return [order, toggleOrder, Icon]
}
