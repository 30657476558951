import { useCallback, useMemo, useState } from 'react'
import { Card, Title, Button, Space, Center, Text, Flex, Loader } from '@mantine/core'
import { IconFileDownload } from '@tabler/icons-react'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useDistributor } from 'src/providers/Distributor'
import { CustomTable } from './Table'
import { useQuery } from '@tanstack/react-query'
import { ExportToCSVModal } from '../common/Modals/ExportModal'
import ExportSAPModal from 'src/components/SentOrdersTable/ExportOrders/ExportSAPModal'
import { sanitizeSentOrdersToCsv } from 'src/utils/prepareDataToCsvExport'
import { getSentOrders } from 'src/requests/orders/getSentOrders'
import { startOfDay } from 'date-fns'
import { UTCDate } from '@date-fns/utc'
import { useMiddlemen } from 'src/hooks/useMiddlemen'
import getOrdersWithSuppliers from 'src/utils/getOrdersWithSuppliers'

const INITIAL_DATES_STATE = {
  startDate: null,
  endDate: null,
}

type ExportDates = {
  startDate: Date | null
  endDate: Date | null
}

export default function SentOrdersTable() {
  const [exportDates, setExportDates] = useState<ExportDates>(INITIAL_DATES_STATE)
  const [opened, { open, close }] = useDisclosure()
  const [openedSAP, { open: openSAP, close: closeSAP }] = useDisclosure()
  const { distributor } = useDistributor()

  const { middlemen } = useMiddlemen()

  // TODO: Move flag to backend
  const hasSAPIntegration =
    import.meta.env?.VITE_FEAT_ORGS_WITH_SAP_INTEGRATION?.split(',').includes(String(distributor?.distributorId)) ??
    false

  const [page, setPage] = useState(1)
  const { data, isLoading } = useQuery({
    queryKey: ['orders', 'sent', { page, size: 15 }],
    queryFn: () =>
      getSentOrders({
        distributorId: distributor!.distributorId,
        page,
        size: 15,
      }),
    enabled: !!distributor!.distributorId,
  })

  const ordersWithSuppliers = useMemo(
    () => getOrdersWithSuppliers(data?.records, distributor),
    [data?.records, distributor],
  )

  const { data: filteredByDateOrders, isFetching: filteredByDateOrdersIsFetching } = useQuery({
    queryKey: ['orders', 'sent', { page }],
    queryFn: () =>
      getSentOrders({
        distributorId: distributor!.distributorId,
        page,
        startDate: startOfDay(new UTCDate(exportDates.startDate!)),
        endDate: startOfDay(new UTCDate(exportDates.endDate!)),
      }),
    enabled: !!distributor!.distributorId && !!exportDates.endDate && !!exportDates.startDate,
  })

  const filteredByDatesOrdersWithSuppliers = useMemo(
    () => getOrdersWithSuppliers(filteredByDateOrders?.records, distributor),
    [filteredByDateOrders?.records, distributor?.producers],
  )

  const csvData = sanitizeSentOrdersToCsv(filteredByDatesOrdersWithSuppliers, exportDates, distributor?.skus, middlemen)

  const handleClose = useCallback(() => {
    setExportDates(INITIAL_DATES_STATE)
    close()
  }, [close])

  const isMobile = useMediaQuery('(max-width: 575px)')

  return (
    <Card
      py={{
        base: 'md',
        md: 'xl',
      }}
      px={0}
      shadow="md"
      radius="md"
    >
      <ExportToCSVModal
        title="Exportar pedidos enviados"
        filenamePrefix="pedidos"
        exportDates={exportDates}
        setExportDates={setExportDates}
        opened={opened}
        onClose={handleClose}
        csvData={csvData}
        isLoading={filteredByDateOrdersIsFetching}
      />
      {hasSAPIntegration && <ExportSAPModal title="Exportar pedidos enviados" opened={openedSAP} onClose={closeSAP} />}
      <Flex align="center" justify="space-between" px={25}>
        <Title order={3}>Pedidos enviados</Title>
        <Flex columnGap={16}>
          {hasSAPIntegration && (
            <Button onClick={openSAP} size="sm" variant="default" radius="sm">
              <IconFileDownload strokeWidth={1.5} size={18} style={{ marginRight: isMobile ? 0 : 12 }} />
              {!isMobile && 'Exportar SAP'}
            </Button>
          )}
          <Button onClick={open} size="sm" variant="default" radius="sm">
            <IconFileDownload strokeWidth={1.5} size={18} style={{ marginRight: isMobile ? 0 : 12 }} />
            {!isMobile && 'Exportar'}
          </Button>
        </Flex>
      </Flex>
      <Space h="md" />
      {!data || !data?.records?.length || isLoading ? (
        <Center>
          <Text>{isLoading ? <Loader /> : 'Você ainda não tem pedidos enviados'}</Text>
        </Center>
      ) : (
        <CustomTable activePage={page} onChangePage={setPage} totalPages={data.last} items={ordersWithSuppliers} />
      )}
    </Card>
  )
}
