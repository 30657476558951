import type { OrderWithSupplier, ProducerReceivingDays } from 'src/types'

export type OrderWithReceivingDays = OrderWithSupplier & {
  receivingDays: ProducerReceivingDays[]
}

export default function prepareOrdersTable(Orders: OrderWithReceivingDays[], skuName?: string | number) {
  if (!skuName || !Orders) return []
  const OrdersWithSku = Orders.filter((order) =>
    order.products.find((product) => product.sku === skuName && order.sentOrder.length === 0),
  )

  return OrdersWithSku
}
