import { useCallback, useEffect, useMemo, useState } from 'react'

function chunk<T>(array: T[], size: number): T[][] {
  if (!array.length) {
    return []
  }
  const head = array.slice(0, size)
  const tail = array.slice(size)
  return [head, ...chunk(tail, size)]
}

export function usePaginateArray<T extends Record<string, any>>(arr: T[], pageSize: number = 10) {
  const [activePage, setActivePage] = useState(1)

  const chunks = useMemo(() => chunk(arr, pageSize), [pageSize, arr])

  const onChangePage = useCallback((num: number) => {
    setActivePage(num)
  }, [])

  useEffect(() => {
    setActivePage(1)
  }, [chunks?.length])

  const paginated = useMemo(() => chunks[activePage - 1] ?? [], [activePage, chunks])

  return {
    paginated,
    totalPages: Math.ceil(arr.length / pageSize) || 1,
    onChangePage,
    activePage,
  }
}
