import { Table } from '@mantine/core'
import { GenerateOrderWithAITableHead } from './Head'
import { GenerateOrderWithAITableBody, GenerateOrderWithAITableBodyProps } from './Body'

type GenerateOrderWithAITableProps = GenerateOrderWithAITableBodyProps

export function GenerateOrderWithAITable(props: GenerateOrderWithAITableProps) {
  return (
    <Table withRowBorders={false}>
      <GenerateOrderWithAITableHead />
      <GenerateOrderWithAITableBody
        updateQtyProps={props.updateQtyProps}
        greatPurchaseId={props.greatPurchaseId}
        items={props.items}
      />
    </Table>
  )
}
