import { createContext } from 'react'
import type { OrdersContextValue } from 'src/types'

export const OrdersContext = createContext<OrdersContextValue>({
  isFetching: false,
  isLoading: false,
  setShouldRefetch: () => null,
  onCancelOrder: () => Promise.resolve(),
  orders: null,
  duplicatedOrdersWithExpirationDate: [],
})

export const OrdersProvider = OrdersContext.Provider
