import { api } from 'src/services/api'
import httpErrorHandler from 'src/utils/httpErrorHandler'

export default async function uploadCSV(body: FormData) {
  try {
    await api.post(`/upload-csv`, body)
  } catch (error) {
    httpErrorHandler(error)
  }
}
