import { Routes, Route } from 'react-router-dom'
import PrivatePage from 'src/components/PrivatePage'
import OrdersTable from 'src/components/OrdersTable'
import ContinueOrder from 'src/components/ContinueOrder'

export default function DraftOrders() {
  return (
    <PrivatePage>
      <Routes>
        <Route index element={<OrdersTable />} />
        <Route path=":order" element={<ContinueOrder />} />
      </Routes>
    </PrivatePage>
  )
}
