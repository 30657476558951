import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'

type useFixedPricesContext = {
  producerCode?: string
}

export function useFixedPrices(options?: useFixedPricesContext) {
  const { distributor } = useDistributor()

  const filteredFixedPrices = useMemo(() => {
    const fixedPrices = distributor?.fixedPrices ?? []

    return fixedPrices.filter((price) => (options?.producerCode ? price.producerCode === options.producerCode : price))
  }, [distributor?.fixedPrices, options?.producerCode])

  return filteredFixedPrices
}
