import { useAudio } from "react-use"
import { BalloonProps } from "../Chat"
import { useEffect, useState } from "react"
import styles from '../Chat.module.css'
import { Box, Button, Slider } from "@mantine/core"
import { IconPlayerPauseFilled, IconPlayerPlayFilled } from "@tabler/icons-react"
import { formatSeconds } from "src/utils/formatSeconds"
import { getStatusIcon } from "../statuses"

type BalloonAudioProps = Pick<BalloonProps, 'mediaUrl' | 'className' | 'status' | 'hour' | 'resendFailedMessage'>

export default function BalloonAudio({ mediaUrl, className, status, hour, resendFailedMessage }: BalloonAudioProps) {
  const [audio, state, controls, audioRef] = useAudio({
    src: mediaUrl ?? '',
    autoPlay: false,
    muted: false,
    type: 'audio/ogg'
  })
  const [playbackRate, setPlaybackRate] = useState(1)

  const playbackRates = [1.0, 1.5, 2]
  const StatusIcon = getStatusIcon(status)
  
  useEffect(() => {
    if (state.duration === state.time) {
      controls.seek(0)
    }
  }, [state.duration, state.time])
 
  function onChangePlaybackRate() {
    if (audioRef.current) {
      const currentIndex = playbackRates.indexOf(audioRef.current.playbackRate)

      const nextIndex = (currentIndex + 1) % playbackRates.length

      const nextRate = playbackRates[nextIndex]

      setPlaybackRate(nextRate)
      audioRef.current.playbackRate = nextRate
    }
  }

  return (
    <Box className={className}>
      <div className={styles.audio_player}>
        {audio}
        <div className={styles.players}>
          <Button
            color="#A9B1B6" 
            size="compact-sm" 
            variant="transparent" 
            type="button" 
            className={styles.btn_play}
            onClick={state.playing ? controls.pause : controls.play}
          >
            {state.playing ? (
              <IconPlayerPauseFilled className={styles['icon-pause']}/>
            ) : (
              <IconPlayerPlayFilled className={styles['icon-play']}/>
            )}          
          </Button>
          <div className={styles.timeline}>
            <Box className={styles.slider}>
              <Box>
                <Slider
                  // mt={7}
                  max={state.duration}
                  size="sm"
                  label={null}
                  color="#53bdeb"
                  value={state.time}
                  onChange={controls.seek}
                  thumbSize={14}
                  styles={{
                    thumb: {
                      borderWidth: 0,
                      background: '#53bdeb'
                    }
                  }}
                />
              </Box>
            </Box>
            <Box className={styles.message_balloon_data_info}>
              <Box component="span" className={styles.message_balloon_time}>
                {formatSeconds(state.time)}
              </Box>
              <Box component="span" className={styles.message_balloon_time}>
                {hour}
                {!!status && !!StatusIcon && (
                  <StatusIcon resendFailedMessage={resendFailedMessage}/>
                )}
              </Box>
            </Box>
          </div>
        </div>
        <Box px="lg">
          <Button w="63" onClick={onChangePlaybackRate} px="md" size="compact-sm" radius={999} color="#A9B1B6">
            {playbackRate.toFixed(1)}x
          </Button>
        </Box>
      </div>
    </Box>
  )
}