import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import type { NotificationMessage } from 'src/types'

export const errorNotificationFactory = (context: NotificationMessage) => {
  const notification = pendingNotificationFactory({
    pending: {
      title: '',
      message: '',
    },
    success: {
      title: '',
      message: '',
    },
    error: context,
  })

  return {
    dispatch: () => notification.error(context),
  }
}
