import { createContext } from 'react'
import type { DistributorContextValue } from 'src/types'

export const DistributorContext = createContext<DistributorContextValue>({
  isFetching: false,
  setDistributor: () => null,
  distributor: null,
})

export const DistributorProvider = DistributorContext.Provider
