import { useState } from "react"
import { BalloonProps } from "./Chat"
import { IconExclamationCircle, IconReload } from "@tabler/icons-react"
import { Button, Tooltip } from "@mantine/core"

export function FailedStatus({ resendFailedMessage }: Pick<BalloonProps, 'resendFailedMessage'>) {
  const [hovering, setHovering] = useState(false)

  if (!resendFailedMessage) {
    return <IconExclamationCircle color="#cc0000" size={14}/>
  }

  function onMouseEnter() {
    setHovering(true)
  }

  function onMouseLeave() {
    setHovering(false)
  }

  return (
    <Tooltip label="Reenviar mensagem" withArrow>
      <Button
        size="compact-xs" 
        variant="transparent" 
        type="button"
        px={0}
        pl={4}
        onClick={resendFailedMessage}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {hovering ? (
          <IconReload size={16}/>
        ) : (
          <IconExclamationCircle color="#cc0000" size={16}/>
        )}
      </Button>
    </Tooltip>
  )
}