import { forwardRef } from 'react'
import { Avatar } from '@mantine/core'
import type { UserAvatarProps } from 'src/types'
import styles from './UserMenu.module.css'

const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ picture, initials, notClickable, ...others }: UserAvatarProps, ref) => {
    return (
      <Avatar
        ref={ref}
        src={picture}
        radius="xl"
        classNames={{
          root: notClickable ? styles.avatarNotClickable : styles.avatar,
          placeholder: notClickable ? styles.avatarPlaceholderNotClickable : styles.avatarPlaceholder,
          image: styles.avatarImage,
        }}
        {...others}
      >
        {initials}
      </Avatar>
    )
  },
)

export default UserAvatar
