import httpErrorHandler from 'src/utils/httpErrorHandler'
import type { WhatsMessage } from 'src/types'
import { api } from 'src/services/api'

export default async function sendWhatsappMessage(whatsMessage: WhatsMessage) {
  try {
    await api.post('/send-message', whatsMessage)
  } catch (error) {
    httpErrorHandler(error)
  }
}
