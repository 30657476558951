import { type ReactNode } from 'react'
import { AppShell as AppShellMantine } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Outlet } from 'react-router-dom'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Header from 'src/components/Header'
import Navbar from 'src/components/Navbar'
import styles from './AppShell.module.css'

type Props = {
  children?: ReactNode
}

export default function AppShell({ children }: Props) {
  const [navbarIsOpen, { open: openNavbar, close: closeNavbar }] = useDisclosure(false)
  const { isAuthenticated } = useKindeAuth()
  const headerProps = { navbarIsOpen, openNavbar }
  const navProps = { navbarIsOpen, closeNavbar }
  return (
    <AppShellMantine
      header={{ height: 60 }}
      navbar={{ width: 200, breakpoint: 'sm', collapsed: { mobile: !navbarIsOpen } }}
      padding="lg"
      disabled={!isAuthenticated}
      classNames={{
        main: !isAuthenticated ? styles.mainLoggedOut : undefined,
        header: styles.header,
      }}
    >
      <Header {...headerProps} />
      <Navbar {...navProps} />
      <AppShellMantine.Main>
        {children ? (
          children
        ) : (
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        )}
      </AppShellMantine.Main>
    </AppShellMantine>
  )
}
