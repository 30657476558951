import { Combobox, Group, Input, InputBase, Text, useCombobox } from '@mantine/core'
import { addNonDigit } from 'src/utils/parseBrazilianPhones'

type MiddlemanSelectOptionProps = {
  description: string
  label: string
}

export function MiddlemanSelectOption({ description, label }: MiddlemanSelectOptionProps) {
  return (
    <Group>
      <div>
        <Text fz="sm" fw={500}>
          {label}
        </Text>
        <Text fz="xs" opacity={0.6}>
          {addNonDigit(description)}
        </Text>
      </div>
    </Group>
  )
}

type Item = {
  value: string
  label: string
  description: string
}

type MiddlemanSelectProps = {
  value?: string | null
  onChange(item: string): void
  data: Item[]
  label: string
  error?: string
  name?: string
}

export function MiddlemanSelect({ value, onChange, data, label, error, name }: MiddlemanSelectProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const selectedOption = data.find((item) => item.value === value)

  const options = data.map((item) => (
    <Combobox.Option value={item.value} key={item.value}>
      <MiddlemanSelectOption {...item} />
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        onChange(val)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          label={label}
          component="button"
          type="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          multiline
          error={error}
          name={name}
        >
          {selectedOption ? selectedOption.label : <Input.Placeholder>Selecione um</Input.Placeholder>}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
