import { useState, useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { FirebaseAuthProvider } from './FirebaseAuthContextValue'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { firebaseAuth, signInWithCustomToken } from 'src/configs/firebase'
import useFirebaseUser from 'src/hooks/useFirebaseUser'
import getCustomToken from 'src/requests/getCustomToken'
import setAnalytics from 'src/utils/setAnalytics'
import type { FirebaseAuthProps } from 'src/types'

export default function FirebaseAuth({ children }: FirebaseAuthProps) {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [preventAuth, setPreventAuth] = useState(false)
  const { showBoundary } = useErrorBoundary()
  const { isLoading: isLoadingKinde, user: kindeUser, getToken: getAccessToken } = useKindeAuth()
  const { firebaseUser } = useFirebaseUser()
  const userId = kindeUser?.id || ''
  const contextValue = { isAuthenticating, setPreventAuth }

  // User is authenticated in Kinde, but not in Firebase
  useEffect(() => {
    const fetch = async () => {
      try {
        setIsAuthenticating(true)
        const accessToken = await getAccessToken()
        if (accessToken) {
          const customToken = await getCustomToken()
          await signInWithCustomToken(firebaseAuth, customToken)
          setAnalytics(userId)
        } else {
          throw new Error('No access token was retrieved from Kinde')
        }
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      } finally {
        setIsAuthenticating(false)
      }
    }
    if (!preventAuth && !isLoadingKinde && kindeUser && !firebaseUser) fetch()
  }, [preventAuth, isLoadingKinde, kindeUser, firebaseUser, showBoundary, userId, getAccessToken])

  // User is not authenticated in Kinde, so set isAuthenticating to false
  useEffect(() => {
    if (!isLoadingKinde && !kindeUser) setIsAuthenticating(false)
  }, [isLoadingKinde, kindeUser])

  useEffect(() => {
    if (!isLoadingKinde && kindeUser && firebaseUser) setIsAuthenticating(false)
  }, [isLoadingKinde, kindeUser, firebaseUser])

  return <FirebaseAuthProvider value={contextValue}>{children}</FirebaseAuthProvider>
}
