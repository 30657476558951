import { formatDate, formatUTCDate } from '../formatDate'

export function formatDateAndWeekDay(d: Date) {
  const date = formatDate(d, 'dd/MM/yyyy')
  const weekDay = formatDate(d, 'EEEE')

  return {
    date,
    weekDay,
  }
}
export function formatUTCDateAndWeekDay(d: Date | string) {
  const date = formatUTCDate(d, 'dd/MM/yyyy')
  const weekDay = formatUTCDate(d, 'EEEE')

  return {
    date,
    weekDay,
  }
}
