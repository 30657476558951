import 'chart.js/auto'
import { Flex, Text, Box, Group } from '@mantine/core'
import { ChartProps, Bar } from 'react-chartjs-2'
import { ChartData, ChartDataset } from 'chart.js/auto'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomMultiSelectOption } from 'src/components/common/Inputs/CustomMultiSelect'
import { useSKUs } from 'src/hooks/useSKUs'
import { MultiSelectView } from '../SelectView'
import { ptBR } from 'date-fns/locale'
import { DatePickerInput } from '@mantine/dates'
import { ChartDates } from '../SelectView/types'
import { INITIAL_DATES_STATE, MAX_DATE } from '../SelectView/constants'
import { useQuery } from '@tanstack/react-query'
import { getMarketSummary } from 'src/requests/charts'
import { useDistributor } from 'src/providers/Distributor'
import { generateHSLColor } from '../utils/colors'

const config: Omit<ChartProps<'bar'>, 'data'> = {
  type: 'bar',
  height: '350px',
  width: '100%',
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          round: 'day',
          unit: 'day',
          displayFormats: {
            day: 'dd MMM',
          },
          tooltipFormat: 'dd MMM',
        },
        adapters: {
          date: {
            locale: ptBR,
          },
        },
        stacked: true,
      },
      y: {
        display: true,
        stacked: true,
      },
    },
  },
}

const TOTAL = {
  label: 'Gasto total',
  value: '',
  preventDelete: true,
  color: '#11CEE3',
}

export function ExpensesChart() {
  const [chartDates, setChartDates] = useState<ChartDates>(INITIAL_DATES_STATE)
  const { labelValueSKUs } = useSKUs()
  const [selectedSKUs, setSelectedSKUs] = useState<CustomMultiSelectOption[]>([TOTAL])
  const { distributor } = useDistributor()

  const items = selectedSKUs.map((s) => s.value).join(',')

  const isTotal = selectedSKUs.length === 1 && selectedSKUs[0].value === TOTAL.value

  const { data } = useQuery({
    initialData: [],
    queryFn: ({ signal }) =>
      getMarketSummary(distributor!.distributorId, {
        params: {
          endDate: chartDates.endDate!,
          startDate: chartDates.startDate!,
          items,
        },
        signal,
      }),
    queryKey: ['market-summary', chartDates, items],
    enabled: !!chartDates.endDate && !!chartDates.startDate && !!distributor?.distributorId,
  })

  const totalExpenses: ChartDataset<'bar'> = useMemo(
    () => ({
      label: 'Gasto total',
      type: 'bar',
      fill: false,
      backgroundColor: TOTAL.color,
      borderColor: TOTAL.color,
      data: data.map((item) => item.totalDailyCost ?? 0),
      borderDash: [5, 5],
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
      maxBarThickness: 15,
      borderRadius: Number.MAX_SAFE_INTEGER,
    }),
    [data],
  )

  const expenses: ChartDataset<'bar'>[] = useMemo(() => {
    return selectedSKUs.map((sku) => ({
      label: `${sku.label}`,
      fill: false,
      backgroundColor: sku.color ?? generateHSLColor(sku.value),
      borderColor: sku.color ?? generateHSLColor(sku.value),
      data: data.map((item) => item?.[sku.value]?.totalPurchasesPrice),
      borderDash: [5, 5],
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
      maxBarThickness: 15,
      borderRadius: Number.MAX_SAFE_INTEGER,
    }))
  }, [selectedSKUs, data])

  const chartData: ChartData<'bar'> = useMemo(() => {
    return {
      labels: data.map((item) => item.date),
      datasets: isTotal ? [totalExpenses] : expenses,
    }
  }, [expenses, data, isTotal, totalExpenses])

  const onChangeSKUs = useCallback((val: CustomMultiSelectOption[]) => {
    if (!val.length) {
      setSelectedSKUs([TOTAL])
      return
    }

    setSelectedSKUs(val.filter((opt) => opt.value !== TOTAL.value))
  }, [])

  const onRemoveSKU = useCallback((val: CustomMultiSelectOption) => {
    setSelectedSKUs((current) => {
      const filtered = current.filter((v) => v.value !== val.value)

      if (!filtered.length) {
        return [TOTAL]
      }

      return filtered
    })
  }, [])

  // for resize purposes
  const ref = useRef<any>(null)

  useEffect(() => {
    window.addEventListener('resize', function () {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      ref.current?.resize?.()
    })
  }, [])

  return (
    <Box>
      <Flex gap="md">
        <Box flex={1}>
          <Group justify="space-between" mb="lg">
            <Text fw={500}>Gastos</Text>

            <DatePickerInput
              onChange={(dates) => {
                const startDate = dates[0]
                const endDate = dates[1]

                setChartDates({
                  startDate,
                  endDate,
                })
              }}
              value={[chartDates.startDate, chartDates.endDate]}
              w={180}
              maxDate={MAX_DATE}
              allowSingleDateInRange
              valueFormat="DD MMM"
              locale="pt-br"
              clearable
              type="range"
              placeholder="Data"
            />
          </Group>
          <Box pos="relative">
            <Bar ref={ref} {...config} data={chartData} />
          </Box>
        </Box>

        <MultiSelectView
          selected={selectedSKUs}
          handleRemove={onRemoveSKU}
          onChange={onChangeSKUs}
          options={labelValueSKUs}
          hideCheckboxes
        />
      </Flex>
    </Box>
  )
}
