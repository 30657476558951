import { startOfDay, subDays } from 'date-fns'

export const INITIAL_CHECKBOXES_STATE = ['offers', 'purchases']

export const MAX_DATE = subDays(new Date(), 1)

export const INITIAL_DATES_STATE = {
  startDate: startOfDay(subDays(new Date(), 7)),
  endDate: MAX_DATE,
}
