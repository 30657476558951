import httpErrorHandler from 'src/utils/httpErrorHandler'
import { api } from 'src/services/api'
import { DeliveryPeriod } from 'src/types'

type sendOrderToBackendContext = {
  distributorId: string
  orderId: string
  deliveryPeriod: DeliveryPeriod | string
}

export default async function sendOrderToBackend({ orderId, ...body }: sendOrderToBackendContext) {
  try {
    await api.post(`/send-order/${orderId}`, body)
  } catch (error) {
    console.log(error)
    httpErrorHandler(error)
  }
}
