import { Distributor, TCreateOrUpdateDemand } from 'src/types'
import db from 'src/configs/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import dataConverter from '../utils/dataConverter'

export async function updateDemands({ distributorId, demands }: TCreateOrUpdateDemand) {
  const distributorRef = doc(db, 'distributors', distributorId).withConverter(dataConverter<Distributor>())

  await updateDoc(distributorRef, {
    demands,
  })
}
