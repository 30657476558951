import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { SUPPLIER_TYPE } from 'src/types'
import { removeDuplicatedArrayItems } from 'src/utils/arrays/removeDuplicated'

export function useSuppliers() {
  const { distributor } = useDistributor()

  const suppliers = useMemo(() => {
    return distributor?.producers || []
  }, [distributor?.producers])

  const suppliersWithoutMiddlemen = useMemo(() => {
    return suppliers.filter((s) => s.type !== SUPPLIER_TYPE.MIDDLEMAN)
  }, [suppliers])

  const labelValueSuppliers = useMemo(() => {
    const options = suppliers.map((supplier) => ({
      value: supplier.phone,
      label: supplier.name,
    }))

    return removeDuplicatedArrayItems(options, 'value')
  }, [suppliers])

  const labelValueSuppliersWithoutMiddlemen = useMemo(() => {
    const options = suppliersWithoutMiddlemen.map((supplier) => ({
      value: supplier.id,
      label: supplier.name,
    }))

    return removeDuplicatedArrayItems(options, 'value')
  }, [suppliersWithoutMiddlemen])

  return {
    suppliers,
    labelValueSuppliers,
    suppliersWithoutMiddlemen,
    labelValueSuppliersWithoutMiddlemen,
  }
}
