import { notifications } from '@mantine/notifications'
import { KeyboardEvent, useCallback, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { updateSingleDemand } from 'src/requests/firebase/demands/updateSingleDemand'
import { useDistributor } from 'src/providers/Distributor'

type Options = {
  onSave?(): void
}

type DemandData = {
  code: string
  sku: string
}

export function useUpdateDemand(options?: Options) {
  const updatingDemandInputRef = useRef<HTMLInputElement>(null)
  const [demandData, setDemandData] = useState<DemandData | null>(null)
  const [updatedDemandValue, setUpdatedDemandValue] = useState<number | null>(null)
  const { distributor } = useDistributor()

  const { showBoundary } = useErrorBoundary()

  const saveDemand = useCallback(
    async (data: DemandData, newDemandValue: number) => {
      try {
        const demands = distributor?.demands ?? []
        await updateSingleDemand({
          currentDemands: demands,
          demandData: data,
          distributorId: distributor!.distributorId,
          newValue: newDemandValue,
        })
        setDemandData(null)
        setUpdatedDemandValue(null)
        options?.onSave?.()
        notifications.show({
          autoClose: 3000,
          message: 'Demanda alterada com sucesso!',
        })
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    },
    [distributor, options, showBoundary],
  )

  const handleClickToUpdateDemand = useCallback(
    (data: DemandData) => () => {
      setDemandData(data)
      setTimeout(() => {
        updatingDemandInputRef.current?.focus()
      }, 100)
    },
    [],
  )

  const handleChangeDemand = useCallback((value: string) => {
    setUpdatedDemandValue(Number(value))
  }, [])

  const handleSaveDemand = useCallback(
    (event: KeyboardEvent) => {
      const newDemand = updatedDemandValue
      const enterPressed = event.key === 'Enter'
      if (demandData && enterPressed && (newDemand || typeof newDemand === 'number')) {
        saveDemand(demandData, newDemand)
      } else if (enterPressed) {
        setDemandData(null)
      }
    },
    [demandData, saveDemand, updatedDemandValue],
  )

  const handleBlurDemand = useCallback(() => {
    const newDemand = updatedDemandValue
    if (demandData && (newDemand || typeof newDemand === 'number')) {
      saveDemand(demandData, newDemand)
    } else {
      setDemandData(null)
    }
  }, [demandData, saveDemand, updatedDemandValue])

  return {
    handleClickToUpdateDemand,
    updatingDemandInputRef,
    updatingProductCode: demandData?.code,
    handleChangeDemand,
    handleSaveDemand,
    handleBlurDemand,
  }
}
