import { Box, Button } from '@mantine/core'
import { BalloonProps } from '../Chat'
import BalloonText from './Text'
import styles from '../Chat.module.css'
import { getStatusIcon } from '../statuses'
import { IconMenu2 } from '@tabler/icons-react'

type BalloonInteractiveProps = Pick<BalloonProps, 'hour' | 'message' | 'className' | 'status' | 'resendFailedMessage'>

export default function BalloonInteractive({
  className,
  hour,
  message,
  status,
  resendFailedMessage,
}: BalloonInteractiveProps) {
  const StatusIcon = getStatusIcon(status)
  return (
    <Box className={className}>
      <BalloonText hideInfo={!!message} hour={hour} message={message} />
      <Box className={styles.message_balloon_data_info}>
        <Box component="span" className={styles.message_balloon_time}>
          {hour}
        </Box>
        {!!status && !!StatusIcon && <StatusIcon resendFailedMessage={resendFailedMessage} />}
      </Box>
      <Button
        bg="transparent"
        w="100%"
        mt="xs"
        variant="transparent"
        disabled
        leftSection={<IconMenu2 size={20} />}
        c="#53bdeb"
        opacity={0.6}
        style={{
          borderTop: '1px solid rgba(0,0,0,0.1)',
          borderRadius: 0,
        }}
      >
        Escolha o fornecedor
      </Button>
    </Box>
  )
}
