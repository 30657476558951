// XXX: Using `FallbackComponent` from `react-error-boundary` alters the CSS order, causing Mantine styles to break.
// This import ensures styles are properly displayed and doesn't increase bundle size.
import '@mantine/core/styles.css'
import { Box, Title, Text, Space, Button, Code } from '@mantine/core'
import IllustrationLaptop from './IllustrationLaptop'
import type { ErrorProps } from 'src/types'
import styles from './ErrorMessage.module.css'
import AppShell from 'src/components/AppShell'

export default function ErrorMessage({ error, resetErrorBoundary }: ErrorProps) {
  const receivedError = error instanceof Error ? error.message : error
  const errorMessage = typeof receivedError === 'string' ? receivedError : 'Unknown error from backend'

  return (
    <AppShell>
      <Box className={styles.box}>
        <IllustrationLaptop width={120} height={120} />
        <Title order={5} className={styles.title}>
          Ocorreu um erro
        </Title>
        <Text size="sm">Se persistir, por favor contate o suporte</Text>
        <Space h="xs" />
        <Button className={styles.btn} onClick={resetErrorBoundary} size="xs">
          Recarregar
        </Button>
        <Space h="xs" />
        <Code className={styles.code}>{errorMessage}</Code>
      </Box>
    </AppShell>
  )
}
