import { Center, Loader } from '@mantine/core'
import styles from './InitialLoader.module.css'

export default function InitialLoader() {
  return (
    <Center className={styles.center}>
      <Loader />
    </Center>
  )
}
