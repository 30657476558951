import { Text as BaseText, createPolymorphicComponent, type TextProps } from '@mantine/core'
import { useState, useRef, useEffect, ReactNode } from 'react'

interface Props extends TextProps {
  children?: ReactNode
}

function CustomText({ children, ...props }: Props) {
  const ref = useRef<HTMLParagraphElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (ref.current) {
      setIsTruncated(ref.current.offsetWidth < ref.current.scrollWidth)
    }
  }, [])

  return (
    <BaseText {...props} ref={ref} data-is-truncated={isTruncated}>
      {children}
    </BaseText>
  )
}

const Text = createPolymorphicComponent<'p', TextProps>(CustomText)

export default Text
