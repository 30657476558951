import { ActionIcon, Box, Flex, Text } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { IconPencil } from '@tabler/icons-react'
import { toZonedTime } from 'date-fns-tz'
import { ReactNode, useRef } from 'react'
import { useUpdateDeliveryDate } from 'src/components/OrdersBySKU/hooks/useUpdateDeliveryDate'

interface DeliveryDateProps {
  setShouldRefetchOrders: (value: boolean) => void
  orderId: string
  deliveryDate: Date
  valueFormat?: string
  weekDay?: string
  sku?: string
  disableEdit?: boolean
  rightSideIcon?: ReactNode
}

export const DeliveryDate = ({
  setShouldRefetchOrders,
  orderId,
  deliveryDate,
  valueFormat,
  weekDay,
  sku,
  disableEdit,
  rightSideIcon,
}: DeliveryDateProps) => {
  const formattedDeliveryDate = toZonedTime(deliveryDate, 'UTC')
  const datePickerRef = useRef<HTMLButtonElement>(null)

  const { handleUpdateOrderDeliveryDate, handleUpdateSkuDeliveryDate } = useUpdateDeliveryDate({
    onSave: () => setShouldRefetchOrders(true),
  })

  const handleDateChange = (value: Date | null) => {
    if (!value) return

    if (sku) {
      return handleUpdateSkuDeliveryDate({ orderId, sku }, value)
    }

    return handleUpdateOrderDeliveryDate({ orderId }, value)
  }

  return (
    <Flex align="center" justify="center" w="100%">
      <Box pos={'relative'}>
        <Flex direction="column" align="center" content="center">
          <DatePickerInput
            ref={datePickerRef}
            valueFormat={valueFormat ?? 'DD/MM, dddd'}
            locale="pt-br"
            minDate={new Date()}
            defaultValue={formattedDeliveryDate}
            onChange={handleDateChange}
            variant="unstyled"
            style={{
              pointerEvents: 'none',
              height: 24,
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
          <Text size="xs">{weekDay}</Text>
        </Flex>
      </Box>
      {rightSideIcon}
      {!disableEdit && (
        <ActionIcon ml={4} variant="subtle" onClick={() => datePickerRef.current?.click()}>
          <IconPencil size="20" />
        </ActionIcon>
      )}
    </Flex>
  )
}
