import { isSameDay } from 'date-fns'
import { useMemo } from 'react'
import { hasMatchingStrings } from 'src/utils/arrays/hasMatchingStrings'
import { lowerCase } from 'src/utils/strings/lowerCase'
import { normalizeString } from 'src/utils/strings/normalize'

type FilterBySingleDateContext<T extends Record<string, any>> = {
  value: Date | null
  keysToFilter: (keyof T)[]
}

function filterBySingleDate<T extends Record<string, any>>({ value, keysToFilter }: FilterBySingleDateContext<T>) {
  return function (item: T) {
    return keysToFilter
      .map((key) => {
        const itemKey = item[key]

        if (Array.isArray(itemKey)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          return value ? itemKey.some((val: Date) => isSameDay(new Date(val), value)) : true
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return value ? isSameDay(new Date(itemKey), value) : true
      })
      .some(Boolean)
  }
}

type FilterBySingleValueContext<T extends Record<string, any>> = {
  value: string
  keysToFilter: (keyof T)[]
}

function filterBySingleValue<T extends Record<string, any>>({ value, keysToFilter }: FilterBySingleValueContext<T>) {
  return function (item: T) {
    return keysToFilter
      .map((key) => {
        const normalizedKeyValue = normalizeString(lowerCase(String(item[key])))

        const normalizedValue = normalizeString(lowerCase(value))

        return normalizedKeyValue.includes(normalizedValue)
      })
      .some(Boolean)
  }
}

type FilterByMultipleValuesContext<T extends Record<string, any>> = {
  value: string[]
  keysToFilter: (keyof T)[]
  returnEmpty?: boolean
}

function filterByMultipleValues<T extends Record<string, any>>({
  value,
  keysToFilter,
  returnEmpty,
}: FilterByMultipleValuesContext<T>) {
  return function (item: T) {
    if (!value.length) {
      return true
    }

    return keysToFilter
      .map((key) => {
        const objectKey = item[key]

        if (typeof objectKey === 'string') {
          return value.includes(objectKey)
        } else if (Array.isArray(objectKey)) {
          if (!objectKey.length && returnEmpty) return true

          return hasMatchingStrings(value, objectKey)
        } else {
          return true
        }
      })
      .some(Boolean)
  }
}

type CreateSingleFilterContext<O extends Record<string, any>> = {
  type: 'single'
  value: string
  keys: (keyof O)[]
}

type CreateMultipleFilterCOntext<O extends Record<string, any>> = {
  type: 'multiple'
  value: string[]
  keys: (keyof O)[]
  returnEmpty?: boolean
}

type CreateSingleDateFilterContext<O extends Record<string, any>> = {
  type: 'single-date'
  value: Date | null
  keys: (keyof O)[]
}

export function useFilterArray<O extends Record<string, any>>(
  arrayToFilter: O[],
  filtersOptions: (CreateSingleFilterContext<O> | CreateMultipleFilterCOntext<O> | CreateSingleDateFilterContext<O>)[],
) {
  const filteredArray = useMemo(() => {
    return filtersOptions.reduce((arr, filter) => {
      switch (filter.type) {
        case 'multiple':
          return arr.filter(
            filterByMultipleValues({
              keysToFilter: filter.keys,
              value: filter.value,
              returnEmpty: filter.returnEmpty,
            }),
          )
        case 'single':
          return arr.filter(
            filterBySingleValue({
              keysToFilter: filter.keys,
              value: filter.value,
            }),
          )
        case 'single-date':
          return arr.filter(
            filterBySingleDate({
              keysToFilter: filter.keys,
              value: filter.value,
            }),
          )
        default:
          return arr
      }
    }, arrayToFilter)
  }, [arrayToFilter, filtersOptions])

  return filteredArray
}
