import { GreatPurchaseOffer, GreatPurchaseProduct } from 'src/types'
import { HIGHER_PURCHASE_MESSAGE, LOWER_PURCHASE_MESSAGE } from 'src/constants/solver'

export function generateDifferingMessage(products: GreatPurchaseProduct[] = []) {
  const messages = []
  const anyLowerPurchase = products.some((product) => {
    const sumProductOffers = sumProductOffersQuantity(product.offers)

    return product.demand > sumProductOffers
  })
  const anyHigherPurchase = products.some((product) => {
    const sumProductOffers = sumProductOffersQuantity(product.offers)

    return product.demand < sumProductOffers
  })

  if (anyLowerPurchase) {
    messages.push(LOWER_PURCHASE_MESSAGE)
  }

  if (anyHigherPurchase) {
    messages.push(HIGHER_PURCHASE_MESSAGE)
  }

  return messages.join('\n\n')
}

export function sumProductOffersQuantity(offers: GreatPurchaseOffer[]) {
  return offers.reduce((acc, item) => {
    const desiredQty = Number(item.desiredQty)

    return acc + desiredQty
  }, 0)
}

export function sumProductOffersPrice(offers: GreatPurchaseOffer[]) {
  return offers.reduce((acc, item) => {
    const desiredQty = Number(item.desiredQty)

    return acc + item.price * desiredQty
  }, 0)
}

export function sumTotalOrderPrice(products: GreatPurchaseProduct[] = []) {
  return products.reduce((acc, product) => {
    const productTotal = sumProductOffersPrice(product.offers)

    return acc + productTotal
  }, 0)
}
