import { api } from 'src/services/api'

type RequestOfferContext = {
  distributorId: string
  date: Date
  products: string[]
  supplierId: string
}

export default async function requestOffer(body: RequestOfferContext) {
  await api.post(`/offers/request`, body)
}
