import { Routes, Route } from 'react-router-dom'
import PrivatePage from 'src/components/PrivatePage'
import SentOrdersTable from 'src/components/SentOrdersTable'

export default function SentOrders() {
  return (
    <PrivatePage>
      <Routes>
        <Route index element={<SentOrdersTable />} />
      </Routes>
    </PrivatePage>
  )
}
