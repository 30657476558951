import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import Login from 'src/components/Login'
import type { PrivatePageProps } from 'src/types'

/** If the user is authenticated, then render the page, else render a Login component */

export default function PrivatePage({ children }: PrivatePageProps) {
  const { isAuthenticated } = useKindeAuth()
  if (isAuthenticated) return <>{children}</>
  return <Login />
}
