/**
 * @param {string} number - The phone number to be formatted. Treats 8 or 9 digit numbers.
 * Must contain the country code and no other characters. Example: 5511912345678.
 */

export default function formatBrazilianNumber(number: string) {
  const areaCode = number.substring(0, 2)
  const localCode = number.substring(2, 4)
  const remaining = number.substring(4)
  if (remaining.length === 8) return `+${areaCode} ${localCode} ${remaining.substring(0, 4)}-${remaining.substring(4)}`
  else if (remaining.length === 9)
    return `+${areaCode} ${localCode} ${remaining.substring(0, 5)}-${remaining.substring(5)}`
  return ''
}
