import { Image, Space, Stack } from '@mantine/core'
import {
  IconBoxSeam,
  IconChartLine,
  IconCubePlus,
  IconPackageImport,
  IconPencil,
  IconReceipt,
  IconTractor
} from '@tabler/icons-react'
import type { NavbarContentProps } from 'src/types'
import styles from './Navbar.module.css'
import NavbarItem from './NavbarItem'

const navItems = [
  { to: '/fornecedores', icon: <IconTractor size={17} />, text: 'Fornecedores' },
  {
    text: 'Ofertas',
    children: [
      { to: '/ofertas/sku', text: 'Ativas', icon: <IconBoxSeam size={17} /> },
      { to: '/ofertas/criar', text: 'Criar oferta', icon: <IconCubePlus size={17} /> },
      { to: '/ofertas', text: 'Histórico', icon: <IconPackageImport size={17} /> },
    ],
  },
  { 
    text: 'Pedidos',
    children: [
      { to: '/pedidos-abertos', text: 'Em aberto', icon: <IconPencil size={17} /> },
      { to: '/pedidos-enviados', text: 'Enviados', icon: <IconReceipt size={17} /> },
    ]
  },
  { to: '/relatorios', text: 'Relatórios', icon: <IconChartLine size={17} /> },
]

export type NavItemType = typeof navItems[number]

export default function NavbarContent(props: NavbarContentProps) {
  const { isMobile } = props
  return (
    <>
      {isMobile && <Image src="/logo.png" fit="contain" height={20} alt="logo" className={styles.logo} />}
      {isMobile && <Space h="lg" />}
      <Stack justify="space-between">
        <Stack gap="xs">
          {navItems.map((item, index) => (
            <NavbarItem key={index} item={item} />
          ))}
        </Stack>
      </Stack>
    </>
  )
}
