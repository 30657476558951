import * as Sentry from '@sentry/react'
import { isAxiosError } from 'src/utils/httpErrorHandler/typeGuards'

type Params = {
  exception: Parameters<typeof Sentry.captureException>['0']
  hint: Parameters<typeof Sentry.captureException>['1']
}

export const captureException = (exception: Params['exception'], hint?: Params['hint']) => {
  let metadata: Params['hint'] = {
    contexts: undefined,
    ...hint,
  }

  if (isAxiosError(exception)) {
    let axios = {
      request: {},
      response: {},
    }

    if (exception.response) {
      axios.request = {
        ...axios.request,
        data: exception.response.config.data,
        headers: exception.response.config.headers.toJSON(),
      }

      axios.response = {
        ...axios.response,
        data: exception.response.data,
      }

      if (exception.response.headers.toJSON instanceof Function) {
        axios.response = {
          ...axios.response,
          headers: exception.response.headers.toJSON(),
        }
      }
    }

    metadata.contexts = { ...metadata.contexts, axios }
  }

  return Sentry.captureException(exception, metadata)
}
