import { Flex, Pagination, Table } from '@mantine/core'
import { CustomTableHead } from './Head'
import { CustomTableBody } from './Body'
import { ReceivedOffer } from 'src/types'
import { usePaginateArray } from 'src/hooks/usePagination'

type CustomTableProps = {
  items: ReceivedOffer[]
}

export function CustomTable(props: CustomTableProps) {
  const { activePage, totalPages, onChangePage, paginated } = usePaginateArray(props.items, 15)

  return (
    <>
      <Table.ScrollContainer minWidth={300}>
        <Table withRowBorders={false} verticalSpacing="sm">
          <CustomTableHead />
          <CustomTableBody items={paginated} />
        </Table>
      </Table.ScrollContainer>
      <Flex px={25} justify="center" mt="xs">
        <Pagination total={totalPages} value={activePage} onChange={onChangePage} />
      </Flex>
    </>
  )
}
