import axios from 'axios'
import type { AxiosError } from 'axios'
import type { CustomError } from 'src/types'

export function isAxiosError(error: unknown): error is AxiosError {
  return axios.isAxiosError(error)
}

function isObject(variable: any): boolean {
  return typeof variable === 'object' && variable !== undefined
}

export function isPlainObject(variable: any): variable is object {
  return isObject(variable) && variable.constructor === Object
}

export function isCustomError(error: object): error is CustomError {
  return Object.prototype.hasOwnProperty.call(error, 'customError')
}

export function isStandardError(error: unknown): error is Error {
  return error instanceof Error
}
