import { useState, useEffect } from 'react'
import { firebaseAuth } from 'src/configs/firebase'
import type { User } from 'firebase/auth'

export default function useFirebaseUser() {
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null)
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) setFirebaseUser(user)
      else setFirebaseUser(null)
    })
    return () => unsubscribe()
  }, [])
  return { firebaseUser }
}
