import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { SUPPLIER_TYPE } from 'src/types'
import { removeDuplicatedArrayItems } from 'src/utils/arrays/removeDuplicated'

export function useDisplayableSuppliers() {
  const { distributor } = useDistributor()

  const displayableSuppliers = useMemo(() => {
    return distributor?.producers.filter((s) => s.type !== SUPPLIER_TYPE.REPRESENTED_SUPPLIER) || []
  }, [distributor?.producers])

  const labelValueDisplayableSuppliers = useMemo(() => {
    const options = displayableSuppliers.map((displayableSupplier) => ({
      value: displayableSupplier.id,
      label: displayableSupplier.name,
      description: displayableSupplier.phone,
    }))

    return removeDuplicatedArrayItems(options, 'value')
  }, [displayableSuppliers])

  return {
    displayableSuppliers,
    labelValueDisplayableSuppliers,
  }
}
