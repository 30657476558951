import { parseISO } from 'date-fns'
import { setToStartOfUTCDay } from 'src/utils/formatDate'

export function parseDeliveryDate(isoDate: string) {
  const zonedDate = parseISO(isoDate)
  const utcDate = zonedDate ? setToStartOfUTCDay(zonedDate) : zonedDate

  return {
    zonedDate,
    utcDate,
  }
}
