import { api } from 'src/services/api'
import httpErrorHandler from 'src/utils/httpErrorHandler'

type GenerateOrderWithAIPreviewContext = {
  distributorId: string
  id: string
}

export default async function applyOrderWithAIPreview({ id, ...body }: GenerateOrderWithAIPreviewContext) {
  try {
    await api.post(`/solver/${id}`, body)
  } catch (error) {
    httpErrorHandler(error)
  }
}
