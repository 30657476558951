import { TCreateUpdateProducer } from 'src/types'
import { api } from 'src/services/api'

export async function updateSupplierAndFixedPrices({ distributorId, id, fixedPrices, ...data }: TCreateUpdateProducer) {
  await api.put(`/suppliers/${id}`, {
    distributorId,
    fixedPrices,
    supplier: data,
  })
}
