import { doc, getDoc, updateDoc, arrayRemove, arrayUnion, setDoc } from 'firebase/firestore'
import db from 'src/configs/firebase'
import type { Order, DraftOrder } from 'src/types'
import { v4 as uuidV4 } from 'uuid'

export default async function createDraftOrder(
  distributorId: string,
  orderId: string,
  draftOrder: DraftOrder,
  expirationOrderDeliveryDate?: Date,
) {
  const orderRef = doc(db, 'distributors', distributorId, 'orders', orderId)
  const orderDocSnapshot = await getDoc(orderRef)

  const orderData = orderDocSnapshot.data() as Order
  const hasDesiredQty = Number(draftOrder.desiredQty) > 0

  if (orderData) {
    if (orderData.validUntil) {
      const newOrder = {
        ...orderData,
        id: uuidV4(),
        deliveryDate: expirationOrderDeliveryDate,
        draftOrder: [draftOrder],
      }

      delete newOrder.validUntil

      const newOrderRef = doc(db, 'distributors', distributorId, 'orders', newOrder.id)

      if (hasDesiredQty) {
        await setDoc(newOrderRef, newOrder)
      }
      return
    }

    const oldDraftOrder = orderData?.draftOrder
    const orderItem = oldDraftOrder.find((item) => item.sku === draftOrder.sku)

    if (orderItem) {
      await updateDoc(orderRef, {
        updatedAt: new Date(),
        draftOrder: arrayRemove(orderItem),
      })
    }

    if (hasDesiredQty) {
      await updateDoc(orderRef, {
        updatedAt: new Date(),
        draftOrder: arrayUnion(draftOrder),
      })
    }
  }
}
