import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import deleteOrderProduct from 'src/requests/firebase/draftOrder/deleteProduct'

type ProductData = {
  sku: string
  orderId: string
}

type Options = {
  onSave?(): void,
  deleteMessage?: string
}

export function useDeleteProduct(options?: Options) {
  const [isDeleteModalOpen, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure()
  const [data, setData] = useState<ProductData | null>(null)
  const { distributor } = useDistributor()

  const { showBoundary } = useErrorBoundary()

  async function handleDeleteProduct() {
    if (data) {
      try {
        await deleteOrderProduct({
          distributorId: distributor!.distributorId,
          orderId: data.orderId,
          sku: data.sku,
        })
        notifications.show({
          autoClose: 3000,
          // color: 'teal',
          message: options?.deleteMessage ?? 'Oferta removida com sucesso!',
        })
        handleCloseDeleteModal()
        options?.onSave?.()
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    }
  }

  function handleCloseDeleteModal() {
    setData(null)
    closeDeleteModal()
  }

  const handleOpenDeleteModal = (context: ProductData) => () => {
    setData(context)
    openDeleteModal()
  }

  return {
    isDeleteModalOpen,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleDeleteProduct,
  }
}
