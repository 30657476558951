import 'chartjs-adapter-date-fns'
import { ActionIcon, Box, Checkbox, Divider, Group, Paper, ScrollArea, Stack, Text } from '@mantine/core'
import { IconMinus } from '@tabler/icons-react'
import { CustomMultiSelect, CustomMultiSelectOption } from 'src/components/common/Inputs/CustomMultiSelect'
import { generateHSLColor } from '../utils/colors'
import { INITIAL_CHECKBOXES_STATE } from './constants'

type MultiSelectViewProps = {
  options: CustomMultiSelectOption[]
  onChange?: (values: CustomMultiSelectOption[]) => void
  selected: CustomMultiSelectOption[]
  handleRemove: (option: CustomMultiSelectOption) => void
  onChangeCheckboxes?: (values: string[]) => void
  limit?: number
  hideCheckboxes?: boolean
  placeholder?: string
}

export function MultiSelectView(props: MultiSelectViewProps) {
  return (
    <Box>
      <Group mb="lg" wrap="nowrap">
        <CustomMultiSelect
          hidePills
          inputProps={{
            w: '100%',
          }}
          placeholder={props.placeholder ?? 'Escolha um ou mais SKUs'}
          onChange={props.onChange}
          value={props.selected}
          options={props.options}
          limit={props.limit}
        />
        {!props.hideCheckboxes && (
          <Checkbox.Group onChange={props.onChangeCheckboxes} defaultValue={INITIAL_CHECKBOXES_STATE}>
            <Group wrap="nowrap">
              <Checkbox size="xs" value="offers" label="Ofertas" />
              <Checkbox size="xs" value="purchases" label="Compras" />
            </Group>
          </Checkbox.Group>
        )}
      </Group>
      <ScrollArea w={430} h={350} type="scroll" p="sm" bg="#F8F9FA">
        <Stack>
          {props.selected.map((option) => (
            <Paper p="md" key={option.value} shadow="none">
              <Group justify="space-between" gap="xs">
                <Group gap="xs" w="48%" wrap="nowrap">
                  <ActionIcon
                    disabled={option.preventDelete}
                    onClick={() => props.handleRemove(option)}
                    size="md"
                    color="red"
                    variant="light"
                  >
                    <IconMinus />
                  </ActionIcon>
                  <Text
                    size="sm"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {option.label}
                  </Text>
                </Group>

                <Group gap="sm">
                  <Group gap="xs">
                    <Text size="sm">Oferta</Text>
                    <Divider size={3} color={option.color ?? generateHSLColor(option.value)} w={25} variant="dashed" />
                  </Group>
                  <Group gap="xs">
                    <Text size="sm">Compra</Text>
                    <Divider size={3} color={option.color ?? generateHSLColor(option.value)} w={25} />
                  </Group>
                </Group>
              </Group>
            </Paper>
          ))}
        </Stack>
      </ScrollArea>
    </Box>
  )
}
