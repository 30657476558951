import { arrayRemove, arrayUnion, doc, runTransaction } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { Demand, Distributor } from 'src/types'
import dataConverter from '../utils/dataConverter'

type UpdateSingleDemandContext = {
  distributorId: string
  currentDemands: Demand[]
  demandData: Pick<Demand, 'code' | 'sku'>
  newValue: number
}

export async function updateSingleDemand(context: UpdateSingleDemandContext) {
  // eslint-disable-next-line @typescript-eslint/require-await
  await runTransaction(db, async (txn) => {
    const distributorRef = doc(db, 'distributors', context.distributorId).withConverter(dataConverter<Distributor>())

    const demandExists = context.currentDemands.find((d) => d.code === context.demandData.code)

    const newDemandData = {
      ...context.demandData,
      value: context.newValue,
    }

    if (demandExists) {
      txn.update(distributorRef, {
        demands: arrayRemove(demandExists),
      })
    }

    txn.update(distributorRef, {
      demands: arrayUnion(newDemandData),
    })
  })
}
