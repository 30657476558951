export function removeDuplicatedStrings<T extends string>(arr: T[]) {
  return Array.from(new Set(arr))
}

export function removeDuplicatedArrayItems<T>(arr: T[], key: keyof T) {
  const map = new Map<T[keyof T], T>()

  arr.forEach((item) => {
    const keyValue = item[key]
    if (!map.has(keyValue)) {
      map.set(keyValue, item)
    }
  })

  return Array.from<T>(map.values())
}

export function keepFirstArrayItem<T>(arr1: T[], arr2: T[], key: keyof T): T[] {
  const map = new Map<T[keyof T], T>()

  // Add items from the first array to the map using the specified key
  arr1.forEach((item) => {
    const keyValue = item[key]
    if (!map.has(keyValue)) {
      map.set(keyValue, item)
    }
  })

  // Add items from the second array to the map if they don't already exist
  arr2.forEach((item) => {
    const keyValue = item[key]
    if (!map.has(keyValue)) {
      map.set(keyValue, item)
    }
  })

  // Return the values of the map as the result array
  return Array.from<T>(map.values())
}
