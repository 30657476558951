import { errorNotificationFactory } from 'src/factories/errorNotificationFactory'
import { DEFAULT_AUTO_CLOSE_TIMEOUT } from 'src/constants/pedingNotification'
import type { NotificationMessage } from 'src/types'

type Options = {
  callback: () => void
}

let isDispatched = false

export const dispatchErrorNotification = (error: any, context: NotificationMessage, options: Options) => {
  if (isDispatched) {
    return Promise.reject(error)
  }

  const notification = errorNotificationFactory(context)
  // Prevent notification spam when user keeps receiving errors while navigating.
  isDispatched = true
  notification.dispatch()
  setTimeout(() => {
    isDispatched = false
    options.callback()
  }, DEFAULT_AUTO_CLOSE_TIMEOUT)
}
