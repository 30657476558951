import { Box, Image, Title, Text, Button } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Link } from 'react-router-dom'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import styles from './Login.module.css'

export default function LoginBox() {
  const { login } = useKindeAuth()
  const isSmall = useMediaQuery('(max-width: 900px)')
  const logoSize = isSmall ? 60 : 70
  const btnSize = isSmall ? 'md' : 'lg'
  return (
    <Box className={styles.loginBox}>
      <Image src="/logo.png" fit="contain" height={logoSize} alt="logo" />
      <Title className={styles.loginTitle}>Gerencie suas compras</Title>
      <Text className={styles.loginText}>Acesse o dashboard, acompanhe seus resultados e compre mais</Text>
      <Button fullWidth onClick={() => login({})} size={btnSize}>
        Entrar na plataforma
      </Button>
      <Link to="/register-org" style={{ display: 'grid', width: '100%' }}>
        <Button variant="light" size={btnSize} className={styles.btn}>
          Criar nova conta
        </Button>
      </Link>
    </Box>
  )
}
