import { doc, getDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore'
import db from 'src/configs/firebase'
import type { Order } from 'src/types'

type UpdateOrderProductsPriceContext = {
  distributorId: string
  orderId: string
  newPrice: number
  sku: string
}

export default async function updateOrderProductsPrice({
  distributorId,
  orderId,
  newPrice,
  sku,
}: UpdateOrderProductsPriceContext) {
  const orderRef = doc(db, 'distributors', distributorId, 'orders', orderId)
  const orderDocSnapshot = await getDoc(orderRef)
  if (orderDocSnapshot.exists()) {
    const oldOrder = orderDocSnapshot.data() as Order
    const oldDraftOrder = oldOrder?.draftOrder
    const orderItem = oldDraftOrder.find((item) => item.sku === sku)

    const productItem = oldOrder.products.find((p) => p.sku === sku)

    if (orderItem) {
      await updateDoc(orderRef, {
        updatedAt: new Date(),
        draftOrder: arrayRemove(orderItem),
        products: arrayRemove(productItem),
        changed: true,
      })
      await updateDoc(orderRef, {
        updatedAt: new Date(),
        draftOrder: arrayUnion({
          ...orderItem,
          price: newPrice,
        }),
        products: arrayUnion({
          ...productItem,
          price: newPrice,
        }),
        changed: true,
      })
      return
    }

    await updateDoc(orderRef, {
      updatedAt: new Date(),
      products: arrayRemove(productItem),
      changed: true,
    })
    await updateDoc(orderRef, {
      updatedAt: new Date(),
      products: arrayUnion({
        ...productItem,
        price: newPrice,
        priceHistory: [
          ...(productItem?.priceHistory ?? []), 
          {
            oldPrice: productItem?.price,
            newPrice,
            changedBy: 'OPERATOR',
            changedAt: new Date(),
          }
        ],
      }),
      changed: true,
    })
  }
}
