import { doc, getDoc, updateDoc } from 'firebase/firestore'
import db from 'src/configs/firebase'

type CancelOrderContext = {
  distributorId: string
  orderId: string
}

export default async function cancelOrder({ distributorId, orderId }: CancelOrderContext): Promise<void> {
  const orderRef = doc(db, 'distributors', distributorId, 'orders', orderId)
  const orderDocSnapshot = await getDoc(orderRef)
  if (orderDocSnapshot.exists()) {
    await updateDoc(orderRef, {
      isCanceled: true,
    })

    return
  }
}
