export enum ORDER_STATUS {
  'CONFIRMED' = 'Confirmado',
  'REJECTED' = 'Rejeitado',
  'PENDING' = 'Pendente',
  'CANCELED' = 'Cancelado',
}

export function decideOrderStatus(canceled?: boolean, confirmed?: boolean) {
  const supplierStatus =
    typeof confirmed !== 'undefined'
      ? confirmed
        ? ORDER_STATUS.CONFIRMED
        : ORDER_STATUS.REJECTED
      : ORDER_STATUS.PENDING

  const status = canceled ? ORDER_STATUS.CANCELED : supplierStatus

  return status
}
