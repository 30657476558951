import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { api } from 'src/services/api'
import type { AxiosProps } from 'src/types'
import { useLogout } from 'src/hooks/useLogout'
import { dispatchErrorNotification } from 'src/utils/dispatchErrorNotification'

const makeErrorContext = (response: any) => {
  const context = {
    title: '',
    message: 'Você será direcionado para o login em instantes',
  }

  const isAccessTokenExpired = response.status === 403 && response.headers.get('Location')
  const isDistributorInvalid =
    response.status === 400 && response.data?.message === 'You need to provide a valid distributor'

  if (isAccessTokenExpired) {
    context.title = 'Sessão expirada'

    return context
  }

  if (isDistributorInvalid) {
    context.title = 'Distribuidor inválido'

    return context
  }
}

const handleHttpError = (error: any, callback: () => Promise<void>) => {
  const hasResponse = !!error.response

  if (!hasResponse) {
    return Promise.reject(error)
  }

  const { response } = error
  const context = makeErrorContext(response)

  if (!context) {
    return Promise.reject(error)
  }

  return dispatchErrorNotification(error, context, { callback })
}

export default function Axios({ children }: AxiosProps) {
  const { getToken, isAuthenticated, isLoading, user } = useKindeAuth()
  const { showBoundary } = useErrorBoundary()
  const { logout } = useLogout()

  useEffect(() => {
    let interceptor = null
    ;(async () => {
      if (isAuthenticated && !isLoading) {
        try {
          const token = await getToken()

          interceptor = api.interceptors.request.use((config) => {
            const updatedConfig = { ...config }

            if (token) {
              updatedConfig.headers['authorization'] = `Bearer ${token}`
            }

            return updatedConfig
          })

          api.interceptors.response.use(
            (response) => response,
            (error) => handleHttpError(error, logout),
          )
        } catch (error) {
          if (error instanceof Error) {
            console.log(error.message)
            showBoundary(error)
          } else {
            console.log(error)
            showBoundary(error)
          }
        }
      }
    })()

    if (interceptor !== null) return api.interceptors.request.eject(interceptor)
  }, [isAuthenticated, isLoading, user, getToken, showBoundary])

  return children
}
