import { createTheme, MantineColorsTuple } from '@mantine/core'

export const theme = createTheme({
  fontFamily: 'Work Sans',
  headings: {
    fontFamily: 'Work Sans',
  },
  colors: {
    main: [
      '#e6fdee',
      '#d7f5e1',
      '#b1e8c4',
      '#88daa3',
      '#66cf89',
      '#4fc877',
      '#42c46e',
      '#31ad5c',
      '#269a50',
      '#138542',
    ] as MantineColorsTuple,
    secondary: [
      '#fff2e6',
      '#ffe3d0',
      '#fdc69e',
      '#fda668',
      '#fc8a3b',
      '#fd7a20',
      '#fd7112',
      '#e26005',
      '#c95400',
      '#af4600',
    ] as MantineColorsTuple,
    customGray: ['#F3F6F9'] as unknown as MantineColorsTuple,
  },
  primaryColor: 'main',
  primaryShade: 5 as const,
  shadows: {
    basic: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.025), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05)',
    darker: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05)',
    blur: '0 0.0625rem 1rem rgba(0, 0, 0, 0.1), 0 0.0625rem 1.125rem rgba(0, 0, 0, 0.05)',
  },
})
