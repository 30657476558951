export default function IllustrationLaptop({ ...props }) {
  return (
    <svg width="613" height="309" viewBox="0 0 613 309" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_0_122)">
        <path
          d="M10.888 285.833C-17.212 256.143 12.848 176.71 58.181 128.133C113.307 69.075 189.3 19.396 336.2 36.29C457.5 50.247 532.806 111.447 558.422 142.967C599.155 193.067 628.583 263.758 604.051 292.994C562.883 342.068 392.792 258.57 300.531 256.868C208.27 255.166 73.645 352.022 10.888 285.833Z"
          fill="#E1ECFF"
        />
        <path
          d="M446.778 238.4H126.597L59.252 18.167C58.6075 16.064 58.4657 13.839 58.8381 11.6712C59.2105 9.50333 60.0867 7.45322 61.3962 5.68586C62.7056 3.91851 64.4117 2.48328 66.3771 1.49571C68.3426 0.508149 70.5124 -0.00416008 72.712 2.54414e-05H363.478C366.488 -0.000236797 369.419 0.96616 371.839 2.7569C374.259 4.54764 376.039 7.06807 376.919 9.94703L446.778 238.4Z"
          fill="#FF6B6B"
        />
        <path
          d="M394.343 212.182H164.639C161.601 212.181 158.644 211.206 156.202 209.4C153.76 207.594 151.962 205.051 151.072 202.147L100.074 35.364C99.7493 34.3059 99.6775 33.1863 99.8643 32.0953C100.051 31.0043 100.491 29.9724 101.15 29.0826C101.808 28.1928 102.666 27.47 103.655 26.9723C104.644 26.4746 105.735 26.2159 106.842 26.217H336.542C339.58 26.2176 342.537 27.1927 344.979 28.9991C347.421 30.8054 349.219 33.3477 350.109 36.252L401.109 203.035C401.434 204.093 401.506 205.213 401.319 206.304C401.132 207.395 400.692 208.427 400.033 209.316C399.375 210.206 398.517 210.929 397.528 211.427C396.54 211.924 395.448 212.183 394.341 212.182H394.343Z"
          fill="#1C3177"
        />
        <path
          d="M126.597 238.399H556.67C558.107 238.399 559.53 238.683 560.857 239.233C562.184 239.783 563.39 240.589 564.406 241.605C565.422 242.621 566.227 243.827 566.777 245.155C567.327 246.482 567.609 247.905 567.609 249.342V263.96C567.609 266.861 566.456 269.643 564.405 271.695C562.353 273.746 559.571 274.899 556.67 274.899H137.536C134.635 274.899 131.853 273.746 129.802 271.695C127.75 269.643 126.598 266.861 126.597 263.96V238.399Z"
          fill="#E03131"
        />
        <path
          d="M179.013 127.228L205.171 98.028"
          stroke="#99ADF9"
          strokeWidth="11.336"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M211.346 127.228L171.955 98.028"
          stroke="#99ADF9"
          strokeWidth="11.336"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M308.182 127.228L334.34 98.0281"
          stroke="#99ADF9"
          strokeWidth="11.336"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M340.518 127.228L301.118 98.028"
          stroke="#99ADF9"
          strokeWidth="11.336"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M243.528 145.69C243.528 145.69 264.033 134.381 284.86 145.69"
          stroke="#99ADF9"
          strokeWidth="11.336"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M467.466 111.223C465.094 111.217 462.796 110.401 460.952 108.91C459.108 107.418 457.829 105.341 457.327 103.023C455.399 94.3301 454.176 87.6921 455.878 81.4831C457.92 74.1201 463.308 69.8091 469.111 65.6831C473.024 62.9091 475.74 58.6991 476.043 54.9671C476.132 53.7377 475.93 52.5045 475.454 51.3676C474.977 50.2307 474.24 49.222 473.301 48.4231C467.214 42.5161 457.358 51.7071 456.917 52.1041C456.917 52.1041 448.637 59.1141 442.486 51.7641C436.335 44.4141 442.486 37.1271 442.486 37.1271C446.454 33.4424 451.019 30.4583 455.986 28.3021C467.825 23.2541 479.105 25.1451 487.753 33.4971C490.882 36.4293 493.315 40.0253 494.873 44.021C496.43 48.0167 497.073 52.3106 496.753 56.5871C495.991 66.5341 490.153 76.2991 481.119 82.6871C476.878 85.6941 476.078 86.7591 475.882 87.1001C475.397 88.6381 476.997 95.8431 477.602 98.5601C477.937 100.079 477.928 101.654 477.575 103.169C477.222 104.684 476.534 106.101 475.562 107.315C474.59 108.53 473.358 109.511 471.957 110.187C470.556 110.863 469.022 111.217 467.466 111.223Z"
          fill="#FEC272"
        />
        <path
          d="M472.476 147.292C479.134 147.292 484.531 141.893 484.531 135.233C484.531 128.573 479.134 123.174 472.476 123.174C465.818 123.174 460.421 128.573 460.421 135.233C460.421 141.893 465.818 147.292 472.476 147.292Z"
          fill="#FEC272"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_122">
          <rect width="612.998" height="308.999" fill="white" transform="translate(0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  )
}
