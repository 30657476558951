export function getInitials(name: string, size = 2): string {
  const lettersOnly = name.replace(/[^a-zA-Z ]/g, '');
  const words = lettersOnly.split(' ');

  const initials: string[] = [];

  words.forEach(word => {
    if (initials.length > size) {
      return
    }

    const initial = word[0]?.toUpperCase();
    initials.push(initial);
  });

  return initials.join('');
}