import { Routes, Route } from 'react-router-dom'
import Chat from 'src/components/Chat'
import PrivatePage from 'src/components/PrivatePage'

export default function Messages() {
  return (
    <PrivatePage>
      <Routes>
        <Route path="/:phone" element={<Chat />} />
      </Routes>
    </PrivatePage>
  )
}
