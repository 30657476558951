import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { useFirebaseAuth } from 'src/providers/FirebaseAuth'
import { logoutFirebase } from 'src/configs/firebase'

export const useLogout = () => {
  const { logout: logoutKinde } = useKindeAuth()
  const { setPreventAuth } = useFirebaseAuth()

  const logout = async () => {
    setPreventAuth(true)
    await logoutKinde()
    await logoutFirebase()
  }

  return { logout }
}
