import { Producer, SKU, SUPPLIER_TYPE } from 'src/types'

export function makeMiddlemanCategories(supplier: Producer, suppliers: Producer[], products: SKU[]) {
  if (supplier.type !== SUPPLIER_TYPE.MIDDLEMAN) {
    return makeCategories(supplier.skuCodesReference, products)
  }

  if (!supplier.representedSuppliers) return []

  return supplier.representedSuppliers.flatMap((id) => {
    const representedSupplier = suppliers.find((s) => s.id === id)

    if (!representedSupplier || !representedSupplier.skuCodesReference) return []

    return makeCategories(representedSupplier.skuCodesReference, products)
  })
}

function makeCategories(skuCodesReference: string[] = [], products: SKU[]) {
  return skuCodesReference
    .map((code) => String(products.find((product) => product['Código'] === code)?.['Categoria']))
    .filter(Boolean)
}

export function makeMiddlemanReceivingDays(supplier: Producer, suppliers: Producer[]) {
  if (supplier.type !== SUPPLIER_TYPE.MIDDLEMAN) {
    return supplier.receivingDays ?? []
  }

  if (!supplier.representedSuppliers) {
    return []
  }

  return supplier.representedSuppliers.flatMap((id) => {
    const representedSupplier = suppliers.find((s) => s.id === id)

    if (!representedSupplier || !representedSupplier.receivingDays) return []

    return representedSupplier.receivingDays
  })
}

export function makeMiddlemanProducts(supplier: Producer, suppliers: Producer[]) {
  if (supplier.type !== SUPPLIER_TYPE.MIDDLEMAN) {
    return supplier.skuCodesReference ?? []
  }

  if (!supplier.representedSuppliers) {
    return []
  }

  return supplier.representedSuppliers.flatMap((id) => {
    const representedSupplier = suppliers.find((s) => s.id === id)

    if (!representedSupplier || !representedSupplier.skuCodesReference) return []

    return representedSupplier.skuCodesReference
  })
}

export function makeMiddlemanRepresentedSuppliersNames(supplier: Producer, suppliers: Producer[]) {
  if (supplier.type !== SUPPLIER_TYPE.MIDDLEMAN || !supplier.representedSuppliers) {
    return []
  }

  return supplier.representedSuppliers
    .map((id) => {
      const representedSupplier = suppliers.find((s) => s.id === id)

      return representedSupplier?.name
    })
    .filter(Boolean) as string[]
}

export function makeMiddlemanRepresentedSuppliersCodes(supplier: Producer, suppliers: Producer[]) {
  if (supplier.type !== SUPPLIER_TYPE.MIDDLEMAN || !supplier.representedSuppliers) {
    return []
  }

  return supplier.representedSuppliers
    .map((id) => {
      const representedSupplier = suppliers.find((s) => s.id === id)

      return representedSupplier?.code
    })
    .filter(Boolean) as string[]
}
