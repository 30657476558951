import { notifications } from '@mantine/notifications'
import { useCallback } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDeliveryDate } from 'src/hooks/useDeliveryDate'
import { useDistributor } from 'src/providers/Distributor'
import updateOffer from 'src/requests/updateOffer'
import updateOfferSku from 'src/requests/updateOfferSku'

type Options = {
  onSave?(): void
}

type OrderProductData = {
  orderId: string
}

type ProductData = OrderProductData & {
  sku: string
}

export function useUpdateDeliveryDate(options?: Options) {
  const { distributor } = useDistributor()
  const { showBoundary } = useErrorBoundary()

  const handleUpdateOrderDeliveryDate = useCallback(
    async (data: OrderProductData, newDeliveryDate: Date) => {
      const { utcDate } = useDeliveryDate(newDeliveryDate?.toISOString())
      try {
        await updateOffer({
          deliveryDate: utcDate!.toISOString(),
          offerId: data.orderId,
        })
        options?.onSave?.()
        notifications.show({
          autoClose: 3000,
          message: 'Data de entrega alterada com sucesso!',
        })
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    },
    [distributor, options, showBoundary],
  )

  const handleUpdateSkuDeliveryDate = useCallback(
    async (data: ProductData, newDeliveryDate: Date) => {
      const { utcDate } = useDeliveryDate(newDeliveryDate?.toISOString())
      try {
        await updateOfferSku({
          deliveryDate: utcDate!.toISOString(),
          offerId: data.orderId,
          sku: data.sku,
        })
        options?.onSave?.()
        notifications.show({
          autoClose: 3000,
          message: 'Data de entrega alterada com sucesso!',
        })
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    },
    [distributor, options, showBoundary],
  )

  return {
    handleUpdateOrderDeliveryDate,
    handleUpdateSkuDeliveryDate,
  }
}
