import { notifications } from '@mantine/notifications'
import { ChangeEvent, KeyboardEvent, useCallback, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import updateDraftOrder from 'src/requests/updateDraftOrder'

type Options = {
  onSave?(): void
}

type ProductData = {
  skuName: string
  orderId: string
}

export function useUpdateDesiredQty(options?: Options) {
  const desiredQtyRef = useRef<HTMLInputElement>(null)
  const [productData, setProductData] = useState<ProductData | null>()
  const [updatedDesiredQty, setUpdatedDesiredQty] = useState<number | undefined>()
  const { distributor } = useDistributor()

  const { showBoundary } = useErrorBoundary()

  const saveDesiredQty = useCallback(
    async (data: ProductData, newQty: number) => {
      try {
        await updateDraftOrder({
          distributorId: distributor!.distributorId,
          desiredQty: newQty,
          orderId: data.orderId,
          sku: data.skuName,
        })
        options?.onSave?.()
        setProductData(null)
        setUpdatedDesiredQty(undefined)
        notifications.show({
          autoClose: 3000,
          message: 'Quantidade desejada alterada com sucesso!',
        })
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    },
    [distributor, options, showBoundary],
  )

  const handleClickToUpdateDesiredQty = useCallback(
    (data: ProductData) => () => {
      setProductData(data)
      setTimeout(() => {
        desiredQtyRef.current?.focus()
      }, 100)
    },
    [],
  )

  function handleChangeDesiredQty(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value

    setUpdatedDesiredQty(Number(value))
  }

  const handleEnterDesiredQty = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const newQty = updatedDesiredQty
      const enterPressed = event.key === 'Enter'
      if (productData && enterPressed && (newQty || typeof newQty === 'string')) {
        saveDesiredQty(productData, newQty)
      } else if (enterPressed) {
        setProductData(null)
      }
    },
    [saveDesiredQty, updatedDesiredQty, productData],
  )

  const handleBlurDesiredQty = useCallback(() => {
    const newQty = updatedDesiredQty
    if (productData && (newQty || typeof newQty === 'string')) {
      saveDesiredQty(productData, newQty)
    } else {
      setProductData(null)
    }
  }, [saveDesiredQty, updatedDesiredQty, productData])

  return {
    handleClickToUpdateDesiredQty,
    isUpdatingDesiredQtyId: productData?.orderId,
    handleChangeDesiredQty,
    updatedDesiredQty,
    handleEnterDesiredQty,
    handleBlurDesiredQty,
    desiredQtyRef,
    activeProductDataDesiredQty: productData
  }
}
